import React, { useState } from 'react';
import { Helmet } from "react-helmet-async";
import { styled } from "@mui/material/styles";
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance from 'src/axiosInstance';
import { notifyError, notifySuccess } from 'src/hooks/useMockData';
import CircularProgress from '@mui/material/CircularProgress';
import Logo from '../../../../components/logo';
import { Button, Box, TextField, FormControl, OutlinedInput, InputAdornment, IconButton } from '@mui/material';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Styles from "../../CSSModules/FormPages.module.css";
import Footer from '../../Footer';


const StyledRoot = styled("div")(({ theme, imageUrl }) => ({
    [theme.breakpoints.up("0")]: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        boxSizing: "border-box",
        backgroundColor: "#FFFFFF",
        backgroundSize: "100% 100%",
        backgroundPosition: "center",
        padding: theme.spacing(4, 0),
    },
}));

const StyledContent = styled("div")(({ theme }) => ({
    boxSizing: "border-box",
    overflowX: "hidden",
    position: "relative",
    width: "80em",
    maxWidth: "100%",
    margin: "0",
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "space-between",
    flexDirection: "column",
    padding: theme.spacing(3, 0),
}));

export default function SignUpToken() {
    const { token } = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [formData, setFormData] = useState({
        fullName: '',
        password: '',
        confirmPassword: ''
    });

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        if (!formData.fullName.trim()) {
            notifyError("Full name is required");
            setIsLoading(false);
            return;
        }
        if (formData.password !== formData.confirmPassword) {
            notifyError("Passwords do not match");
            setIsLoading(false);
            return;
        }

        try {
            const response = await axiosInstance.post('/user-account/accept?productName=onepoint', {
                name: formData.fullName,
                password: formData.password,
                confirmPassword: formData.confirmPassword,
                token: token
            });

            if (response.status === 200) {
                notifySuccess('Registration completed successfully');
                navigate('/login');
            } else {
                notifyError(response.data.message || 'Registration failed');
            }
        } catch (error) {
            console.error('Registration error:', error);
            notifyError(error.response?.data?.message || 'Registration failed. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <Helmet>
                <title> Complete Registration </title>
            </Helmet>

            <StyledRoot className="px-3">
                <StyledContent className={`${Styles.StyledContent}`}>
                    <div className={`${Styles.logo_wrap}`}>
                        <Logo />
                    </div>

                    <div className={`${Styles.signin_form_wrap} py-5 my-3 py-md-5 my-md-5 mx-sm-auto ml-md-5 pl-md-5`}>
                        <form onSubmit={handleSubmit}>
                            <h4>Complete Registration </h4>
                            <p className="text-muted mb-4">Please fill in your details to complete your registration</p>

                            <div className={`${Styles.input_box}`}>
                                <label>Full Name</label>
                                <Box sx={{ maxWidth: "100%" }}>
                                    <TextField
                                        fullWidth
                                        placeholder="Enter your full name"
                                        value={formData.fullName}
                                        onChange={(e) => setFormData({...formData, fullName: e.target.value})}
                                    />
                                </Box>
                            </div>

                            <div className={`${Styles.input_box}`}>
                                <label>Password</label>
                                <FormControl sx={{ width: '100%' }} variant="outlined">
                                    <OutlinedInput
                                        type={showPassword ? 'text' : 'password'}
                                        placeholder="Create a password"
                                        value={formData.password}
                                        onChange={(e) => setFormData({...formData, password: e.target.value})}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton onClick={handleClickShowPassword} edge="end">
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </div>

                            <div className={`${Styles.input_box}`}>
                                <label>Confirm Password</label>
                                <FormControl sx={{ width: '100%' }} variant="outlined">
                                    <OutlinedInput
                                        type={showConfirmPassword ? 'text' : 'password'}
                                        placeholder="Confirm your password"
                                        value={formData.confirmPassword}
                                        onChange={(e) => setFormData({...formData, confirmPassword: e.target.value})}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton onClick={handleClickShowConfirmPassword} edge="end">
                                                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </div>

                            <Button
                                type="submit"
                                fullWidth
                                className={`${Styles.signin_btn} mb-0 position-relative`}
                                disabled={isLoading}
                                sx={{ 
                                    boxShadow: 'none',
                                    background: '#000 !important',
                                    color: '#fff',
                                    textTransform: 'none',
                                    padding: '8px !important ',
                                    '&:hover': {
                                        backgroundColor: '#001 !important',
                                        boxShadow: 'none',
                                        color: '#fff'
                                    },
                                    '&:disabled': {
                                        backgroundColor: '#000 !important',
                                        color: '#fff',
                                        opacity: 0.7
                                    }
                                }}
                            >
                                {isLoading ? (
                                    <>
                                        <CircularProgress size={20} color="inherit" />
                                        <span className="ml-2">Processing...</span>
                                    </>
                                ) : (
                                    'Complete Registration'
                                )}
                            </Button>
                        </form>
                    </div>
                </StyledContent>
            </StyledRoot>
        </>
    );
}