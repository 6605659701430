import React, { useEffect, useState } from 'react';
import { Select, MenuItem, FormControl, ListSubheader, InputLabel } from '@mui/material';
import { fetchLedgerAccounts } from '../../services/ledgerService';

const LedgerAccountDropdown = ({ onSelect }) => {
  const [ledgerAccounts, setLedgerAccounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadLedgerAccounts = async () => {
      try {
        const accounts = await fetchLedgerAccounts();
        console.log('Raw accounts data:', accounts);
        setLedgerAccounts(accounts);
      } catch (err) {
        console.error('Error loading ledger accounts:', err);
        setError("Failed to load ledger accounts");
      } finally {
        setLoading(false);
      }
    };

    loadLedgerAccounts();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <FormControl fullWidth variant="outlined">
      {/* <InputLabel id="ledger-account-label" htmlFor="ledger-account">
        Select Ledger Account
      </InputLabel> */}
      <Select
        labelId="ledger-account-label"
        id="ledger-account"
        displayEmpty
        defaultValue=""
        label="Select Ledger Account"
        onChange={(e) => {
          const selectedValue = e.target.value;
          const selectedAccount = ledgerAccounts.reduce((found, parent) => {
            if (found) return found;
            return parent.children?.find(child => child.id === selectedValue);
          }, null);

          console.log('Selected account:', selectedAccount);
          
          onSelect({
            id: selectedValue,
            chart_of_account_id: selectedAccount ? selectedAccount.chart_of_account_id : null
          });
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              maxHeight: 450,
            }
          }
        }}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {ledgerAccounts.map((parentAccount) => [
          <ListSubheader
            key={`header-${parentAccount.id}`}
            sx={{
              bgcolor: 'grey.100',
              color: 'grey.500',
              fontWeight: 500,
              userSelect: 'none',
              pointerEvents: 'none'
            }}
          >
            {parentAccount.name}
          </ListSubheader>,
          ...(parentAccount.children?.map(childAccount => (
            <MenuItem
              key={childAccount.id}
              value={childAccount.id}
              sx={{
                pl: 4,
                '&:hover': {
                  bgcolor: 'grey.100'
                }
              }}
            >
              {childAccount.name}
            </MenuItem>
          )) || [])
        ])}
      </Select>
    </FormControl>
  );
};

export default LedgerAccountDropdown;