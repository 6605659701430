import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
// @mui
import { Box, Drawer, Link } from '@mui/material';
// mock
// hooks
import useResponsive from '../../../hooks/useResponsive';
// components
// import Logo from '../../../components/logo';
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';
// import { StyledNavItem } from '../../../components/nav-section/styles';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import navConfig from './config'; // Import getFilteredNavConfig function

// import bus from "../../../bus";
import bus from 'src/bus';
import toast from 'react-hot-toast';
import '../header/index';
import { useAuth } from 'src/hooks/useAuth';
import SuccessToast from 'src/components/SuccessToast';
import axiosInstance from 'src/axiosInstance';
import { notifySuccess, notifyError } from 'src/hooks/useMockData';

// ----------------------------------------------------------------------

const style1 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  maxWidth: '95%',
  overflow: 'scroll',
  bgcolor: '#F5F5F5',
  border: '1px solid #F5F5F5',
  borderRadius: '5px',
  boxShadow: 24,
  p: 2,
};

const NAV_WIDTH = 250;

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const [openLogoutDialog, setOpenLogoutDialog] = useState(false);
  const { pathname } = useLocation();
  const { dispatch } = useAuth();
  const accountID = JSON.parse(localStorage.getItem('auth'))?.account_id;
  const name = JSON.parse(localStorage.getItem('auth'))?.name;
  const businessName = JSON.parse(localStorage.getItem('auth'))?.businessName;
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const authUser = JSON.parse(localStorage.getItem('auth'));
  const apiUrl = process.env.REACT_APP_NAME;

  console.log(businessName, ' BusiNesa name ');

  const [count, setCount] = useState(0);
  const [KYC_Status, setKYC_Status] = useState();
  const [AwaitingStatus, setAwaitingStatus] = useState();

  const notify = (msg) => {
    toast.custom((t) => <SuccessToast message={msg} show={t.visible} />, {
      duration: 3000,
    });
  };

  // const [runTour, setRunTour] = useState(false);

  // const [filteredNav, setFilteredNav] = useState(GetFilteredNavConfig);

  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      if (count < 50) {
        setCount((prevCount) => prevCount + 1);

        setKYC_Status(JSON.parse(localStorage.getItem('kyc_status')));
        setAwaitingStatus(
          JSON.parse(localStorage.getItem('awaiting_kyc_verification'))
        );
        bus.emit('lang_status', true);

        if (!KYC_Status) {
          const handleNotVerified = (val) => {
            if (val) {
              if (AwaitingStatus === true) {
                notifyError('Your account is currently being reviewed!');
                return;
              }
              if (AwaitingStatus === false) {
                notifyError(
                  'Kindly complete your KYC to access these features.'
                );
                return;
              }
            }
          };

          bus.on('not_verified', handleNotVerified);

          return () => {
            bus.off('not_verified', handleNotVerified);
          };
        }
      } else {
        clearInterval(interval);
      }
    }, 100);

    return () => clearInterval(interval);
  }, [count, KYC_Status, AwaitingStatus]);

  const getUserDetails = async () => {
    try {
      const response = await axiosInstance.get(
        `/accounts/user?productName=${apiUrl}`
      );
      // const response = await axiosInstance.get(`/accounts/${authUser && authUser.account_id ? authUser.account_id : null}?productName=${apiUrl}`)
      const data = response.data.data;

      if (data) {
        localStorage.setItem('userDetails', JSON.stringify(data));

        if (data.account.kyc_status !== null) {
          localStorage.setItem(
            'kyc_status',
            JSON.stringify(data.account.kyc_status)
          );
        }

        if (
          data.account.business_rep &&
          data.account.docs_upload &&
          data.account.secure_account &&
          data.account.setup_account &&
          data.account.verify_business
        ) {
          localStorage.setItem(
            'awaiting_kyc_verification',
            JSON.stringify(true)
          );
        } else {
          localStorage.setItem(
            'awaiting_kyc_verification',
            JSON.stringify(false)
          );
        }
      }
    } catch (err) {
      if (err.response) {
        if (
          err.response.data &&
          err.response.data.data &&
          err.response.data.data.errorMessage
        ) {
          notifyError(err.response.data.data.errorMessage);
        } else if (err.response.data && err.response.data.message) {
          notifyError(err.response.data.message);
        } else {
          notifyError('An unexpected error occurred');
        }
      } else {
        notifyError('Network error or server not responding');
      }
    } finally {
    }
  };

  useEffect(() => {
    if (authUser && authUser.account_id) {
      getUserDetails();
    }
  }, [openNav, onCloseNav]);

  const handleLogoutClick = () => {
    const keysToSpare = ['me'];

    Object.keys(localStorage).forEach((key) => {
      if (!keysToSpare.includes(key)) {
        localStorage.removeItem(key);
      }
    });

    notify('Logout Successful! See you soon.');

    setTimeout(() => {
      dispatch({ type: 'LOGIN', payload: {} });
      navigate('/login');
    }, 1000);
  };

  const isDesktop = useResponsive('up', 'lg');

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        // setTooltipVisible(true);
        // setTimeout(() => {
        //     setTooltipVisible(false);
        // }, 2000);
      },
      (err) => {
        console.error('Failed to copy: ', err);
      }
    );
  };

  const getAbbreviation = (name) => {
    if (name) {
      let passedName = name.replace(/"/g, '').trim();

      const words = passedName.split(' ');
      if (words.length >= 2) {
        return words[0][0]?.toUpperCase() + words[1][0]?.toUpperCase();
      } else if (words.length === 1) {
        return words[0].substring(0, 2).toUpperCase();
      }
      return '';
    }
    return '';
  };

  const handleCopyClick = (item) => {
    copyToClipboard(item);
  };

  // useEffect(() => {
  //   const getFilteredNav = () => {
  //     // Call your function to get the filtered nav based on the current pathname
  //     const newFilteredNav = GetFilteredNavConfig(pathname);
  //     setFilteredNav(newFilteredNav);
  //   };

  //   getFilteredNav(); // Call initially
  // }, [pathname]);

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    bus.on('open-logout-modal', () => {
      setOpenLogoutDialog(true);
    });
  }, []);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
      style={{
        background: '#F9FAFB',
        paddingLeft: '5px',
        paddingTop: '10px',
        paddingRight: '15px',
      }}
    >
      <Box sx={{ py: 3, display: 'inline-flex', width: '100%' }}>
        <div className="d-flex justify-content-between align-items-center w-100 sidenav_initials_wrap pl-4">
          <div className="d-flex align-items-center">
            <div className="sidenav_initials d-flex justify-content-center align-items-center">
              {getAbbreviation(name ? name : '')}
            </div>
            <div className="ml-2">
              <p className="mb-0 user_account_name">{name ? name : ''}</p>
              <span className="d-block user_account_name d-flex justify-content-between align-items-center">
                <span
                  className="fs-12 fw-500 text-dark d-block"
                  style={{
                    width: '10em',
                    maxWidth: '100%',
                    whiteSpace: 'nowrap',
                    overflowX: 'hidden',
                    textOverflow: 'ellipsis',
                    wordWrap: 'normal',
                    wordBreak: 'keep-all',
                    fontSize: '10px',
                  }}
                >
                  ID: {accountID ? accountID : ''}
                </span>

                <span
                  className="text-muted"
                  style={{ fontSize: '11px', cursor: 'pointer' }}
                  onClick={() => {
                    handleCopyClick(accountID ? accountID : '');
                    accountID && notifySuccess('Account ID Copied');
                  }}
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M7.5 9.5C7.5 8.39543 8.39543 7.5 9.5 7.5H16.3333C17.4379 7.5 18.3333 8.39543 18.3333 9.5V16.3333C18.3333 17.4379 17.4379 18.3333 16.3333 18.3333H9.5C8.39543 18.3333 7.5 17.4379 7.5 16.3333V9.5Z"
                      stroke="#222222"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M4.16699 12.5001H3.33366C2.41318 12.5001 1.66699 11.7539 1.66699 10.8334V3.33341C1.66699 2.41294 2.41318 1.66675 3.33366 1.66675H10.8337C11.7541 1.66675 12.5003 2.41294 12.5003 3.33341V4.16675"
                      stroke="#222222"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  {/* {tooltipVisible && (
                      <div className="copy_tooltip" style={{ top: '-20px', }}>
                          Copied
                      </div>
                  )} */}
                </span>
              </span>
              {/* <span className='d-block user_account_name'>{name ? name : ''}</span> */}
            </div>
          </div>

          <div className="">
            <svg
              width="20"
              height="19"
              viewBox="0 0 20 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              data-toggle="dropdown"
              aria-expanded="false"
              className="cursor-pointer"
            >
              <rect
                x="0.734375"
                y="0.53125"
                width="18.4375"
                height="18.4375"
                rx="2.76562"
                fill="black"
              />
              <path
                d="M5.34375 7.44531L9.95313 12.0547L14.5625 7.44531"
                stroke="white"
                strokeWidth="1.53646"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <div
              className="dropdown-menu rounded border-0 sideNav_dropdown"
              style={{
                background: '#fff',
                boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.1)',
              }}
            >
              {/* Removed  */}
              <div className="d-none op_side mb-2 px-3 py-3 w-100 d-flex justify-content-between align-items-center">
                {/* <span
                  className="fs-12 fw-500 text-dark d-block"
                  style={{
                    width: '12em',
                    maxWidth: '100%',
                    whiteSpace: 'nowrap',
                    overflowX: 'hidden',
                    textOverflow: 'ellipsis',
                    wordWrap: 'normal',
                    wordBreak: 'keep-all',
                  }}
                >
                  ID: {accountID ? accountID : ''}
                </span> */}

                <span
                  className="d-none text-muted"
                  style={{ fontSize: '13px', cursor: 'pointer' }}
                  onClick={() => {
                    handleCopyClick(accountID ? accountID : '');
                    accountID && notifySuccess('Account ID Copied');
                  }}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M7.5 9.5C7.5 8.39543 8.39543 7.5 9.5 7.5H16.3333C17.4379 7.5 18.3333 8.39543 18.3333 9.5V16.3333C18.3333 17.4379 17.4379 18.3333 16.3333 18.3333H9.5C8.39543 18.3333 7.5 17.4379 7.5 16.3333V9.5Z"
                      stroke="#222222"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M4.16699 12.5001H3.33366C2.41318 12.5001 1.66699 11.7539 1.66699 10.8334V3.33341C1.66699 2.41294 2.41318 1.66675 3.33366 1.66675H10.8337C11.7541 1.66675 12.5003 2.41294 12.5003 3.33341V4.16675"
                      stroke="#222222"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  {/* {tooltipVisible && (
                      <div className="copy_tooltip" style={{ top: '-20px', }}>
                          Copied
                      </div>
                  )} */}
                </span>
              </div>
              {/* End Removed  */}

              {/* <Link
                className="dropdown-item py-2 mb-1 dropdown_text fw-500"
                to="/app/user-settings"
                component={RouterLink}
              >
                Settings
              </Link> */}
              {/* <button
                className="dropdown-item py-2 mb-1 dropdown_text fw-500"
              >
                Settings
              </button> */}
              <button className="dropdown-item py-2 mb-1 dropdown_text fw-500">
                Documentation
              </button>
              <button
                type="button"
                className="dropdown-item py-2 dropdown_text fw-500"
                style={{ color: 'rgba(153, 27, 27, 1)' }}
                onClick={handleLogoutClick}
              >
                Log Out
              </button>
            </div>
          </div>
        </div>
        {/* <Box
          component="img"
          src="/assets/images/logoOnepoint.png"
          style={{
            width: '180px',
            // height: '29px',
          }}
          className="ml-md-"
        /> */}
      </Box>

      <NavSection data={navConfig} />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <>
      <Box
        component="nav"
        sx={{
          flexShrink: { lg: 0 },
          width: { lg: NAV_WIDTH },
        }}
      >
        {isDesktop ? (
          <Drawer
            open
            variant="permanent"
            PaperProps={{
              sx: {
                width: NAV_WIDTH,
                bgcolor: 'background.default',
                borderRightStyle: 'dashed',
              },
            }}
          >
            {renderContent}
          </Drawer>
        ) : (
          <Drawer
            open={openNav}
            onClose={onCloseNav}
            ModalProps={{
              keepMounted: true,
            }}
            PaperProps={{
              sx: { width: NAV_WIDTH },
            }}
          >
            {renderContent}
          </Drawer>
        )}
      </Box>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openLogoutDialog}
        onClose={() => setOpenLogoutDialog(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
        className="modal_bg"
        style={{ maxWidth: '100%' }}
      >
        <Fade
          in={openLogoutDialog}
          className="modal_bg"
          style={{ maxWidth: '90%' }}
        >
          <Box
            className={`mobile_modal_size modal_bg`}
            sx={style1}
            style={{ maxWidth: '90%' }}
          >
            <div className={`text-center add_to_cart_content py-3`}>
              <img src="/assets/RABBIT1.png" alt="" />
              <p
                className="text-center font-weight-normal my-3"
                style={{ textAlign: 'center !important', fontSize: '1.2em' }}
              >
                Do you want to Logout?
              </p>
              <div className="d-flex px-2">
                <button
                  onClick={() => setOpenLogoutDialog(false)}
                  className={`modal_btn d-block w-100 hover btn border py-2 bg-light text-dark mr-2`}
                  style={{
                    boxShadow: '0 5px 5px #bbb',
                  }}
                >
                  Cancel
                </button>

                <button
                  className={`modal_btn d-block w-100 hover btn border py-2 bg-light text-dark ml-2`}
                  style={{
                    boxShadow: '0 5px 5px #bbb',
                  }}
                  type="button"
                  onClick={handleLogoutClick}
                >
                  Logout
                </button>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
