import axios from 'axios';
import { notifySuccess, notifyError } from "src/hooks/useMockData";

// Debug the API URL
console.log('Current API URL:', process.env.REACT_APP_API_URL);

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'https://gateway-staging.onepointhq.com/onepoint-gateway/api/v1',
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  },
  withCredentials: true
});

// Request interceptor
instance.interceptors.request.use(
  config => {
    // Log the full request URL for debugging
    console.log('Making request to:', `${config.baseURL}${config.url}`);
    
    config.headers['ngrok-skip-browser-warning'] = true;
    
    try {
      const authData = localStorage.getItem('auth');
      if (authData) {
        const { token } = JSON.parse(authData);
        if (token) {
          config.headers['x-auth-token'] = token;
        }
      }
    } catch (error) {
      console.error('Error parsing auth data:', error);
    }
    
    return config;
  },
  error => {
    console.error('Request interceptor error:', error);
    return Promise.reject(error);
  }
);

// Response interceptor
instance.interceptors.response.use(
  response => response,
  error => {
    // Detailed error logging
    console.error('API Error:', {
      url: error.config?.url,
      method: error.config?.method,
      status: error.response?.status,
      data: error.response?.data,
      message: error.message
    });

    if (!error.response) {
      // Check if backend server is running
      notifyError('Cannot connect to the server. Please ensure the backend server is running.');
      return Promise.reject(error);
    }

    const { response } = error;
    
    switch (response.status) {
      case 401: {
        const isUnauthorized = 
          response.data?.data?.errorMessage === "Unauthorized." ||
          response.status === 401;

        if (isUnauthorized) {
          notifyError('Authentication failed. Please try again.');
          return Promise.reject(new Error('Authentication failed'));
        }
        break;
      }
      case 404:
        notifyError('API endpoint not found. Please check the URL.');
        break;
      default:
        notifyError(response.data?.message || 'An error occurred. Please try again.');
    }

    return Promise.reject(error);
  }
);

export default instance;