import React, { useEffect, useState } from 'react';
import axiosInstance from "src/axiosInstance";


export default function RecentTransactionsList() {
    const [recentTransactions, setRecentTransactions] = useState([
        {
            image: '/assets/icons/inward_trn.svg',
            title: 'Sales of Goods',
            date: '24 Mar,2024 11:54 pm',
            amount: '450.65',
            currency: '$',
            profit_loss: '+'
        },
        {
            image: '/assets/icons/outward_trn.svg',
            title: 'Salaries and Wages',
            date: '24 Mar,2024 11:54 pm',
            amount: '450.65',
            currency: '$',
            profit_loss: '-'
        },
        // ... other default transactions
    ]);

    const fetchRecentTransactions = async () => {
        try {
            const response = await axiosInstance.get("/ledger/accounts", {
                params: { productName: "onepoint" },
            });

            if (response.data.status === "success") {
                const transactions = response.data.data.data
                    .slice(0, 5)
                    .map(item => ({
                        image: item.type === 'REVENUE' 
                            ? '/assets/icons/inward_trn.svg' 
                            : '/assets/icons/outward_trn.svg',
                        title: item.name,
                        date: new Date(item.updatedAt).toLocaleString(),
                        amount: item.total_amount,
                        currency: '₦',
                        profit_loss: item.type === 'REVENUE' ? '+' : '-'
                    }));
                if (transactions.length > 0) {
                    setRecentTransactions(transactions);
                }
            }
        } catch (error) {
            console.error("Error fetching ledger data:", error);
        }
    };

    useEffect(() => {
        fetchRecentTransactions();
    }, []);

    return (
        <div className="w-100 pt-4">
            {recentTransactions.map((item) => (
                <div className="d-flex mb-3 pt-2 pb-3 rounded justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                        <img src={item.image} alt="" />
                        <div className='ml-2'>
                            <p className="mb-0 fw-600 fs-13 text-dark">{item.title}</p>
                            <span className="d-block fs-11 fw-500">{item.date}</span>
                        </div>
                    </div>
                    <span className='fs-16 fw-600 text-dark'>
                        {item.profit_loss} {item.currency}{item.amount}
                    </span>
                </div>
            ))}
        </div>
    );
}