import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./LedgerTable.module.css";
import axiosInstance from "src/axiosInstance";
import Pagination from "../../pagination/GlobalPagination";
import Pageloader from 'src/pageloader';

export default function AccountTable({ searchQuery, refreshTrigger, filters }) {
  const navigate = useNavigate();
  const [ledgerData, setLedgerData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [pagination, setPagination] = useState({
    totalItems: 0,
    totalPages: 0,
    currentPage: 1,
    hasNext: false,
    hasPrevious: false,
  });
  const pageSize = 10;

  const fetchLedgerData = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get("/ledger/accounts", {
        params: {
          productName: "onepoint",
          page: currentPage,
          pageSize,
          search: searchQuery,
          sortField: sortField,
          sortOrder: sortOrder,
          categoryType: filters?.categoryType || '',
          dateFrom: filters?.dateFrom ? new Date(filters.dateFrom).toISOString() : '',
          dateTo: filters?.dateTo ? new Date(filters.dateTo).toISOString() : '',
          amountFrom: filters?.amountFrom || '',
          amountTo: filters?.amountTo || '',
        },
      });

      if (response.data.status === "success") {
        const { data, ...paginationInfo } = response.data.data;
        setLedgerData(data || []);
        setPagination(paginationInfo);
      }
    } catch (error) {
      console.error("Error fetching ledger data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchLedgerData();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [currentPage, searchQuery, sortField, sortOrder, refreshTrigger, filters]);

  const handlePageChange = (page) => {
    if (page > 0 && page <= pagination.totalPages) {
      setCurrentPage(page);
    }
  };

  const handleRowClick = (item) => {
    if (item.has_sub_accounts === 1) {
      navigate(`/app/ledger-management/ledger-details/accounts/${item.id}`, {
        state: { accountData: item },
      });
    } else {
      navigate(
        `/app/ledger-management/ledger-details/sales-details/${item.id}`,
        {
          state: { accountData: item },
        }
      );
    }
  };

  const handleSort = (field) => {
    const newOrder =
      sortField === field && sortOrder === "asc" ? "desc" : "asc";
    setSortField(field);
    setSortOrder(newOrder);
  };

  return (
    <>
      <div className="card border-0">
        <div className="table-responsive">
          <table
            className={`${styles.table} table-hover table-striped`}
            style={{ minWidth: "20em" }}
          >
            <thead className={`${styles.thead}`}>
              <tr>
                <th
                  className="fw-500 fs-12 cursor-pointer"
                  onClick={() => handleSort("name")}
                  style={{ background: 'white', border: 'none' }}
                >
                  Account Category
                  <svg
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ 
                      transform: sortField === 'name' && sortOrder === 'desc' ? 'rotate(180deg)' : 'none',
                      marginLeft: '4px'
                    }}
                  >
                    <path
                      d="M8.00004 3.8335V13.1668M8.00004 13.1668L12.6667 8.50016M8.00004 13.1668L3.33337 8.50016"
                      stroke={sortField === 'name' ? '#111827' : '#667085'}
                      strokeWidth="1.33333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </th>
                <th
                  className="fw-500 fs-12 cursor-pointer"
                  onClick={() => handleSort("type")}
                  style={{ border: "none" ,background: 'white',}}
                >
                  Category Type
                  <svg
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.00004 3.8335V13.1668M8.00004 13.1668L12.6667 8.50016M8.00004 13.1668L3.33337 8.50016"
                      stroke="#667085"
                      strokeWidth="1.33333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </th>
                <th
                  className="fw-500 fs-12 cursor-pointer"
                  onClick={() => handleSort("total_amount")}
                  style={{ border: "none",background: 'white', }}
                >
                  Amount
                  <svg
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.00004 3.8335V13.1668M8.00004 13.1668L12.6667 8.50016M8.00004 13.1668L3.33337 8.50016"
                      stroke="#667085"
                      strokeWidth="1.33333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </th>
                <th
                  className="fw-500 fs-12 cursor-pointer"
                  onClick={() => handleSort("updatedAt")}
                  style={{ border: "none" ,background: 'white',}}
                >
                  Last Update
                  <svg
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.00004 3.8335V13.1668M8.00004 13.1668L12.6667 8.50016M8.00004 13.1668L3.33337 8.50016"
                      stroke="#667085"
                      strokeWidth="1.33333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td
                    colSpan="4"
                    style={{
                      position: 'relative',
                      height: '150px',
                      backgroundColor: '#fff'
                    }}
                  >
                    <Pageloader loading={loading} />
                  </td>
                </tr>
              ) : ledgerData.length > 0 ? (
                ledgerData.map((item) => (
                  <tr
                    key={item.id}
                    onClick={() => handleRowClick(item)}
                    className="cursor-pointer hover-bg-light"
                  >
                    <td
                      className="py-3 fs-13 fw-500"
                      style={{ border: "none" }}
                    >
                      {item.name}
                    </td>
                    <td
                      className="py-3 fs-13 fw-500"
                      style={{ border: "none" }}
                    >
                      {item.type}
                    </td>
                    <td
                      className="py-3 fs-13 fw-500"
                      style={{ border: "none" }}
                    >
                      ₦{(item.total_amount || 0).toLocaleString()}
                    </td>
                    <td
                      className="py-3 fs-13 fw-500"
                      style={{ border: "none" }}
                    >
                      {new Date(item.updatedAt).toLocaleString()}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4" className="text-center py-4" style={{ border: "none" }}>
                    No matching records found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* Pagination */}
      <div className="mt-4">
        {pagination.totalItems > 0 && (
          <div className="d-flex align-items-center justify-content-between">
            <span className="text-sm text-gray-700">
              Showing {(currentPage - 1) * pageSize + 1} to{" "}
              {Math.min(currentPage * pageSize, pagination.totalItems)} of{" "}
              {pagination.totalItems} results
            </span>
            <nav className="pagination">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={!pagination.hasPrevious}
                className="pagination-btn"
              >
                <svg width="8" height="12" viewBox="0 0 8 12" fill="none">
                  <path
                    d="M6.5 11L1.5 6L6.5 1"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>

              {[...Array(pagination.totalPages)].map((_, index) => {
                const pageNumber = index + 1;
                if (
                  pageNumber === 1 ||
                  pageNumber === pagination.totalPages ||
                  (pageNumber >= currentPage - 1 &&
                    pageNumber <= currentPage + 1)
                ) {
                  return (
                    <button
                      key={pageNumber}
                      onClick={() => handlePageChange(pageNumber)}
                      className={`pagination-number ${
                        currentPage === pageNumber ? "active" : ""
                      }`}
                    >
                      {pageNumber}
                    </button>
                  );
                } else if (
                  pageNumber === currentPage - 2 ||
                  pageNumber === currentPage + 2
                ) {
                  return (
                    <span key={pageNumber} className="pagination-ellipsis">
                      ...
                    </span>
                  );
                }
                return null;
              })}

              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={!pagination.hasNext}
                className="pagination-btn"
              >
                <svg width="8" height="12" viewBox="0 0 8 12" fill="none">
                  <path
                    d="M1.5 11L6.5 6L1.5 1"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </nav>
          </div>
        )}
      </div>

      <style jsx>{`
        .table th {
          padding: 12px 24px;
          color: var(--Gray-600);
          font-weight: 500;
          background: white !important;
        }

        .table td {
          padding: 16px 24px;
          font-weight: 500;
        }

        .hover-bg-light:hover {
          background-color: #f9fafb;
        }

        .cursor-pointer {
          cursor: pointer;
        }

        .pagination {
          display: flex;
          align-items: center;
          gap: 8px;
        }

        .pagination-btn {
          border: 1px solid #d1d5db;
          padding: 7px 10px;
          background: white;
          color: #374151;
          cursor: pointer;
        }

        .pagination-btn:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }

        .pagination-number {
          border: 1px solid #d1d5db;
          padding: 7px 10px;
          min-width: 40px;
          text-align: center;
          background: white;

          color: #374151;
          cursor: pointer;
        }

        .pagination-number.active {
          background: #f3f4f6;
          border-color: #9ca3af;
        }

        .pagination-ellipsis {
          color: #6b7280;
        }

        svg {
          transition: transform 0.2s ease;
        }
      `}</style>
    </>
  );
}
