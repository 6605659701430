// ----------------------------------------------------------------------
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import styles from "./LedgerTable.module.css";
import TransactionsTable from "./TransactionsTable";
import AccountTable from "./AccountTable";
import TextField from "@mui/material/TextField";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Button,
  Box,
  Tab,
  Drawer,
  Link,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Link as RouterLink } from "react-router-dom";
import axiosInstance from "src/axiosInstance";
import AccountTypeDropdown from "./AccountTypeDropdown";
import { notifySuccess, notifyError } from "src/hooks/useMockData";

// ----------------------------------------------------------------------

export default function LedgerDetails() {
  // UI Control States
  const [value, setValue] = useState("1");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const [toggleShowLedger, setToggleShowLedger] = useState(false);
  const [toggleShowPosting, setToggleShowPosting] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });

  // Data States
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [ledgerData, setLedgerData] = useState([]);
  const [ledgerEntries, setLedgerEntries] = useState([]);
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");

  // Filter States - Initialize with default values
  const [filters, setFilters] = useState({
    account_category: '',
    category_type: '',
    min_amount: '',
    max_amount: '',
  });

  // Summary Statistics State
  const [summaryStats, setSummaryStats] = useState({
    totalRevenue: 0,
    totalExpenses: 0,
    balance: 0,
  });

  // Form States
  const [item, setItem] = useState({
    name: "",
    type: "",
    description: "",
  });
  const [subAccounts, setSubAccounts] = useState([]);
  const [accountTypes, setAccountTypes] = useState([]);

  // Add currentPage state to LedgerDetails
  const [currentPage, setCurrentPage] = useState(1);

  // Filter Functions
  const handleFilterChange = (name, value) => {
    setFilters(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const clearFilters = () => {
    try {
      setFilters({
        account_category: '',
        category_type: '',
        min_amount: '',
        max_amount: '',
      });
      setStartDate(null);
      setEndDate(null);
      setToggleDropdown(false); 
    } catch (error) {
      console.error('Error clearing filters:', error);
    }
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleToggleDropdown = () => {
    setToggleDropdown(!toggleDropdown);
  };

  const handleItemChange = (e) => {
    const { name, value } = e.target;
    setItem((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubAccountChange = (index, event) => {
    const newSubAccounts = [...subAccounts];
    newSubAccounts[index] = event.target.value;
    setSubAccounts(newSubAccounts);
  };

  const handleAddSubAccounts = () => {
    setSubAccounts([...subAccounts, ""]);
  };

  const handleCreateLedger = async () => {
    console.log("Category:", item);
    console.log("Sub Accounts:", subAccounts);
  };

  // Fetch ledger statistics and account types
  useEffect(() => {
    const fetchLedgerStats = async () => {
      try {
        setLoading(true);
        setError(null);

        const productName = "onepoint";

        // Fetch the summary statistics
        const statsResponse = await axiosInstance.get(
          `/ledger/account-stats?productName=${productName}`
        );

        if (statsResponse.data.status === "success") {
          const statsData = statsResponse.data.data[0];
          setSummaryStats({
            totalRevenue: statsData.total_credit || 0,
            totalExpenses: statsData.total_debit || 0,
            balance: statsData.balance || 0,
          });
        } else {
          setError("Failed to fetch summary statistics");
        }

        // Fetch account types with the product name parameter
        const typesResponse = await axiosInstance.get(
          `/ledger/account-types?productName=${productName}`
        );

        if (typesResponse.data.status === "success") {
          setAccountTypes(typesResponse.data.data || []); // Assuming the account types are in the data array
        } else {
          setError("Failed to fetch account types");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error.response?.data?.message || "Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };

    fetchLedgerStats(); // Call the function to fetch ledger stats and account types
  }, []);

  useEffect(() => {
    const fetchAccountTypes = async () => {
      try {
        const response = await axiosInstance.get(
          `/ledger/account-types?productName=onepoint`
        );
        if (response.data.status === "success") {
          setAccountTypes(response.data.data || []);
        } else {
          console.error("Failed to fetch account types");
        }
      } catch (error) {
        console.error("Error fetching account types:", error);
      }
    };

    fetchAccountTypes(); // Call the function to fetch account types
  }, []);

  const fetchLedgerEntries = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get("/ledger/accounts", {
        params: { productName: "onepoint" },
      });

      if (response.data.status === "success") {
        setLedgerEntries(response.data.data);
      }
    } catch (error) {
      notifyError("Failed to fetch ledger entries");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchLedgerEntries();
  }, []);

  const fetchLedgerData = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get("/ledger/accounts", {
        params: { productName: "onepoint" },
      });

      console.log("API Response:", response.data); // Debug log

      if (response.data.status === "success") {
        // Set the data directly from the response
        setLedgerData(response.data.data || []);
      }
    } catch (error) {
      console.error("Error fetching ledger data:", error);
      notifyError("Failed to fetch ledger data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLedgerData();
  }, [refreshTrigger]);

  // Add this with your other state declarations
  const [isCreatingCategory, setIsCreatingCategory] = useState(false);

  // Update the handleCreateAccount function
  const handleCreateAccount = async () => {
    try {
      if (!item.name || !item.type) {
        return notifyError("Please fill in all required fields");
      }

      // Filter out empty sub-accounts
      const validSubAccounts = subAccounts.filter(account => account.trim() !== "");

      setIsCreatingCategory(true);

      const payload = {
        name: item.name,
        type: item.type,
        sub_accounts: validSubAccounts.map(name => ({ name })) // Format sub-accounts as required
      };

      console.log("Sending payload:", payload); // Debug log

      const response = await axiosInstance.post("/ledger/manual", payload, {
        params: { productName: "onepoint" },
      });

      if (response.data.status === "success") {
        notifySuccess("Account category created successfully");
        setToggleShowLedger(false);
        // Reset all form fields
        setItem({
          name: "",
          type: "",
          description: "",
        });
        setSubAccounts([]); // Clear sub-accounts
        setCurrentPage(1);
        setRefreshTrigger((prev) => prev + 1);
      }
    } catch (error) {
      console.error("Create account error:", error);
      const errorMessage =
        error.response?.data?.data?.errorMessage ||
        error.response?.data?.message ||
        "Failed to create account";
      notifyError(errorMessage);
    } finally {
      setIsCreatingCategory(false);
    }
  };

  const handleExport = async (format) => {
    try {
      const response = await axiosInstance.get(`/ledger/export`, {
        params: {
          format,
          productName: "onepoint",
        },
        responseType: "blob",
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `ledger_accounts.${format.toLowerCase()}`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      notifyError("Failed to export data");
    }
  };

  const applyFilters = () => {
    try {
      // Validate and format filters before applying
      const formattedFilters = {
        account_category: filters.account_category?.trim() || '',
        category_type: filters.category_type || '',
        min_amount: filters.min_amount ? Number(filters.min_amount) : '',
        max_amount: filters.max_amount ? Number(filters.max_amount) : '',
        startDate: startDate,
        endDate: endDate
      };

      console.log('Applying filters:', formattedFilters); // Debug log
      setActiveFilters(formattedFilters);
      setToggleDropdown(false); // Close the filter dropdown after applying
    } catch (error) {
      console.error('Error applying filters:', error);
    }
  };

  // Handle date changes
  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setDateRange({
      startDate: start,
      endDate: end,
    });
  };

  // Fetch data on mount
  useEffect(() => {
    fetchLedgerData();
  }, []);

  // Add this with your other state declarations at the top
  const [activeFilters, setActiveFilters] = useState(null);

  return (
    <>
      <Helmet>
        <title>All Transactions</title>
      </Helmet>

      <div className="px-0 px-md-2">
        <div
          onClick={() => handleToggleDropdown()}
          className={`overlay_div ${toggleDropdown ? "show_overlay" : ""}`}
        ></div>

        <div className="d-flex justify-content-between align-items-center">
          <div className="">
            <h5 className="page_title mb-2">Ledgers</h5>
            <p
              style={{ color: "#4B5563", fontSize: "13px", gap: "8px" }}
              className="mt-2 d-flex align-items-center fw-500 text-dark"
            >
              <Link
                to="/app/ledger-management"
                component={RouterLink}
                className="py-1 d-flex justify-content-center align-items-center"
              >
                <span className="text-dark">Ledger</span>
              </Link>
              <span>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.29289 14.7071C6.90237 14.3166 6.90237 13.6834 7.29289 13.2929L10.5858 10L7.29289 6.70711C6.90237 6.31658 6.90237 5.68342 7.29289 5.29289C7.68342 4.90237 8.31658 4.90237 8.70711 5.29289L12.7071 9.29289C13.0976 9.68342 13.0976 10.3166 12.7071 10.7071L8.70711 14.7071C8.31658 15.0976 7.68342 15.0976 7.29289 14.7071Z"
                    fill="#111827"
                  />
                </svg>
              </span>
              <span className=" text-dark">NGN Ledger</span>
            </p>
          </div>
        </div>

        {/* Account Type Dropdown
        // <AccountTypeDropdown 
        //   accountTypes={accountTypes} 
        //   onSelect={(selectedId) => console.log("Selected Account Type ID:", selectedId)} 
        // /> */}

        <div className="row justify-content-center">
          {/* Total Revenue Card */}
          <div className="col-12 col-md-6 col-lg-4 d-flex justify-content-center mb-3">
            <div className="card py-3 px-3 rounded border-0 bg-light w-100">
              <p className="d-block fs-15 mb-3 text-dark">Total Revenue</p>
              <h2 className="mb-2 fw-600 text-dark">
                ₦{summaryStats.totalRevenue.toLocaleString()}
              </h2>
            </div>
          </div>

          {/* Total Expenses Card */}
          <div className="col-12 col-md-6 col-lg-4 d-flex justify-content-center mb-3">
            <div className="card py-3 px-3 rounded border-0 bg-light w-100">
              <p className="d-block fs-15 mb-3 text-dark">Total Expenses</p>
              <h2 className="mb-2 fw-600 text-dark">
                ₦{summaryStats.totalExpenses.toLocaleString()}
              </h2>
            </div>
          </div>

          {/* Balance Card */}
          <div className="col-12 col-md-6 col-lg-4 d-flex justify-content-center mb-3">
            <div className="card py-3 px-3 rounded border-0 bg-light w-100">
              <p className="d-block fs-15 mb-3 text-dark">Balance</p>
              <h2 className="mb-2 fw-600 text-dark">
                ₦{summaryStats.balance.toLocaleString()}
              </h2>
            </div>
          </div>
        </div>
        <div className="d-md-flex justify-content-between align-items-center mt-sm-4 w-100">
          <div
            className="d-flex justify-content-between align-items-center mb-3 mb-md-0 w-100"
            style={{ gap: "10px", width: "fit-content" }}
          >
            <div
              className="w-50 w-sm-100"
              style={{
                width: "50%",
                "@media (max-width: 776px)": { width: "100%" },
              }}
            >
              <div className="position-relative w-100">
                <img
                  src="/assets/icons/search.svg"
                  alt="Search Icon"
                  style={{
                    position: "absolute",
                    left: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    width: "20px",
                    height: "20px",
                    pointerEvents: "none",
                  }}
                />
                <input
                  type="text"
                  name="search"
                  placeholder="Search"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  style={{
                    paddingLeft: "40px",
                    width: "100%",
                    borderRadius: "0",
                    fontSize: "13px",
                    minHeight: "40px",
                    border: "1px solid #E5E7EB",
                    color: "#6B7280",
                  }}
                  className="form-control rounded-0"
                />
              </div>
            </div>
          </div>
          <div className="d-flex w-100 justify-content-end" style={{}}>
            <div
              className="fit-content d-flex flex-wrap"
              style={{ whiteSpace: "nowrap" }}
            >
              <div className="fit-content">
                {/* <button
                  // to="/app/ledger-management/new-transaction"
                  // component={RouterLink}
                  className="btn border btn-primary"
                  type="button"
                  onClick={() => setToggleShowPosting(true)}
                >
                  <span className="button_text text-white">New Posting</span>
                </button> */}
                &nbsp;&nbsp;
              </div>
              <div className="fit-content last_btn_arrg ml-sm-2">
                {value == 1 && (
                  <div className="btn-group">
                    <button
                      // to="/app/ledger-management/new-account"
                      // component={RouterLink}
                      className="btn border btn-primary "
                      type="button"
                      onClick={() => setToggleShowLedger(true)}
                    >
                      <span className="button_text text-white mr-1">
                        New Account Category
                      </span>
                    </button>
                    <Link
                      onClick={() => handleToggleDropdown()}
                      className="btn border btn-primary rounded d-flex align-items-center ml-2"
                    >
                      <svg
                        width="12"
                        height="13"
                        viewBox="0 0 12 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.7523 5.01514L6.57741 8.19003C6.20246 8.56498 5.58891 8.56498 5.21396 8.19003L2.03906 5.01514"
                          stroke="white"
                          strokeWidth="1.16867"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>{" "}
                      <span className="ml-1 text-white">Filters</span>
                    </Link>
                    <div
                      className={`${
                        toggleDropdown ? "show" : ""
                      } dropdown-menu dropdown-menu-right ${
                        styles.drp_dwn_menu_right_topup
                      } drp_dwn_menu_right`}
                      style={{ transform: "translate3d(-310px, 40px, 0px)" }}
                    >
                      <div
                        className={`${styles.filter_wrap} px-2 px-sm-4 py-3`}
                      >
                        <div className="row">
                          <div className="col-12 mb-1">
                            <div className="mb-3 d-flex flex-column">
                              <label className="fw-500" htmlFor="account_category">
                                Account Category
                              </label>
                              <TextField
                                name="account_category"
                                fullWidth
                                id="account_category"
                                size="small"
                                value={filters.account_category}
                                onChange={(e) => handleFilterChange('account_category', e.target.value)}
                                placeholder="Enter account category"
                              />
                            </div>

                            {/* Category Type Dropdown */}
                            <div className="mb-3 d-flex flex-column">
                              <label className="fw-500" htmlFor="category_type">
                                Category Type
                              </label>
                              <Select
                                labelId="category_type"
                                id="category_type"
                                fullWidth
                                size="small"
                                value={filters.category_type}
                                onChange={(e) => handleFilterChange('category_type', e.target.value)}
                              >
                                <MenuItem value="">All</MenuItem>
                                <MenuItem value="REVENUE">Revenue</MenuItem>
                                <MenuItem value="EXPENSES">Expenses</MenuItem>
                                <MenuItem value="ASSETS">Assets</MenuItem>
                                <MenuItem value="LIABILITIES">Liabilities</MenuItem>
                              </Select>
                            </div>

                            {/* Amount Range */}
                            <div className="mb-3 d-flex flex-column">
                              <label className="fw-500">Amount</label>
                              <div className="d-flex gap-2">
                                <TextField
                                  name="min_amount"
                                  fullWidth
                                  size="small"
                                  type="number"
                                  placeholder="Min"
                                  value={filters.min_amount}
                                  onChange={(e) => handleFilterChange('min_amount', e.target.value)}
                                />
                                <TextField
                                  name="max_amount"
                                  fullWidth
                                  size="small"
                                  type="number"
                                  placeholder="Max"
                                  value={filters.max_amount}
                                  onChange={(e) => handleFilterChange('max_amount', e.target.value)}
                                />
                              </div>
                            </div>

                            {/* Date Range */}
                            <div className="mb-3 d-flex flex-column">
                              <label className="fw-500">Date</label>
                              <div className="start_enddate d-flex gap-2">
                                <div style={{ width: "100%" }}>
                                  <DatePicker
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                    dateFormat="yyyy/MM/dd"
                                    className="form-control border"
                                    placeholderText="Start date"
                                    required
                                  />
                                </div>
                                <div
                                  className="px-2 d-flex align-items-center "
                                  style={{ height: "40px" }}
                                >
                                  <img
                                    src="/assets/icons/line.svg"
                                    style={{ width: "30px" }}
                                    alt=""
                                  />
                                </div>
                                <div style={{ width: "100%" }}>
                                  <DatePicker
                                    selected={endDate}
                                    onChange={(date) => setEndDate(date)}
                                    dateFormat="yyyy/MM/dd"
                                    className="form-control border"
                                    placeholderText="End date"
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="mt-1">
                          <div
                            className="mr-auto"
                            style={{ width: "fit-content" }}
                          >
                            <button
                              type="button"
                              className="btn border bg-white"
                              onClick={clearFilters}
                            >
                              Clear
                            </button>
                            &nbsp; &nbsp;
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={applyFilters}
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {value == 2 && (
                  <div className="btn-group">
                    <Link
                      onClick={() => handleToggleDropdown()}
                      className="btn border btn-primary rounded d-flex align-items-center"
                    >
                      <svg
                        width="12"
                        height="13"
                        viewBox="0 0 12 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.7523 5.01514L6.57741 8.19003C6.20246 8.56498 5.58891 8.56498 5.21396 8.19003L2.03906 5.01514"
                          stroke="white"
                          strokeWidth="1.16867"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>{" "}
                      <span className="ml-1 text-white">Filters</span>
                    </Link>
                    <div
                      className={`${
                        toggleDropdown ? "show" : ""
                      } dropdown-menu dropdown-menu-right ${
                        styles.drp_dwn_menu_right_topup
                      } drp_dwn_menu_right`}
                      style={{ transform: "translate3d(-310px, 40px, 0px)" }}
                    >
                      <div
                        className={`${styles.filter_wrap} px-2 px-sm-4 py-3`}
                      >
                        <div className="row">
                          <div className="col-12 mb-1">
                            <div className="mb-3 d-flex flex-column">
                              <label htmlFor="name">Status</label>
                              <div className="position-relative bg-white">
                                <span className=" all_selectdropdown_icon position-absolute px-2">
                                  <svg
                                    width="20"
                                    height="21"
                                    viewBox="0 0 20 21"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M5.29289 7.79289C5.68342 7.40237 6.31658 7.40237 6.7071 7.79289L9.99999 11.0858L13.2929 7.79289C13.6834 7.40237 14.3166 7.40237 14.7071 7.79289C15.0976 8.18342 15.0976 8.81658 14.7071 9.20711L10.7071 13.2071C10.3166 13.5976 9.68341 13.5976 9.29289 13.2071L5.29289 9.20711C4.90237 8.81658 4.90237 8.18342 5.29289 7.79289Z"
                                      fill="#6B7280"
                                    />
                                  </svg>
                                </span>
                                <Select
                                  labelId="s"
                                  id="s"
                                  fullWidth
                                  size="small"
                                  required
                                  name="verified"
                                  placeholder="Select"
                                  defaultValue="ALL"
                                >
                                  <MenuItem value="ALL">ALL</MenuItem>
                                  <MenuItem value="POSTED">POSTED</MenuItem>
                                </Select>
                              </div>
                            </div>

                            <div className="mb-3 d-flex flex-column">
                              <label htmlFor="ounit">Amount</label>
                              <div className="position-relative">
                                <TextField
                                  name="min_products"
                                  required
                                  type="number"
                                  fullWidth
                                  id="ounit"
                                  placeholder=""
                                  size="small"
                                />
                              </div>
                            </div>

                            <label htmlFor="ounit">Custom Date Range</label>
                            <div className="start_enddate d-flex">
                              <div className="" style={{ width: "100%" }}>
                                <div
                                  className="mb-3 bg-white"
                                  style={{ position: "relative" }}
                                >
                                  <span
                                    style={{
                                      position: "absolute",
                                      right: "10px",
                                      top: "7px",
                                      zIndex: "0",
                                    }}
                                  >
                                    <svg
                                      width="14"
                                      height="14"
                                      viewBox="0 0 14 14"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M2.40527 4.29523C2.40527 3.38641 3.14202 2.64966 4.05084 2.64966H10.6331C11.5419 2.64966 12.2787 3.3864 12.2787 4.29523V10.8775C12.2787 11.7863 11.5419 12.5231 10.6331 12.5231H4.05084C3.14202 12.5231 2.40527 11.7863 2.40527 10.8775V4.29523Z"
                                        stroke="#7B8794"
                                        strokeWidth="1.64557"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M9.53516 1.55298V3.74707"
                                        stroke="#7B8794"
                                        strokeWidth="1.64557"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M5.14844 1.55298V3.74707"
                                        stroke="#7B8794"
                                        strokeWidth="1.64557"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M2.40527 5.94092H12.2787"
                                        stroke="#7B8794"
                                        strokeWidth="1.64557"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </span>
                                  <DatePicker
                                    // showIcon
                                    showYearDropdown
                                    scrollableYearDropdown
                                    yearDropdownItemNumber={15}
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                    dateFormat="dd/MM/yyyy"
                                    className="form-control border"
                                    placeholderText="Start date"
                                    required
                                  />
                                </div>
                              </div>
                              <div
                                className="px-2 d-flex align-items-center "
                                style={{ height: "40px" }}
                              >
                                <img
                                  src="/assets/icons/line.svg"
                                  style={{ width: "30px" }}
                                  alt=""
                                />
                              </div>
                              <div className="" style={{ width: "100%" }}>
                                <div
                                  className="mb-3 bg-white"
                                  style={{ position: "relative" }}
                                >
                                  <span
                                    style={{
                                      position: "absolute",
                                      right: "10px",
                                      top: "7px",
                                      zIndex: "0",
                                    }}
                                  >
                                    <svg
                                      width="14"
                                      height="14"
                                      viewBox="0 0 14 14"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M2.40527 4.29523C2.40527 3.38641 3.14202 2.64966 4.05084 2.64966H10.6331C11.5419 2.64966 12.2787 3.3864 12.2787 4.29523V10.8775C12.2787 11.7863 11.5419 12.5231 10.6331 12.5231H4.05084C3.14202 12.5231 2.40527 11.7863 2.40527 10.8775V4.29523Z"
                                        stroke="#7B8794"
                                        strokeWidth="1.64557"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M9.53516 1.55298V3.74707"
                                        stroke="#7B8794"
                                        strokeWidth="1.64557"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M5.14844 1.55298V3.74707"
                                        stroke="#7B8794"
                                        strokeWidth="1.64557"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M2.40527 5.94092H12.2787"
                                        stroke="#7B8794"
                                        strokeWidth="1.64557"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </span>
                                  <DatePicker
                                    // showIcon
                                    showYearDropdown
                                    scrollableYearDropdown
                                    yearDropdownItemNumber={15}
                                    selected={endDate}
                                    onChange={(date) => setEndDate(date)}
                                    dateFormat="dd/MM/yyyy"
                                    className="form-control border"
                                    placeholderText="End date"
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mt-1">
                          <div
                            className="mr-auto"
                            style={{ width: "fit-content" }}
                          >
                            <button
                              type="button"
                              className={`btn border bg-white`}
                              onClick={() => setToggleDropdown(false)}
                            >
                              Clear
                            </button>
                            &nbsp; &nbsp;
                            <button
                              type="button"
                              className={`btn btn-primary`}
                              onClick={() => setToggleDropdown(false)}
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                &nbsp;&nbsp;
                <div className="btn-group">
                  <button
                    data-toggle="dropdown"
                    aria-expanded="false"
                    className="btn border btn-primary"
                    type="button"
                  >
                    <svg
                      width="12"
                      height="13"
                      viewBox="0 0 12 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.51855 8.93359V10.3937C1.51855 10.9313 1.95436 11.3671 2.49195 11.3671H9.30576C9.84335 11.3671 10.2792 10.9313 10.2792 10.3937V8.93359"
                        stroke="#F3F4F6"
                        strokeWidth="1.86"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3.9519 6.5L5.8987 8.4468L7.8455 6.5"
                        stroke="#F3F4F6"
                        strokeWidth="1.86"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M5.89868 1.6333V8.4471"
                        stroke="#F3F4F6"
                        strokeWidth="1.86"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>{" "}
                    <span className="button_text text-white">Export </span>
                  </button>
                  <div
                    className="dropdown-menu dropdown-menu-right rounded border-0"
                    style={{
                      background: "#fff",
                      boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <button
                      className="dropdown-item dropdown_text fw-500 position-relative"
                      onClick={() => handleExport("CSV")}
                    >
                      CSV
                    </button>
                    <button
                      className="dropdown-item dropdown_text fw-500 position-relative"
                      onClick={() => handleExport("PDF")}
                    >
                      PDF
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{ marginTop: "0px", borderRadius: "10px" }}
          className="p-0 mt-4 create_invoice_tab_section"
        >
          {/* <Box sx={{ borderBottom: 1, borderColor: "divider", }}>
                        
                        </Box> */}
          <AccountTable
            data={ledgerData} // Changed prop name to be more clear
            searchQuery={searchQuery}
            refreshTrigger={refreshTrigger}
            filters={filters}
          />
          {/* <TabPanel value="2" className="invoice_preview_wrap">
                            <TransactionsTable />
                        </TabPanel> */}
        </div>
      </div>

      {/* MODALS */}
      <Drawer
        anchor="right"
        open={toggleShowLedger}
        onClose={() => setToggleShowLedger(false)}
        PaperProps={{
          sx: {
            width: 350,
            maxWidth: "100%",
            border: "none",
            marginTop: "0em",
            height: "100vh",
            padding: "20px",
            paddingTop: "40px",
          },
        }}
      >
        <div style={{ overflowY: "scroll", maxHeight: "90vh" }}>
          <div className="mt-3">
            <p className="fs-16 mb-0 fw-500 text-dark">New Account Category</p>
            <span className="fs-14">Enter the amount you wish to Withdraw</span>
          </div>
          <div className="row mt-5">
            <div className="col-12 mb-1">
              <div className="mb-3 flex-column">
                <label className="fw-500 text-dark" htmlFor="name">
                  Account Name
                </label>
                <TextField
                  name="name"
                  required
                  fullWidth
                  id="name"
                  size="small"
                  value={item.name}
                  onChange={handleItemChange}
                />
              </div>

              <div className="mb-3 d-flex flex-column">
                <label className="fw-500 text-dark" htmlFor="normal_bal">
                  Type
                </label>
                <Select
                  labelId="type"
                  id="type"
                  fullWidth
                  size="small"
                  required
                  name="type"
                  value={item.type}
                  onChange={handleItemChange}
                >
                  <MenuItem value="REVENUE">Revenue</MenuItem>
                  <MenuItem value="EXPENSES">Expenses</MenuItem>
                  <MenuItem value="ASSETS">Assets</MenuItem>
                  <MenuItem value="LIABILITIES">Liabilities</MenuItem>
                </Select>
              </div>

              {subAccounts.map((subAccount, index) => (
                <div key={index} className="mb-3 flex-column">
                  <label className="fw-500 text-dark">
                    Sub account {index + 1}
                  </label>
                  <TextField
                    fullWidth
                    size="small"
                    value={subAccount}
                    onChange={(e) => handleSubAccountChange(index, e)}
                  />
                </div>
              ))}

              <a onClick={handleAddSubAccounts} className="text-dark mb-2 ">
                Add Sub account
              </a>
            </div>
          </div>

          <div className="mt-1 ">
            <button
              type="button"
              className="btn btn-primary w-70 px-4"
              onClick={handleCreateAccount}
              disabled={isCreatingCategory}
            >
              {isCreatingCategory ? (
                <div className="d-flex align-items-center justify-content-center">
                  <CircularProgress size={20} color="inherit" className="text-white mr-2" />
                  <span>Creating...</span>
                </div>
              ) : (
                "Add"
              )}
            </button>
          </div>
        </div>
      </Drawer>

      <Drawer
        anchor="right"
        open={toggleShowPosting}
        onClose={() => setToggleShowPosting(false)}
        PaperProps={{
          sx: {
            width: 300,
            maxWidth: "100%",
            border: "none",
            marginTop: "0em",
            height: "100vh",
            padding: "20px",
            paddingTop: "40px",
          },
        }}
      >
        <span
          className="px- py-2"
          style={{
            fontSize: "25px",
            cursor: "pointer",
            position: "absolute",
            top: "40px",
            right: "20px",
          }}
          onClick={() => setToggleShowPosting(false)}
        >
          &times;
        </span>
        <div
          className={`modal_content px-sm- py-3`}
          style={{ overflowY: "scroll", maxHeight: "90vh" }}
        ></div>
      </Drawer>
    </>
  );
}
