import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Link as RouterLink, useParams, useLocation } from "react-router-dom";
import styles from "./LedgerTable.module.css";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Link } from "@mui/material";
import axiosInstance from "src/axiosInstance";
import Scrollbar from "../../components/scrollbar";
import GlobalPagination from "../../pagination/GlobalPagination";
import Pageloader from "src/pageloader";
import { textAlign } from "@mui/system";

export default function AccountDetails() {
  const { id: ledger_account_id } = useParams();
  const location = useLocation();
  const accountData = location.state?.accountData;

  const [value, setValue] = React.useState("1");
  const [subAccounts, setSubAccounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [accountAmount, setAccountAmount] = useState(0);
  const pageSize = 8;

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    setCurrentPage(1);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Get all accounts to find children
        const accountResponse = await axiosInstance.get(
          `/ledger/EXPENSES?productName=onepoint`
        );

        if (accountResponse.data.status === "success") {
          // Find the parent account that matches our ledger_account_id
          const parentAccount = accountResponse.data.data.find(
            (account) => account.id === parseInt(ledger_account_id)
          );

          if (parentAccount && parentAccount.children) {
            // Set sub-accounts initially
            setSubAccounts(parentAccount.children);
          }

          // Get transactions for the current account
          const transactionResponse = await axiosInstance.get(
            `/ledger/account-transactions/${ledger_account_id}?productName=onepoint`
          );

          if (
            transactionResponse.data.status === "success" &&
            transactionResponse.data.data.length > 0
          ) {
            const transactionData = transactionResponse.data.data[0];

            // Update
            if (parentAccount && parentAccount.children) {
              const updatedSubAccounts = parentAccount.children.map((child) => {
                if (child.id === transactionData.id) {
                  return {
                    ...child,
                    transactions: transactionData.transactions || [],
                  };
                }
                return {
                  ...child,
                  transactions: [],
                };
              });
              setSubAccounts(updatedSubAccounts);
            }

            // Set total amount from transactions
            if (transactionData.transactions) {
              const total = transactionData.transactions.reduce(
                (sum, trans) => {
                  return sum + (Number(trans.amount) || 0);
                },
                0
              );
              setAccountAmount(total);
            }
          }
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [ledger_account_id]);

  return (
    <>
      <Helmet>
        <title>{accountData?.name || "Account Details"} | OnePoint</title>
      </Helmet>

      <div className="px-0 px-md-2">
        {/* Breadcrumb */}
        <div className="d-flex justify-content-between align-items-center">
          <div className="">
            <h5 className="page_title mb-2">Ledgers</h5>
            <p
              className="mt-2 d-flex align-items-center fw-500 text-dark"
              style={{ fontSize: "13px", gap: "8px" }}
            >
              <Link to="/app/ledger-management" component={RouterLink}>
                Ledger
              </Link>
              <span>›</span>
              <Link
                to="/app/ledger-management/ledger-details"
                component={RouterLink}
              >
                NGN Ledger
              </Link>
              <span>›</span>
              <span className="text-dark">{accountData?.name}</span>
            </p>
          </div>
        </div>

        {/* Balance Card */}
        <div
          className="card px-3 py-3 border-0 position-relative mt-4"
          style={{
            background: "rgba(243, 244, 246, 1)",
            borderRadius: "6px",
            width: "100%",
            maxWidth: "350px",
            "@media (min-width: 768px)": {
              width: "17%",
            }
          }}
        >
          <h6 className="text-dark mb-2">{accountData?.name}</h6>
          <h3 className="fw-bold">₦{accountAmount.toLocaleString()}</h3>
        </div>

        {/* Table Section with Tabs */}
        <Box sx={{ width: "100%" }} className="mt-4">
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleTabChange}
                aria-label="ledger tabs"
                variant="scrollable"
                scrollButtons="auto"
              >
                {subAccounts.map((subAccount, index) => (
                  <Tab
                    key={subAccount.id}
                    label={subAccount.name}
                    value={String(index + 1)}
                  />
                ))}
              </TabList>
            </Box>
            {subAccounts.map((subAccount, index) => (
              <TabPanel key={subAccount.id} value={String(index + 1)}>
                <Scrollbar>
                  <div className={`${styles.overflow_table} table-responsive`}>
                    <table className={`${styles.table} table-hover`}>
                      <thead>
                        <tr>
                          <th
                            style={{ border: "none", background: "white" }}
                            className="fw-500 fs-12"
                          >
                            Transaction ID
                            <svg
                              width="16"
                              height="17"
                              viewBox="0 0 16 17"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M8.00004 3.8335V13.1668M8.00004 13.1668L12.6667 8.50016M8.00004 13.1668L3.33337 8.50016"
                                stroke="#667085"
                                strokeWidth="1.33333"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </th>
                          <th
                            style={{ border: "none", background: "white" }}
                            className="fw-500 fs-12"
                          >
                            Type
                            <svg
                              width="16"
                              height="17"
                              viewBox="0 0 16 17"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M8.00004 3.8335V13.1668M8.00004 13.1668L12.6667 8.50016M8.00004 13.1668L3.33337 8.50016"
                                stroke="#667085"
                                strokeWidth="1.33333"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </th>
                          <th
                            style={{ border: "none", background: "white" }}
                            className="fw-500 fs-12"
                          >
                            Amount
                            <svg
                              width="16"
                              height="17"
                              viewBox="0 0 16 17"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M8.00004 3.8335V13.1668M8.00004 13.1668L12.6667 8.50016M8.00004 13.1668L3.33337 8.50016"
                                stroke="#667085"
                                strokeWidth="1.33333"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </th>
                          <th
                            style={{ border: "none", background: "white" }}
                            className="fw-500 fs-12"
                          >
                            Wallet
                            <svg
                              width="16"
                              height="17"
                              viewBox="0 0 16 17"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M8.00004 3.8335V13.1668M8.00004 13.1668L12.6667 8.50016M8.00004 13.1668L3.33337 8.50016"
                                stroke="#667085"
                                strokeWidth="1.33333"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </th>
                          <th
                            style={{ border: "none", background: "white" }}
                            className="fw-500 fs-12"
                          >
                            Date Posted
                            <svg
                              width="16"
                              height="17"
                              viewBox="0 0 16 17"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M8.00004 3.8335V13.1668M8.00004 13.1668L12.6667 8.50016M8.00004 13.1668L3.33337 8.50016"
                                stroke="#667085"
                                strokeWidth="1.33333"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                          <tr>
                            <td
                              colSpan="5"
                              style={{
                                position: "relative",
                                height: "150px",
                                backgroundColor: "#fff",
                              }}
                            >
                              <Pageloader loading={loading} />
                            </td>
                          </tr>
                        ) : subAccount.transactions?.length > 0 ? (
                          subAccount.transactions
                            .slice(
                              (currentPage - 1) * pageSize,
                              currentPage * pageSize
                            )
                            .map((item) => (
                              <tr key={item.transaction_ref || item.id}>
                                <td>{item.transaction_ref}</td>
                                <td>{item.type}</td>
                                <td>₦{Number(item.amount).toLocaleString()}</td>
                                <td>
                                  <div className="d-flex align-items-center gap-2">
                                    <span>🇳🇬</span>
                                    <span>{item.wallet_name}</span>
                                  </div>
                                </td>
                                <td>
                                  {new Date(item.createdAt).toLocaleString()}
                                </td>
                              </tr>
                            ))
                        ) : (
                          <tr>
                            <td colSpan="5" className="text-center">
                              No transactions found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </Scrollbar>

                {subAccount.transactions?.length > 0 && (
                  <GlobalPagination
                    currentPage={currentPage}
                    totalResults={subAccount.transactions.length}
                    pageSize={pageSize}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                )}
              </TabPanel>
            ))}
          </TabContext>
        </Box>
      </div>
    </>
  );
}
