// ----------------------------------------------------------------------
import React from "react";
import { Helmet } from "react-helmet-async";
import styles from "../UserSettings.module.css";
import {
    useEffect,
    useState,
} from "react";
import TextField from "@mui/material/TextField";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import GlobalModal from "../../../Modals/GlobalModal"
import Pagination from "../../../pagination/GlobalPagination";
import Pageloader from 'src/pageloader';

import {
    Link,
    Drawer
} from "@mui/material";
// sections
// mock
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

// import Pagination from '../../../pagination/GlobalPagination'; // Adjust the path as necessary
import Scrollbar from "../../../components/scrollbar";
import axios from "axios"
import axiosInstance from "src/axiosInstance"
import CircularProgress from "@mui/material/CircularProgress";
import { notifySuccess, notifyError } from "src/hooks/useMockData";
import { formatDistanceToNow } from 'date-fns';
// ----------------------------------------------------------------------

export default function TeamTab() {
    // eslint-disable-next-line no-unused-vars
    const apiUrl = process.env.REACT_APP_NAME;
    const [value, setValue] = React.useState("1");
    const [search, setSearch] = React.useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [loadingRole, setLoadingRole] = useState(false);
    const [isLoadingAction, setIsLoadingAction] = useState(false);
    const [InviteTeamsDialog, setInviteTeamsDialog] = useState(false);
    const [ViewRoleDialog, setViewRoleDialog] = useState(false);
    const navigate = useNavigate();
    const [toggleDropdown, setToggleDropdown] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [ViewActionsDialog, setViewActionsDialog] = useState(false);
    const [teamData, setTeamData] = useState([]);
    const [teamRoleData, setTeamRoleData] = useState([]);
    const [teamActionData, setTeamActionData] = useState([]);
    const [teamPagination, setTeamPagination] = useState({
        totalItems: 0,
        totalPages: 0,
        currentPage: 1,
        hasNext: false,
        hasPrevious: false,
    });
    const [item, setItem] = useState({});
    const pageSize = 8;
    // const tabValue = JSON.parse(localStorage.getItem('currentTab'))

    const [isDeleting, setIsDeleting] = useState(false);

    const [inviteEmail, setInviteEmail] = useState('');
    const [selectedRole, setSelectedRole] = useState('select');

    const handleSearch = (e) => {
        setSearch(e.target.value)
    }

    const handleCloseInviteTeamsDialog = () => {
        setInviteTeamsDialog(false);
    };

    const handleOpenViewRoleDialog = () => {
        setViewRoleDialog(true);
    };

    const handleCloseViewRoleDialog = () => {
        setViewRoleDialog(false);
    };
    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleToggleDropdown = () => {
        setToggleDropdown(!toggleDropdown);
    };

    const handleSubmitEvidence = () => {
        setInviteTeamsDialog(false)
    };

    const handleRouteToCustom = () => {
        setInviteTeamsDialog(false)
        navigate("/app/user-settings/custom-role");
    };
    
    const handleGoToInviteTeam = () => {
        navigate(`/app/user-settings/invite-team`);
    };

 

    const totalResults = teamData.length;

    const onPageChange = (page) => {
        setCurrentPage(page);
    };

    const handleCloseViewActionsDialog = () => {
        setViewActionsDialog(false);
    };

    // Calculate the data to display on the current page
    const currentTableData = teamData.slice((currentPage - 1) * pageSize, currentPage * pageSize);

    const formatRelativeTime = (timestamp) => {
        let relativeTime = formatDistanceToNow(new Date(timestamp), { addSuffix: true });
        relativeTime = relativeTime.replace(/^about /, ''); // Remove "about " if it exists at the start
        return relativeTime;
    };

    const getTeamData = async (searchQuery = '') => {
        setIsLoading(true);
        try {
            const response = await axiosInstance.get(`/user-account/team`, {
                params: {
                    productName: 'onepoint',
                    search: searchQuery,
                    page: currentPage,
                    pageSize
                }
            });
            if (response.data?.status === "success") {
                const { data, ...paginationInfo } = response.data.data;
                setTeamData(data?.rows || []);
                setTeamPagination(paginationInfo);
            }
        } catch (err) {
            console.error('Error:', err);
            notifyError(err.response?.data?.message || 'Failed to fetch team data');
        } finally {
            setIsLoading(false);
        }
    };

    const getTeamActionData = async (val) => {
        setIsLoadingAction(true)
        setItem(val)
        try {
            const response = await axiosInstance.get(`/user-activity/${val?.role_id}/actions?productName=${apiUrl}`)
            // notifySuccess(response.data.message)
            const data = response.data.data
            setTeamActionData(data)

        } catch (err) {
            if (err.response) {
                if (err.response.data && err.response.data.data && err.response.data.data.errorMessage) {
                    notifyError(err.response.data.data.errorMessage);
                } else if (err.response.data && err.response.data.message) {
                    notifyError(err.response.data.message);
                } else {
                    notifyError('An unexpected error occurred');
                }
            } else {
                notifyError('Network error or server not responding');
            }
        } finally {
            setIsLoadingAction(false)
        }
    }


    const getTeamRoleData = async (val) => {
        setLoadingRole(true)
        try {
            const response = await axiosInstance.get(`/role/${val?.role_id}?productName=${apiUrl}`)
            // notifySuccess(response.data.message)
            const data = response.data.data
            setTeamRoleData(data)


        } catch (err) {
            if (err.response) {
                if (err.response.data && err.response.data.data && err.response.data.data.errorMessage) {
                    notifyError(err.response.data.data.errorMessage);
                } else if (err.response.data && err.response.data.message) {
                    notifyError(err.response.data.message);
                } else {
                    notifyError('An unexpected error occurred');
                }
            } else {
                notifyError('Network error or server not responding');
            }
        } finally {
            setLoadingRole(false)
        }
    }

    const handleSetItem = (item) => {
        setItem(item)
        handleOpenViewRoleDialog()
        getTeamRoleData(item)
    }


    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getTeamData(search);
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }, [currentPage, search]);

    const groupPermissions = (permissions) => {
        const grouped = {};
        const settingsPermissions = [];
    
        permissions.forEach(permission => {
            if (permission.name.includes('withdraw') || permission.name.includes('convert') || permission.name.includes('transaction') || permission.name.includes('wallet')) {
                const key = 'Wallet Permission';
                if (!grouped[key]) grouped[key] = [];
                grouped[key].push(permission);
            } else if (permission.name.includes('transfer')) {
                const key = 'Transfer Permission';
                if (!grouped[key]) grouped[key] = [];
                grouped[key].push(permission);
            } else if (permission.name.includes('payment')) {
                const key = 'Payment Links Permission';
                if (!grouped[key]) grouped[key] = [];
                grouped[key].push(permission);
            } else if (permission.name.includes('chargeback')) {
                const key = 'Dispute Permission';
                if (!grouped[key]) grouped[key] = [];
                grouped[key].push(permission);
            } else if (permission.name.includes('customer')) {
                const key = 'Customer Permission';
                if (!grouped[key]) grouped[key] = [];
                grouped[key].push(permission);
            } else if (permission.name.includes('invoice')) {
                const key = 'Invoice Permission';
                if (!grouped[key]) grouped[key] = [];
                grouped[key].push(permission);
            } else if (permission.name.includes('ledger') || permission.name.includes('entries')) {
                const key = 'Ledger Permission';
                if (!grouped[key]) grouped[key] = [];
                grouped[key].push(permission);
            } else {
                settingsPermissions.push(permission);
            }
        });
    
        if (settingsPermissions.length > 0) {
            grouped['Settings Permission'] = settingsPermissions;
        }
    
        return grouped;
    };
    

    const groupedPermissions = groupPermissions(teamRoleData?.permissions || []);

    const handleDelete = async (teamMember) => {
        if (!teamMember?.id) {
            notifyError('Invalid team member');
            return;
        }

        if (window.confirm(`Are you sure you want to delete this team member?`)) {
            setIsDeleting(true);
            try {
                await axiosInstance.delete(`/user-account/team/${teamMember.id}`, {
                    params: { productName: apiUrl }
                });
                notifySuccess('Team member deleted successfully');
                getTeamData();
            } catch (err) {
                notifyError(err.response?.data?.message || 'Failed to delete team member');
            } finally {
                setIsDeleting(false);
            }
        }
    };

    const handleInvite = async () => {
        if (!inviteEmail || selectedRole === 'select') {
            notifyError('Please fill in all required fields');
            return;
        }

        try {
            const response = await axiosInstance.post(`/user-account/invite?productName=${apiUrl}`, {
                email: inviteEmail,
                role: selectedRole
            });
            
            if (response.data.status === "success") {
                notifySuccess('Invitation sent successfully');
                handleCloseInviteTeamsDialog();
                setInviteEmail('');
                setSelectedRole('select');
                getTeamData();
            } else {
                notifyError(response.data.message || 'Failed to send invitation');
            }
        } catch (err) {
            notifyError(err.response?.data?.message || 'Failed to send invitation');
        }
    };

    const handlePageChange = (page) => {
        if (page > 0 && page <= teamPagination.totalPages) {
            setCurrentPage(page);
        }
    };

    return (
        <>
            <div className="px-0 px-md-2">

                <div className="d-md-flex justify-content-between align-items-center mt-4 pt-2 w-100">
                    <div className="d-flex justify-content-between align-items-center mb-3 mb-md-0 w-100" style={{ gap: '10px', width: 'fit-content' }}>
                        <div className={`${styles.hold_input} w-100`}>
                            <img src="/assets/icons/search.svg" alt="" />
                            <input
                                type="text"
                                name="search"
                                placeholder="Search"
                                style={{ paddingLeft: "33px", width: "24em", maxWidth: '100%', borderRadius: '0', fontSize: '13px', minHeight: '40px' }}
                                className=" form-control rounded-0"
                                value={search}
                                onChange={handleSearch}
                            />
                        </div>
                    </div>
                    <div className="d-flex justify-content-end">
                        <div className="fit-content d-flex" style={{ whiteSpace: 'nowrap' }}>
                            <div className="btn-group">
                                <button
                                    className="btn border btn-primary pl-3"
                                    type="button"
                                    // onClick={() => setInviteTeamsDialog(true)}
                                    onClick={handleGoToInviteTeam}
                                >
                                    <svg
                                        width="13"
                                        height="14"
                                        viewBox="0 0 15 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M3.75 8H11.25"
                                            stroke="white"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M7.5 11.75V4.25"
                                            stroke="white"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>{" "}
                                    <span className="text-white button_text">Invite <span className="d-none d-sm-inline">New Member</span></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{ marginTop: "0px", borderRadius: "10px" }} className="p-0 mt-4 create_invoice_tab_section">
                    <div className="px-0">

                        <div style={{ marginTop: "0px", borderRadius: "10px" }} className="p-0">
                            <Scrollbar>
                                <div className="table-responsive">
                                    <table className={`${styles.table} table-hover table-striped`}
                                        style={{ minWidth: "50em" }}>
                                        <thead  className={`${styles.thead}`}>
                                        <tr>
                                                <th className="text-muted fs-13 fw-500 th_width" style={{ minWidth: "6%" }}>
                                                    Member {" "}<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M8.00004 3.8335V13.1668M8.00004 13.1668L12.6667 8.50016M8.00004 13.1668L3.33337 8.50016" stroke="#667085" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </th>
                                                <th className="text-muted fs-13 fw-500 th_width">Email {" "}<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.00004 3.8335V13.1668M8.00004 13.1668L12.6667 8.50016M8.00004 13.1668L3.33337 8.50016" stroke="#667085" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg></th>
                                                <th className="text-muted fs-13 fw-500 th_width">Role {" "}<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.00004 3.8335V13.1668M8.00004 13.1668L12.6667 8.50016M8.00004 13.1668L3.33337 8.50016" stroke="#667085" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg></th>
                                                <th className="text-muted fs-13 a-500 th_width">Last Login {" "}<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.00004 3.8335V13.1668M8.00004 13.1668L12.6667 8.50016M8.00004 13.1668L3.33337 8.50016" stroke="#667085" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg></th>
                                             
                                                <th className="text-muted fs-13 fw-500 th_width">Status {" "}<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.00004 3.8335V13.1668M8.00004 13.1668L12.6667 8.50016M8.00004 13.1668L3.33337 8.50016" stroke="#667085" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg></th>
                                                <th className="text-muted fs-13 fw-500 th_width">Action {" "}<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.00004 3.8335V13.1668M8.00004 13.1668L12.6667 8.50016M8.00004 13.1668L3.33337 8.50016" stroke="#667085" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {isLoading ? (
                                                <tr>
                                                    <td colSpan="5" style={{ position: 'relative', height: '150px', backgroundColor: '#fff' }}>
                                                        <Pageloader loading={isLoading} />
                                                    </td>
                                                </tr>
                                            ) : teamData && teamData.length > 0 ? (
                                                teamData.map((item) => (
                                                    <tr key={item.id} className={`${styles.tr} table-fonts`}>
                                                        <td>{item?.user?.name}</td>
                                                        <td>{item?.user?.email}</td>
                                                        <td>{item?.role?.name}</td>
                                                        <td>{formatRelativeTime(item?.createdAt)}</td>
                                                        <td>
                                                            <div className=" ">
                                                                <div
                                                                    className={`${item?.status?.toLowerCase()} badge_style fit-content text-uppercase fw-500 fs-12 d-inline`}
                                                                    style={{
                                                                        backgroundColor: item?.status === 'ACTIVE' ? '#E6F5EE' : '#FEE4E2',
                                                                        color: item?.status === 'ACTIVE' ? '#027A48' : '#B42318',
                                                                        padding: '2px 8px',
                                                                        borderRadius: '4px'
                                                                    }}
                                                                >
                                                                    {item?.status}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <button 
                                                                className="btn btn-link p-0 text-primary"
                                                                onClick={() => {
                                                                    setItem(item);
                                                                    handleOpenViewRoleDialog();
                                                                }}
                                                            >
                                                                View Role
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan="6">
                                                        <div className="alerts alerts-primary text-center mt-4 mb-4">
                                                            <h6>No team members found</h6>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </Scrollbar>

                            {teamPagination.totalItems > 0 && (
                                <div className="mt-4">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <span className="text-sm text-gray-700">
                                            Showing {(currentPage - 1) * pageSize + 1} to{" "}
                                            {Math.min(currentPage * pageSize, teamPagination.totalItems)} of{" "}
                                            {teamPagination.totalItems} results
                                        </span>
                                        <div className="d-flex align-items-center gap-2">
                                            <button
                                                onClick={() => handlePageChange(currentPage - 1)}
                                                disabled={!teamPagination.hasPrevious}
                                                className="btn btn-light px-3 py-2"
                                            >
                                                <svg width="8" height="12" viewBox="0 0 8 12" fill="none">
                                                    <path
                                                        d="M6.5 11L1.5 6L6.5 1"
                                                        stroke="currentColor"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                            </button>

                                            <div className="d-flex align-items-center">
                                                {[...Array(teamPagination.totalPages)].map((_, index) => {
                                                    const pageNumber = index + 1;
                                                    if (pageNumber === currentPage) {
                                                        return (
                                                            <button
                                                                key={pageNumber}
                                                                className="btn btn-light px-3 py-2 active"
                                                                style={{
                                                                    backgroundColor: '#F3F4F6',
                                                                    border: '1px solid #E5E7EB',
                                                                    marginLeft: '4px',
                                                                    marginRight: '4px'
                                                                }}
                                                            >
                                                                {pageNumber}
                                                            </button>
                                                        );
                                                    } else if (pageNumber === 1 || pageNumber === teamPagination.totalPages) {
                                                        return (
                                                            <button
                                                                key={pageNumber}
                                                                onClick={() => handlePageChange(pageNumber)}
                                                                className="btn btn-light px-3 py-2"
                                                                style={{
                                                                    border: '1px solid #E5E7EB',
                                                                    marginLeft: '4px',
                                                                    marginRight: '4px'
                                                                }}
                                                            >
                                                                {pageNumber}
                                                            </button>
                                                        );
                                                    } else if (
                                                        pageNumber === currentPage - 1 ||
                                                        pageNumber === currentPage + 1
                                                    ) {
                                                        return (
                                                            <button
                                                                key={pageNumber}
                                                                onClick={() => handlePageChange(pageNumber)}
                                                                className="btn btn-light px-3 py-2"
                                                                style={{
                                                                    border: '1px solid #E5E7EB',
                                                                    marginLeft: '4px',
                                                                    marginRight: '4px'
                                                                }}
                                                            >
                                                                {pageNumber}
                                                            </button>
                                                        );
                                                    }
                                                    return null;
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>


            {/* <GlobalModal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={ViewRoleDialog}
                onClose={handleCloseViewRoleDialog}
                position="absolute"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                width="570px !important"
                maxWidth="100%"
                overflowY="scroll"
                bgcolor="#F9FAFB"
                border="1px solid #F5F5F5"
                borderRadius="5px"
                boxShadow="24"
                p="25px"
            >
                <span
                    className="px-3 py-2"
                    style={{
                        fontSize: "25px",
                        cursor: "pointer",
                        position: "absolute",
                        top: "0px",
                        right: "0px",
                    }}
                    onClick={handleCloseViewRoleDialog}
                >
                    &times;
                </span>
                <div
                    className={`modal_content w-100`}
                    style={{ overflowY: 'scroll', maxHeight: '85vh', }}
                >
                    <div className="w-100">
                        <div className="w-100">
                            <h6>
                                <span className="text-dark fw-700" style={{ fontSize: '17px' }}>What the role have access to</span>
                            </h6>
                        </div>

                        <div className=" px-3 pt-4">
                            <div className="row ">
                                <div className="col-md-6 px-0 mb-3">
                                    <label htmlFor="" className="fs-14 d-block text-dark">Wallet Permission</label>
                                    <div className="checkbox_div light d-flex align-items-center mb-1">
                                        <label htmlFor="schedule" className="fs-14 cursor-pointer mb-0 fw-400">Can view wallet</label>
                                    </div>

                                    <div className="checkbox_div light d-flex align-items-center mb-1">
                                        <label htmlFor="schedule" className="fs-14 cursor-pointer mb-0 fw-400">Can manage wallet</label>
                                    </div>
                                </div>

                                <div className="col-md-6 px-0 mb-3">
                                    <label htmlFor="" className="fs-14 d-block text-dark">Dispute Permission</label>
                                    <div className="checkbox_div light d-flex align-items-center mb-1">
                                        <label htmlFor="schedule" className="fs-14 cursor-pointer mb-0 fw-400">Can view chargebacks</label>
                                    </div>

                                    <div className="checkbox_div light d-flex align-items-center mb-1">
                                        <label htmlFor="schedule" className="fs-14 cursor-pointer mb-0 fw-400">Can manage chargebacks</label>
                                    </div>
                                </div>

                                <div className="col-md-6 px-0 mb-3">
                                    <label htmlFor="" className="fs-14 d-block text-dark">Transfer Permission</label>
                                    <div className="checkbox_div light d-flex align-items-center mb-1">
                                        <label htmlFor="schedule" className="fs-14 cursor-pointer mb-0 fw-400">Can view transfers</label>
                                    </div>

                                    <div className="checkbox_div light d-flex align-items-center mb-1">
                                        <label htmlFor="schedule" className="fs-14 cursor-pointer mb-0 fw-400">Can create single transfers </label>
                                    </div>

                                    <div className="checkbox_div light d-flex align-items-center mb-1">
                                        <label htmlFor="schedule" className="fs-14 cursor-pointer mb-0 fw-400">Can create bulk transfers </label>
                                    </div>
                                </div>

                                <div className="col-md-6 px-0 mb-3">
                                    <label htmlFor="" className="fs-14 d-block text-dark">Payment Links Permission</label>
                                    <div className="checkbox_div light d-flex align-items-center mb-1">
                                        <label htmlFor="schedule" className="fs-14 cursor-pointer mb-0 fw-400">Can view payment links</label>
                                    </div>

                                    <div className="checkbox_div light d-flex align-items-center mb-1">
                                        <label htmlFor="schedule" className="fs-14 cursor-pointer mb-0 fw-400">Can manage payment links</label>
                                    </div>
                                </div><div className="col-md-6 px-0 mb-3">
                                <label htmlFor="" className="fs-14 d-block text-dark">Customer Permission</label>
                                <div className="checkbox_div light d-flex align-items-center mb-1">
                                    <label htmlFor="schedule" className="fs-14 cursor-pointer mb-0 fw-400">Can view customers</label>
                                </div>

                                <div className="checkbox_div light d-flex align-items-center mb-1">
                                    <label htmlFor="schedule" className="fs-14 cursor-pointer mb-0 fw-400">Can manage customers</label>
                                </div>
                            </div>

                            <div className="col-md-6 px-0 mb-3">
                                <label htmlFor="" className="fs-14 d-block text-dark">Invoice Permission</label>
                                <div className="checkbox_div light d-flex align-items-center mb-1">
                                    <label htmlFor="schedule" className="fs-14 cursor-pointer mb-0 fw-400">Can view Invoice</label>
                                </div>

                                <div className="checkbox_div light d-flex align-items-center mb-1">
                                    <label htmlFor="schedule" className="fs-14 cursor-pointer mb-0 fw-400">Can create Invoice</label>
                                </div>

                                <div className="checkbox_div light d-flex align-items-center mb-1">
                                    <label htmlFor="schedule" className="fs-14 cursor-pointer mb-0 fw-400">Can manage Invoice</label>
                                </div>
                            </div>

                            <div className="col-md-6 px-0 mb-3">
                                <label htmlFor="" className="fs-14 d-block text-dark">Ledger Permission</label>
                                <div className="checkbox_div light d-flex align-items-center mb-1">
                                    <label htmlFor="schedule" className="fs-14 cursor-pointer mb-0 fw-400">Can view account Ledger</label>
                                </div>

                                <div className="checkbox_div light d-flex align-items-center mb-1">
                                    <label htmlFor="schedule" className="fs-14 cursor-pointer mb-0 fw-400">Can manage account Ledger</label>
                                </div>

                                <div className="checkbox_div light d-flex align-items-center mb-1">
                                    <label htmlFor="schedule" className="fs-14 cursor-pointer mb-0 fw-400">Can post entries</label>
                                </div>
                            </div>

                            <div className="col-md-6 px-0 mb-3">
                                <label htmlFor="" className="fs-14 d-block text-dark">Settings Permission</label>
                                <div className="checkbox_div light d-flex align-items-center mb-1">
                                    <label htmlFor="schedule" className="fs-14 cursor-pointer mb-0 fw-400">Can manage user profile</label>
                                </div>

                                <div className="checkbox_div light d-flex align-items-center mb-1">
                                    <label htmlFor="schedule" className="fs-14 cursor-pointer mb-0 fw-400">Can manage business profile</label>
                                </div>

                                <div className="checkbox_div light d-flex align-items-center mb-1">
                                    <label htmlFor="schedule" className="fs-14 cursor-pointer mb-0 fw-400">Can manage notification setting</label>
                                </div>

                                <div className="checkbox_div light d-flex align-items-center mb-1">
                                    <label htmlFor="schedule" className="fs-14 cursor-pointer mb-0 fw-400">Can manage roles and permissions</label>
                                </div>

                                <div className="checkbox_div light d-flex align-items-center mb-1">
                                    <label htmlFor="schedule" className="fs-14 cursor-pointer mb-0 fw-400">Can manage payment and security</label>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </GlobalModal> */}

            <Drawer
                anchor="right"
                open={ViewActionsDialog}
                onClose={handleCloseViewActionsDialog}
                PaperProps={{
                    sx: {
                        width: 350,
                        maxWidth: "100%",
                        border: "none",
                        marginTop: "0em",
                        height: "100vh",
                        padding: "20px",
                    },
                }}
            >
                <div
                    className={`modal_content px-smd-2 pt-3 pb-2`}
                    style={{ overflowY: 'scroll', maxHeight: '85vh', }}
                >
                    <div className="d-flex flex-column justify-content-start align-items-center">
                        <div className="w-100">
                            {/* <h6 className="mb-1">
                                <span className="text-dark fw-500 fs-15">Add Account</span>
                            </h6>
                            <p style={{ fontWeight: '300', color: 'rgba(75, 85, 99, 1)' }}>
                                <small className="fs-12">Update your settlement account here</small>
                            </p> */}

                            <div className="d-flex justify-content-between">
                                <div>
                                    <label htmlFor="" className="fw-500 fs-14 mb-1">Email</label>
                                    <p className="mb-0 fs-13 fw-600">{item?.user?.email}</p>
                                </div>
                                <div className="pr-5">
                                    <label htmlFor="" className="fw-500 fs-14 mb-1 ml-2">Role</label>
                                    <p className="mb-0"><span className=" fs-13 fw-400 ml-2" style={{ color: '#6B7280' }}>{item?.role?.name}</span></p>
                                </div>
                            </div>

                            <div className="w-100 mt-4">
                                <label htmlFor="" className="fw-600 fs-14 mb-0">Action</label>

                                {!isLoadingAction && teamActionData?.map((item) => (
                                    <div
                                        key={item.email}
                                        className="py-2 mb-1 d-flex justify-content-between align-items-center"
                                        style={{ cursor: "pointer" }}
                                    >
                                        <div>
                                            {/* <span className="fs-12 fw-500">{item.email} </span> */}
                                            <span className="fs-12 fw-400">{item.action}</span>
                                        </div>
                                        <span className="fs-12 fw-400">{item.action_time}</span>
                                    </div>
                                ))}

                            </div>
                            {isLoadingAction &&
                                <div className="mt-3" style={{ position: 'relative', height: '150px', backgroundColor: '#F9FAFB' }}>
                                    <Pageloader loading={isLoadingAction} />
                                </div>
                            }
                            {
                                (!isLoadingAction && !teamActionData?.length) &&
                                <div className="alerts alerts-primary text-center mt-4 mb-4">
                                    <h6>No record found</h6>
                                </div>
                            }
                            <div className="close_btn my-4 fit-content ml-auto">
                                <button className="btn border bg-white" onClick={() => setViewActionsDialog(false)}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Drawer>

            <Drawer
                anchor="right"
                open={InviteTeamsDialog}
                onClose={handleCloseInviteTeamsDialog}
                PaperProps={{
                    sx: {
                        width: 550,
                        maxWidth: "100%",
                        border: "none",
                        marginTop: "0em",
                        height: "100vh",
                        padding: "20px",
                    },
                }}
            >
                <span
                    className="px-3 py-2"
                    style={{
                        fontSize: "25px",
                        cursor: "pointer",
                        position: "absolute",
                        top: "0px",
                        right: "0px",
                    }}
                    onClick={() => setInviteTeamsDialog(false)}
                >
                    &times;
                </span>
                <div
                    className={`modal_content`}
                    style={{ overflowY: 'scroll', height: 'auto', }}
                >
                    <div className="d-flex flex-column justify-content-start align-items-center">
                        <div className="w-100">
                            <h6>
                                <span className="text-dark fw-700" style={{ fontSize: '20px' }}>Invite Team Member</span>
                            </h6>
                            <p style={{ fontWeight: '300', color: 'rgba(75, 85, 99, 1)', width: '28em', maxWidth: '100%' }}>
                                <small className="fs-12">Enter names and emails for some team members to invite them to join your organisation on OnePoint.</small>
                            </p>
                        </div>
                        <div className="mb-3" style={{ width: "100%" }}>
                            <label htmlFor="invite_email" className="fs-14">Email</label>
                            <div className="">
                                <TextField
                                    name="invite_email"
                                    required
                                    type="email"
                                    id="invite_email"
                                    value={inviteEmail}
                                    onChange={(e) => setInviteEmail(e.target.value)}
                                    size="small"
                                    placeholder="Enter email address"
                                    fullWidth
                                />
                            </div>
                        </div>

                        <div className="mb-3 d-flex flex-column w-100">
                            <div className="d-flex mb-2 justify-content-between align-items-center">
                                <label htmlFor="name" className="mb-0">Select Role</label>
                                <span className="fs-12 cursor-pointer text-dark fw-500" onClick={handleRouteToCustom} style={{ textDecoration: 'underline', textUnderlineOffset: '3px' }}>Custom Role</span>
                            </div>
                            <div className="position-relative">

                                <span className=" all_selectdropdown_icon position-absolute px-2">
                                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M5.29289 7.79289C5.68342 7.40237 6.31658 7.40237 6.7071 7.79289L9.99999 11.0858L13.2929 7.79289C13.6834 7.40237 14.3166 7.40237 14.7071 7.79289C15.0976 8.18342 15.0976 8.81658 14.7071 9.20711L10.7071 13.2071C10.3166 13.5976 9.68341 13.5976 9.29289 13.2071L5.29289 9.20711C4.90237 8.81658 4.90237 8.18342 5.29289 7.79289Z" fill="#6B7280" />
                                    </svg>
                                </span>
                                <Select
                                    labelId="s"
                                    id="s"
                                    fullWidth
                                    size="small"
                                    required
                                    value={selectedRole}
                                    onChange={(e) => setSelectedRole(e.target.value)}
                                >
                                    <MenuItem value="select">Select</MenuItem>
                                    <MenuItem value="wallet">Wallet Permission</MenuItem>
                                    <MenuItem value="transfer">Transfer Permission</MenuItem>
                                    <MenuItem value="customer">Customer Permission</MenuItem>
                                    <MenuItem value="ledger">Ledger Permission</MenuItem>
                                    <MenuItem value="dispute">Dispute Permission</MenuItem>
                                    <MenuItem value="payment">Payment Links Permission</MenuItem>
                                    <MenuItem value="invoice">Invoice Permission</MenuItem>
                                    <MenuItem value="settings">Settings Permission</MenuItem>
                                </Select>
                            </div>
                        </div>

                        <div className="w-100 mt-3">
                            <button
                                className="btn border btn-primary d-block"
                                onClick={handleInvite}
                                style={{ padding: '8px 20px' }}
                            >
                                <span className="text-white">Send Invite</span>
                            </button>
                        </div>
                    </div>
                </div>
            </Drawer>

            <Drawer
                anchor="right"
                open={ViewRoleDialog}
                onClose={handleCloseViewRoleDialog}
                PaperProps={{
                    sx: {
                        width: 300,
                        maxWidth: "100%",
                        border: "none",
                        marginTop: "0em",
                        height: "100vh",
                        padding: "20px",
                        paddingTop: '40px'
                    },
                }}
            >
                <span
                    className="px-3 py-2"
                    style={{
                        fontSize: "25px",
                        cursor: "pointer",
                        position: "absolute",
                        top: "20px",
                        right: "10px",
                    }}
                    onClick={handleCloseViewRoleDialog}
                >
                    &times;
                </span>
                <div
                    className={`modal_content w-100`}
                    style={{ overflowY: 'scroll', maxHeight: '85vh', }}
                >
                    <div className="w-100">
                        <div className="w-100">
                            <span>Role</span>
                            <h3 className="mt-2">
                                <span className="text-dark fw-700" style={{ fontSize: '22px' }}>Administrator</span>
                            </h3>
                        </div>
                        {
                            !loadingRole &&
                            <>
                                <label htmlFor="" className="fs-15 d-block text-dark pt-4">{teamRoleData?.name}</label>
                                <div className=" px-3">
                                    <div className="row ">
                                        {/* {(!loadingRole && teamRoleData?.permissions?.length > 0) && teamRoleData?.permissions?.map((el,i) => {
                                            
                                            return (
                                                <React.Fragment>
                                                    {Object.keys(groupedPermissions).map((group, a) => (
                                                        <div key={a} className="col-md-12 px-0 mb-3">
                                                            <label htmlFor="" className="fs-15 d-block text-dark pt-4">{group}</label>
                                                            {groupedPermissions[group].map((item, index) => (
                                                                <div key={index} className="checkbox_div light d-flex align-items-center mb-1">
                                                                    <label htmlFor="schedule" className="fs-14 cursor-pointer mb-0 fw-400">{item.name}</label>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    ))}
                                                </React.Fragment>
                                                )
                                            })}
                                
                                        
                                        {
                                                (!loadingRole && !teamRoleData?.permissions?.length) &&
                                                <div className="alerts alerts-primary text-center mt-4 mb-4">
                                                    <h6>No permission record found</h6>
                                                </div>
                                            } */}
                                        {(!loadingRole && teamRoleData?.permissions?.length > 0) ? (
                                            Object.keys(groupedPermissions).map((group, a) => (
                                                <div key={a} className="col-md-12 px-0 mb-3">
                                                    <label htmlFor="" className="fs-15 d-block text-dark pt-4">{group}</label>
                                                    {groupedPermissions[group].map((item, index) => (
                                                        <div key={index} className="checkbox_div light d-flex align-items-center mb-1">
                                                            <label htmlFor="schedule" className="fs-14 cursor-pointer mb-0 fw-400">{item.name}</label>
                                                        </div>
                                                    ))}
                                                </div>
                                            ))
                                        ) : (
                                            <div className="alerts alerts-primary text-center mt-4 mb-4">
                                                <h6>No permission record found</h6>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </>
                        }
                        {loadingRole &&
                           <div className="mt-5" style={{ position: 'relative', height: '150px', backgroundColor: '#F9FAFB' }}>
                                <Pageloader loading={loadingRole} />
                            </div>
                        }
                    </div>
                </div>
            </Drawer>
        </>
    );
}

