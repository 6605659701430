import { Helmet } from 'react-helmet-async';
// import * as Router from 'react-router-dom';
// import Scrollbar from "../../../components/scrollbar";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
// import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import TextField from '@mui/material/TextField';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Iconify from '../../../components/iconify';
import { useApiKeys } from 'src/hooks/useApiKeys';
import axios from 'axios';
import axiosInstance from 'src/axiosInstance';
import CircularProgress from '@mui/material/CircularProgress';
import * as dateFns from 'date-fns';

import {
  Link,
  Checkbox,
  Box,
  AppBar,
  Toolbar,
  Stack,
  Drawer,
  Typography,
  Divider,
  IconButton,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
// import bus from "src/bus";
import GlobalModal from '../../../Modals/GlobalModal';
import styles from '../Wallet.module.css';
import Scrollbar from '../../../components/scrollbar';
import Button from 'src/theme/overrides/Button';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import bus from 'src/bus';
import LiveSelect from 'react-select';
import { notifySuccess, notifyError } from 'src/hooks/useMockData';
import TransactionPINDialog from 'src/Modals/TransactionPINDialog';
import LedgerAccountDropdown from '../../Ledger-Management/LedgerAccountDropdown';

// import TimePicker from 'react-time-picker';
const SCHEDULE_TRANSFER_FREQUENCY = {
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
  QUARTERLY: 'QUARTERLY',
  CUSTOM: 'CUSTOM',
};
const SCHEDULE_TRANSFER_INTERVAL = {
  EVERY_5_DAYS: 'EVERY_5_DAYS',
  EVERY_2_WEEKS: 'EVERY_2_WEEKS',
  EVERY_2_MONTHS: 'EVERY_2_MONTHS',
  BI_ANNUALLY: 'BI_ANNUALLY',
  QUARTERLY: 'QUARTERLY',
};
// ----------------------------------------------------------------------
const formatDateWithSuffix = (date) => {
  const day = format(date, 'do'); // 'do' gives the day of the month with ordinal suffix (e.g., 1st, 2nd, 3rd)
  const month = format(date, 'MMMM');
  const year = format(date, 'yyyy');
  return `${day} ${month} ${year}`;
};

export default function TransferToBankSingle() {
  const [pinError, setPinError] = useState(false);
  const [isPINDialogOpen, setIsPINDialogOpen] = useState(false);

  const apiKeys = useApiKeys();
  const navigate = useNavigate();
  const authData = JSON.parse(localStorage.getItem('auth'));
  const apiUrl = process.env.REACT_APP_NAME;
  const [selectedBankOption, setSelectedBankOption] = useState({});
  const [selectedWalletOption] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [existingBen, setExistingBen] = useState(false);
  const [schedulePayment, setSchedulePayment] = useState(false);
  const [save, setSave] = useState(false);
  const [saveAsBen, setSaveAsBen] = useState(false);
  const [trsnPinDialog, setTrnsPinDialog] = useState(false);
  const [selectedOption, setSelectedOption] = useState('thirdpty');
  const [paymentSuccessfulDialog, setPaymentSuccessfulDialog] = useState(false);
  const [ledgerDialog, setLedgerDialog] = useState(false);
  const [editLedger, setEditLedger] = useState(false);
  const [scheduledOption, setScheduledOption] = useState('DAILY');
  const defaultTime = '00:00'; // Default time in 24-hour format
  const [paymentDate, setPaymentDate] = useState(new Date());
  const [paymentTime, setPaymentTime] = useState(defaultTime);
  const [previewPaymentTime, setPreviewPaymentTime] = useState(null);
  const [interval, setInterval] = useState('EVERY_5_DAYS');
  const [noOfTimes, setNoOfTimes] = useState(1);
  const [banks, setBanks] = useState([]);
  const [walletData, setWalletData] = useState([]);
  const [accountData, setAccountData] = useState([]);
  const [beneficiaryData, setBeneficiaryData] = useState([]);
  const [exchangeRate, setExchangeRate] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [rateLoading, setRateLoading] = useState(false);
  const [rateError, setRateError] = useState('');
  const [isCreating, setIsCreating] = useState(false);
  const [rateStatus, setRateStatus] = useState(false);
  const [notSufficientBal, setNotSufficientBal] = useState('');
  const [nameEnquiryLoading, setNameEnquiryLoading] = useState(false);
  const [isLoadingTransfer, setIsLoadingTransfer] = useState(false);
  let [bank_info, setBankInfo] = useState({
    account_no: '',
    account_name: '',
    bank_code: '',
    bank_name: '',
  });
  const [baseCurrency, setBaseCurrency] = useState('USD');
  const [targetCurrency, setTargetCurrency] = useState('NGN');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [transferDetails, setTransferDetails] = useState({
    account_number: '',
    account_name: '',
    bank_name: '',
    wallet_ref: '',
    merchant_id: '',
    pin: '',
    currency: 'NGN',
    amount: 0,
    description: '',
    transfer_type: '',
    chart_of_account_id: '', // Add this line
    schedule: {
      time: '',
      interval: '',
      frequency: '',
      number_of_occurance: '',
    },
  }); // Initialize with default value of 2
  const [selectedAccount, setSelectedAccount] = useState(''); // State for selected ledger account

  const openPINDialog = () => setIsPINDialogOpen(true);
  const closePINDialog = () => setIsPINDialogOpen(false);

  const goToTransfer = () => {
    navigate('/app/payment/transfers');
  };

  const handleNoOfTimesChange = (event) => {
    setNoOfTimes(event.target.value); // Update the state with the user's typed value
  };

  const handleInterval = (event) => {
    setInterval(event.target.value); // Update the state with the user's typed value
  };

  const handleTimeChange = (event) => {
    const time = event.target.value;
    // console.log(time);
    setPaymentTime(time);

    // Format the time to display as 12:00 AM and 12:00 PM, not 14:00
    const formattedTime = formatTimeTo12Hour(time);
    setPreviewPaymentTime(formattedTime);
  };

  const formatTimeTo12Hour = (time) => {
    const [hours, minutes] = time.split(':');
    let hour = parseInt(hours, 10);
    const isPM = hour >= 12;
    hour = hour % 12 || 12; // Convert 24-hour to 12-hour format, handling midnight as 12
    const formattedHour = hour.toString().padStart(2, '0');
    const period = isPM ? 'PM' : 'AM';
    return `${formattedHour}:${minutes} ${period}`;
  };

  const handleClosePaymentSuccessfulDialog = () => {
    setPaymentSuccessfulDialog(false);
  };

  const handleToggleSuccessDialog = () => {
    setTrnsPinDialog(false);
    setPaymentSuccessfulDialog(true);
  };

  const handleToggleLedgerDialog = () => {
    setPaymentSuccessfulDialog(false);
    setLedgerDialog(true);
    localStorage.setItem('show_ledger_banner', JSON.stringify(true));
  };

  const handleClosePinDialog = () => {
    localStorage.setItem('show_ledger_banner', JSON.stringify(true));
    navigate('/app/payment/transfers');
  };

  const handleCloseLedgerDialog = () => {
    setLedgerDialog(false);
  };

  const handleToggleEditLedger = () => {
    setEditLedger(!editLedger);
  };

  const handleChangeSave = () => {
    setSchedulePayment(true);
    // setSave(!save);
    setSave(false);
  };

  const handleChangeSaveAsBen = () => {
    setSaveAsBen(!saveAsBen);
    if (saveAsBen && transferDetails.account_name !== '') {
      saveBeneficiary();
    }
  };

  const verifyScheduleTrue = () => {
    setSchedulePayment(false);
    setSave(true);
  };

  const handleCloseExistingBen = () => {
    setExistingBen(false);
  };

  const handleCloseSchedulePayment = () => {
    setSchedulePayment(false);
  };

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
    setTransferDetails((prev) => {
      return {
        ...prev,
        account_name: '',
      };
    });
    setBankInfo({ bank_code: '' });
  };

  const handleScheduleChange = (event) => {
    setScheduledOption(event.target.value);
    if (event.target.value !== 'CUSTOM') {
      setInterval('');
      setNoOfTimes(null);
    }
  };

  const fetchBanks = async () => {
    const banksApi = await fetch('https://api.paystack.co/bank');
    const banks = await banksApi.json();
    if (banks.status) {
      setBanks(banks.data);
    }
  };

  const handleBankChange = (e) => {
    const value = e.value;
    const label = e.label;
    setBankInfo((prev) => {
      return {
        ...prev,
        bank_code: value,
        bank_name: label,
      };
    });
  };

  const nameEnquiry = async () => {
    if (
      transferDetails.account_number.length < 10 ||
      bank_info.bank_name === '' ||
      bank_info.bank_code === ''
    )
      return;

    setNameEnquiryLoading(true);
    transferDetails.account_name = '';
    const accountNameApi = await fetch(
      `https://api.paystack.co/bank/resolve?account_number=${transferDetails.account_number}&bank_code=${bank_info.bank_code}`,
      {
        headers: {
          Authorization: `Bearer ${apiKeys.PASTACK.SECRET_KEY}`,
        },
      }
    );
    const accountName = await accountNameApi.json();
    setNameEnquiryLoading(false);

    if (accountName.status) {
      const data = transferDetails;
      data.account_name = accountName.data.account_name;
      data.bank_code = bank_info.bank_code;
      setTransferDetails(data);
    } else {
      notifyError(accountName.message);
    }
  };

  const handleACNChange = (e) => {
    const { value } = e.target;
    if (value.length <= 10 && /^\d*$/.test(value)) {
      handleTransferDetails('account_number', value);
    }
  };

  const onFundWallet = (ref) => {
    localStorage.setItem('toFund', JSON.stringify(ref));
    navigate('/app/wallet/transactions');
  };

  const handleTransferDetails = (key, value) => {
    setTransferDetails((prev) => {
        // Typecast specific fields to numbers
        if (key === 'amount' || key === 'chart_of_account_id') {
            return {
                ...prev,
                [key]: Number(value)
            };
        }
        return {
            ...prev,
            [key]: value
        };
    });

    if (key === 'wallet_ref') {
      // Check if transferDetails.wallet_ref === wallet_ref in walletData
      const matchingWallet = walletData.find(
        (wallet) => wallet.wallet_ref === value
      );

      if (matchingWallet) {
        if (matchingWallet.currency !== transferDetails.currency) {
          setTargetCurrency(matchingWallet.currency);
          setRateStatus(true);
        } else {
          setRateStatus(false);
        }

        updateBalanceStatus(matchingWallet, transferDetails.amount);
      } else {
        resetBalStatus();
      }
    }

    if (key === 'currency') {
      // Check if transferDetails.wallet_ref === wallet_ref in walletData
      const matchingWallet = walletData.find(
        (wallet) => wallet.wallet_ref === transferDetails.wallet_ref
      );

      if (matchingWallet) {
        // Check for currency mismatch
        if (matchingWallet.currency !== value) {
          setBaseCurrency(value);
          setRateStatus(true);
        } else {
          setRateStatus(false);
        }
      } else {
        setRateStatus(false);
      }
    }

    if (key === 'amount') {
      const matchingWallet = walletData.find(
        (wallet) => wallet.wallet_ref === transferDetails.wallet_ref
      );
      if (matchingWallet) {
        updateBalanceStatus(matchingWallet, value);
      } else {
        resetBalStatus();
      }
    }
  };

  const updateBalanceStatus = (wallet, amount) => {
    if (Number(wallet.balance) < Number(amount)) {
      setNotSufficientBal('Balance not sufficient');
    }
    // else if (Number(wallet.balance) > Number(amount) && Number(amount) < 100) {
    //   setNotSufficientBal('Amount should not below N100');
    // }
    else {
      setNotSufficientBal('');
    }
  };

  const resetBalStatus = () => {
    setNotSufficientBal('');
  };

  // useEffect(()=> {
  //   setTransferDetails({})
  // }, [])

  const saveBeneficiary = async () => {
    const payload = {
      bank_code: transferDetails.bank_code,
      bank_name: transferDetails.bank_name,
      account_name: transferDetails.account_name,
      account_number:
        selectedOption === 'thirdpty'
          ? transferDetails.account_number
          : transferDetails.merchant_id,
    };
    try {
      const response = await axiosInstance.post(
        `/payments/beneficiaries?productName=${apiUrl}`,
        payload
      );

      // notifySuccess(response.data.message)
      getBeneficiaries();
    } catch (err) {
      if (err.response) {
        if (
          err.response.data &&
          err.response.data.data &&
          err.response.data.data.errorMessage
        ) {
          notifyError(err.response.data.data.errorMessage);
        } else if (err.response.data && err.response.data.message) {
          notifyError(err.response.data.message);
        } else {
          notifyError('An unexpected error occurred');
        }
      } else {
        notifyError('Network error or server not responding');
      }
    } finally {
    }
  };

  useEffect(() => {
    console.log('TRANSFER DETAILS UPDATED:', transferDetails);
}, [transferDetails]);
  useEffect(() => {}, [transferDetails]);

  //   const checkTransactionStatus = (transaction) => {
  //     for (const key in transaction) {
  //         if (transaction.hasOwnProperty(key) && (key !== 'description' && key !== 'wallet_ref')) {
  //             if (transaction[key] === null || transaction[key] === '') {
  //                 return false;
  //             }
  //         }
  //     }
  //     return true;
  //   };

  const checkStatus = () => {
    if (selectedOption === 'thirdpty') {
      if (
        transferDetails.account_number === '' ||
        transferDetails.account_name === '' ||
        transferDetails.bank_name === '' ||
        transferDetails.bank_code === '' ||
        transferDetails.wallet_ref === '' ||
        transferDetails.amount === '' ||
        transferDetails.amount === null ||
        // transferDetails.amount === 0 ||
        transferDetails.currency === '' ||
        notSufficientBal !== ''
      ) {
        setIsButtonDisabled(true);
      } else {
        setIsButtonDisabled(false);
      }
    }
    if (selectedOption !== 'thirdpty') {
      if (
        transferDetails.merchant_id === '' ||
        transferDetails.account_name === '' ||
        transferDetails.wallet_ref === '' ||
        transferDetails.amount === '' ||
        transferDetails.amount === null ||
        // transferDetails.amount === 0 ||
        transferDetails.currency === ''
      ) {
        setIsButtonDisabled(true);
      } else {
        setIsButtonDisabled(false);
      }
    }
  };

  useEffect(() => {
    checkStatus();
  }, [transferDetails]);

  const handleSendTransfer = async (pin) => {
    transferDetails.pin = pin;

    if (transferDetails.pin === '' || transferDetails.pin.length < 4) {
      return notifyError('Input transaction pin to proceed');
    }

    // Check if chart_of_account_id is selected
    if (!transferDetails.chart_of_account_id) {
      return notifyError('Please select a ledger account');
    }

    setIsLoadingTransfer(true);
    let filter_payload = {};

    // Convert chart_of_account_id to string when adding to payload
    if (transferDetails.chart_of_account_id) {
      filter_payload.chart_of_account_id = String(transferDetails.chart_of_account_id); // Convert to string
    }

    if (save) {
      filter_payload.schedule = {};

      const cleanedTime = paymentTime.replace(/AM|PM/, '').trim();
      filter_payload.schedule.time = cleanedTime;
      if (scheduledOption !== '') {
        filter_payload.schedule.frequency = scheduledOption.toLocaleUpperCase();
      }
      if (scheduledOption === 'CUSTOM' && noOfTimes !== null) {
        filter_payload.schedule.number_of_occurance = noOfTimes;
      }
      if (scheduledOption === 'CUSTOM' && interval !== '') {
        filter_payload.schedule.interval = interval;
      }
    }

    if (transferDetails.account_number !== '') {
      filter_payload.account_number = transferDetails.account_number;
    }
    if (transferDetails.account_name !== '') {
      filter_payload.account_name = transferDetails.account_name;
    }
    if (selectedOption === 'thirdpty') {
      filter_payload.account_name = transferDetails.account_name;
    }
    // if (selectedOption !== 'thirdpty') {
    //   filter_payload.merchant_name = transferDetails.account_name
    // }
    if (transferDetails.bank_name !== '') {
      filter_payload.bank_name = transferDetails.bank_name;
    }
    if (transferDetails.bank_code !== '') {
      filter_payload.bank_code = transferDetails.bank_code;
    }
    if (transferDetails.wallet_ref !== '') {
      filter_payload.wallet_ref = transferDetails.wallet_ref;
    }
    if (transferDetails.merchant_id !== '') {
      filter_payload.merchant_account_id = transferDetails.merchant_id;
    }
    if (transferDetails.pin !== '') {
      filter_payload.pin = transferDetails.pin;
    }
    if (transferDetails.amount !== '' || transferDetails.amount !== null) {
      filter_payload.amount = +transferDetails.amount;
    }
    if (transferDetails.description !== '') {
      filter_payload.description = transferDetails.description;
    }
    if (transferDetails.currency !== '') {
      filter_payload.currency = transferDetails.currency;
    }
    if (selectedOption === 'thirdpty') {
      filter_payload.transfer_type = 'EXTERNAL_TRANSFER';
    }
    if (selectedOption !== 'thirdpty') {
      filter_payload.transfer_type = 'INTERNAL_TRANSFER';
    }

    try {
      const response = await axiosInstance.post(
        `/payments/transfers/single?productName=${apiUrl}`,
        filter_payload
      );
      
      if (saveAsBen && selectedOption === 'thirdpty') {
        saveBeneficiary();
      }
      notifySuccess(response.data.message);
      handleClosePinDialog();
      closePINDialog();
    } catch (err) {
      if (err.response) {
        if (
          err.response.data &&
          err.response.data.data &&
          err.response.data.data.errorMessage
        ) {
          notifyError(err.response.data.data.errorMessage);
          if (err.response.data.data.errorMessage === 'Invalid transaction pin') {
            setPinError(true);
          }
        } else if (err.response.data && err.response.data.message) {
          notifyError(err.response.data.message);
          if (err.response.data.message === 'Invalid transaction pin') {
            setPinError(true);
          }
        } else {
          notifyError('Network error or server not responding');
        }
      } else {
        notifyError('Network error or server not responding');
      }
    } finally {
      setIsLoadingTransfer(false);
      setPinError(false);
    }
  };

  const fetchWalletData = async () => {
    setIsCreating(true);

    try {
      const response = await axiosInstance.get(
        `/wallet/my-wallets?productName=${apiUrl}`
      );

      const data = response.data.data.data;
      setWalletData(data);
    } catch (err) {
      if (err.response) {
        if (
          err.response.data &&
          err.response.data.data &&
          err.response.data.data.errorMessage
        ) {
          notifyError(err.response.data.data.errorMessage);
        } else if (err.response.data && err.response.data.message) {
          notifyError(err.response.data.message);
        } else {
          notifyError('An unexpected error occurred');
        }
      } else {
        notifyError('Network error or server not responding');
      }
    } finally {
      setIsCreating(false);
    }
  };

  const fetchAuthAccounts = async () => {
    setIsCreating(true);

    try {
      const response = await axiosInstance.get(
        `/accounts/${transferDetails?.merchant_id}?productName=${apiUrl}`
      );

      const data = response.data.data.data;
      setTransferDetails((prev) => {
        return {
          ...prev,
          account_name: data.user.name,
        };
      });
      // setAccountData(data);
    } catch (err) {
      if (err.response) {
        if (
          err.response.data &&
          err.response.data.data &&
          err.response.data.data.errorMessage
        ) {
          notifyError(err.response.data.data.errorMessage);
        } else if (err.response.data && err.response.data.message) {
          notifyError(err.response.data.message);
        } else {
          notifyError('An unexpected error occurred');
        }
      } else {
        notifyError('Network error or server not responding');
      }
    } finally {
      setIsCreating(false);
    }
  };

  useEffect(() => {
    if (
      transferDetails.merchant_id !== '' &&
      transferDetails.merchant_id !== undefined &&
      transferDetails.merchant_id !== null
    ) {
      // console.log(transferDetails.merchant_id,22);
      fetchAuthAccounts();
    }
  }, [transferDetails.merchant_id]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const getBeneficiaries = async () => {
    setIsCreating(true);

    const payload = {
      search: searchQuery,
    };

    try {
      const response = await axiosInstance.get(
        `/payments/beneficiaries?productName=${apiUrl}`,
        {
          params: {
            search: searchQuery,
          },
        }
      );

      setBeneficiaryData(response.data.data.data);
    } catch (err) {
      if (err.response) {
        if (
          err.response.data &&
          err.response.data.data &&
          err.response.data.data.errorMessage
        ) {
          notifyError(err.response.data.data.errorMessage);
        } else if (err.response.data && err.response.data.message) {
          notifyError(err.response.data.message);
        } else {
          notifyError('An unexpected error occurred');
        }
      } else {
        notifyError('Network error or server not responding');
      }
    } finally {
      setIsCreating(false);
    }
  };

  useEffect(() => {
    if (paymentTime && scheduledOption) {
      getNextPaymentDate(paymentTime, scheduledOption, noOfTimes, interval);
    }
  }, [paymentTime, scheduledOption, interval]);

  const getNextPaymentDate = (
    time,
    frequency,
    number_of_occurance = noOfTimes,
    interval
  ) => {
    const [hours, minutes] = time.split(':').map(Number);
    let nextDate = new Date();

    nextDate.setHours(hours, minutes, 0, 0);

    switch (frequency) {
      case SCHEDULE_TRANSFER_FREQUENCY.DAILY:
        nextDate = dateFns.addDays(nextDate, 1);
        break;
      case SCHEDULE_TRANSFER_FREQUENCY.WEEKLY:
        nextDate = dateFns.addWeeks(nextDate, 1);
        break;
      case SCHEDULE_TRANSFER_FREQUENCY.MONTHLY:
        nextDate = dateFns.addMonths(nextDate, 1);
        break;
      case SCHEDULE_TRANSFER_FREQUENCY.QUARTERLY:
        nextDate = dateFns.addQuarters(nextDate, 1);
        break;
      case SCHEDULE_TRANSFER_FREQUENCY.CUSTOM:
        if (number_of_occurance > 0 && interval) {
          switch (interval) {
            case SCHEDULE_TRANSFER_INTERVAL.EVERY_5_DAYS:
              // console.log(number_of_occurance);
              nextDate = dateFns.addDays(nextDate, 5);
              break;
            case SCHEDULE_TRANSFER_INTERVAL.EVERY_2_WEEKS:
              nextDate = dateFns.addWeeks(nextDate, 2);
              break;
            case SCHEDULE_TRANSFER_INTERVAL.EVERY_2_MONTHS:
              nextDate = dateFns.addMonths(nextDate, 2);
              break;
            case SCHEDULE_TRANSFER_INTERVAL.BI_ANNUALLY:
              nextDate = dateFns.addMonths(nextDate, 6);
              break;
            case SCHEDULE_TRANSFER_INTERVAL.QUARTERLY:
              nextDate = dateFns.addQuarters(nextDate, 1);
              break;
            default:
              throw new Error('Invalid custom interval');
          }
        }
        break;
      default:
    }
    setPaymentDate(nextDate);
    return nextDate;
  };

  const handleSetBeneficiary = (item) => {
    setSelectedBankOption((prev) => {
      if (document.querySelector('.css-1dimb5e-singleValue'))
        document.querySelector('.css-1dimb5e-singleValue').innerHTML =
          item.bank_name;
      return {
        ...prev,
        code: item.bank_code,
        label: item.bank_name,
      };
    });
    setTransferDetails((prev) => {
      return {
        ...prev,
        account_name: item.account_name,
        bank_code: item.bank_code,
        bank_name: item.bank_name,
        account_number: item.account_number,
      };
    });
    setExistingBen(false);
  };

  const fetchExchangeRate = async (base, target) => {
    const apiKey = '2a80c724e7f96161953be8ae'; 
    const endpoint = `https://v6.exchangerate-api.com/v6/${apiKey}/pair/${base}/${target}`;

    try {
      const response = await axios.get(endpoint);
      const rate = response.data.conversion_rate;
      setExchangeRate(rate);
      setRateLoading(false);
    } catch (err) {
      console.error('Failed to fetch exchange rate:', err);
      setRateError('Failed to fetch exchange rate');
      setRateLoading(false);
    }
  };

  useEffect(() => {
    fetchExchangeRate(baseCurrency, targetCurrency);
    // console.log(baseCurrency, targetCurrency);
  }, [baseCurrency, targetCurrency]);

  useEffect(() => {
    getBeneficiaries(searchQuery);
  }, [searchQuery]);

  useEffect(() => {
    fetchWalletData();
    fetchBanks();
  }, []);

  useEffect(() => {
    if (bank_info.bank_code !== '') {
      nameEnquiry();
    }
    const data = transferDetails;
    data.bank_name = bank_info.bank_name;
  }, [bank_info]);

  const handleAccountSelect = (accountId) => {
    setSelectedAccount(accountId); 
  };

  const handleLedgerAccountSelect = (selectedAccount) => {
    console.log('Selected Account:', selectedAccount); 
    
    setTransferDetails(prev => ({
        ...prev,
        chart_of_account_id: Number(selectedAccount.id) 
    }));
  };

  return (
    <>
      <Helmet>
        <title> Bank Transfer - Single Payment </title>
      </Helmet>

      <div className="wrapper">
        <div
          className={`${styles.header_wrap} d-sm-flex justify-content-between align-items-center`}
        >
          <div className="d-flex">
            <Link
              className="d-block text-secondary"
              to="/app/payment/transfers"
              component={RouterLink}
            >
              <i
                className="bi bi-x"
                style={{ fontSize: '1.5em', lineHeight: '1' }}
              ></i>
            </Link>
            <h6
              className="pl-2 pl-sm-3 mb-0 fw-700 pb-0"
              style={{ lineHeight: '1.5' }}
            >
              Single Transfer
            </h6>
          </div>
          <div className="header_btn_group d-none d-sm-block mt-2 mt-sm-0">
            <div className="btn-group">
              <button
                className="btn border btn-primary"
                onClick={openPINDialog}
                disabled={isButtonDisabled}
              >
                <span className="button_text text-white">Send Transfer</span>
              </button>
            </div>
          </div>
        </div>

        <div className="header_btn_group d-block d-sm-none mt-3 mt-sm-0">
          <div className="btn-group">
            <button className="btn border btn-primary" onClick={openPINDialog}>
              <span className="button_text text-white">Send Transfer</span>
            </button>
          </div>
        </div>

        <div className="row pb-5">
          <div className="col-md-4">
            <div className="mb-3 left_side_select px-0 py-sm-4">
              <label htmlFor="pty">Send money to:</label>
              <div className="position-relative">
                <span className=" all_selectdropdown_icon position-absolute bg-white px-2">
                  <svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.43287 8.84964L7.10787 11.5246L8.7412 13.1663C9.43287 13.858 10.5579 13.858 11.2495 13.1663L15.5662 8.84964C16.1329 8.28298 15.7245 7.31631 14.9329 7.31631H10.2579H5.0662C4.2662 7.31631 3.8662 8.28298 4.43287 8.84964Z"
                      fill="#4B5563"
                    />
                  </svg>
                </span>
                <Select
                  labelId="pty"
                  id="pty"
                  fullWidth
                  size="small"
                  required
                  name="verified"
                  value={selectedOption}
                  onChange={handleChange}
                >
                  <MenuItem value="thirdpty">To third party</MenuItem>
                  <MenuItem value="onepointID">To OnePoint ID</MenuItem>
                </Select>
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <div
              className={`${styles.trftobank_side_wrap} px-0 px-sm-2 py-sm-4`}
            >
              {/* {selectedOption === 'thirdpty' && ( */}
              <div>
                {selectedOption === 'thirdpty' && (
                  <div className="mb-3">
                    <label htmlFor="ounit">Account Number</label>
                    <div className="" style={{ width: '100%' }}>
                      <div className="">
                        <TextField
                          name="account_number"
                          required
                          type="number"
                          id="ounit"
                          size="small"
                          placeholder=""
                          fullWidth
                          value={transferDetails.account_number}
                          onChange={handleACNChange}
                        />
                      </div>
                    </div>
                    <div className="mb-3 mt-3">
     
  </div>
                    <small className="d-block text-left mt-2">
                      <Link
                        className="text-primary font-weight-bold"
                        component={RouterLink}
                        onClick={() => setExistingBen(true)}
                      >
                        <small
                          className="fw-500"
                          style={{
                            textDecoration: 'underline',
                            textUnderlineOffset: '2px',
                          }}
                        >
                          Select from existing beneficiaries
                        </small>
                      </Link>
                    </small>
                  </div>
                )}

                {selectedOption === 'onepointID' && (
                  <div className="mb-3">
                    <label htmlFor="merchant_id">Onepoint ID</label>
                    <div className="" style={{ width: '100%' }}>
                      <div className="">
                        <TextField
                          name="merchant_id"
                          required
                          type="text"
                          id="merchant_id"
                          size="small"
                          placeholder=""
                          fullWidth
                          value={transferDetails.merchant_id}
                          onChange={(e) =>
                            handleTransferDetails('merchant_id', e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}

                {selectedOption === 'thirdpty' && (
                  <>
                    <div className="mb-3">
                      <label htmlFor="ounit">Bank</label>
                      <div className="" style={{ width: '100%' }}>
                        <div className="position-relative">
                          <LiveSelect
                            defaultValue={
                              <div className="mb-3">
                                <label htmlFor="ounit">Bank</label>
                                <div className="" style={{ width: '100%' }}>
                                  <div className="position-relative">
                                    <LiveSelect
                                      defaultValue={selectedBankOption}
                                      onChange={handleBankChange}
                                      options={banks?.map((option) => ({
                                        value: option.code,
                                        label: option.name,
                                      }))}
                                    />
                                  </div>
                                </div>
                              </div>
                            }
                            onChange={handleBankChange}
                            options={banks?.map((option) => ({
                              value: option.code,
                              label: option.name,
                            }))}
                          />
                          {/* <Select
                            labelId="s"
                            id="s"
                            fullWidth
                            size="small"
                            required
                            name="bank_name"
                            defaultValue="operation"
                            value={transferDetails.bank_name}
                            onChange={(e) => handleTransferDetails('bank_name', e.target.value)}
                          >
                            <MenuItem value="operation">First Bank</MenuItem>
                            <MenuItem value="banking">Access Bank</MenuItem>
                            <MenuItem value="finance">Wema Bank</MenuItem>
                          </Select> */}
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className="">
                  {selectedOption === 'thirdpty' &&
                    transferDetails.account_name !== '' && (
                      <>
                        <label htmlFor="acct_name">
                          Account Name
                          {nameEnquiryLoading && (
                            <CircularProgress
                              className="ml-1 mt-1"
                              style={{ color: '#4B5563' }}
                              size="0.8rem"
                            />
                          )}
                        </label>
                        <div className="" style={{ width: '100%' }}>
                          <div className="">
                            <TextField
                              name="account_name"
                              required
                              type="text"
                              id="acct_name"
                              size="small"
                              placeholder=""
                              fullWidth
                              value={transferDetails.account_name}
                              readOnly={true}
                              disabled={true}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  {selectedOption !== 'thirdpty' &&
                    transferDetails.account_name !== '' && (
                      <>
                        <label htmlFor="acct_name">
                          Merchant Name
                          {nameEnquiryLoading && (
                            <CircularProgress
                              className="ml-1 mt-1"
                              style={{ color: '#4B5563' }}
                              size="0.8rem"
                            />
                          )}
                        </label>
                        <div className="" style={{ width: '100%' }}>
                          <div className="">
                            <TextField
                              name="account_name"
                              required
                              type="text"
                              id="acct_name"
                              size="small"
                              placeholder=""
                              fullWidth
                              value={transferDetails.account_name}
                              readOnly={true}
                              disabled={true}
                            />
                          </div>
                        </div>
                      </>
                    )}
                </div>
                {selectedOption === 'thirdpty' && (
                  <div className="checkbox_div pt-2 mb-2">
                    <Checkbox
                      id="save_as_ben"
                      checked={saveAsBen}
                      onClick={handleChangeSaveAsBen}
                      style={{}}
                    />
                    <label
                      htmlFor="save_as_ben"
                      className="ml-1 text-dark"
                      style={{ fontSize: '12px', cursor: 'pointer' }}
                    >
                      Save as beneficiary
                    </label>
                  </div>
                )}

                <div className="mb-3">
                  <label htmlFor="name">
                    Select Wallet
                    {isCreating && (
                      <CircularProgress
                        style={{ color: '#222222' }}
                        className="text-white ml-1"
                        size="0.8rem"
                      />
                    )}
                  </label>
                  <div className="position-relative">
                    <span className=" all_selectdropdown_icon position-absolute bg-white px-2">
                      <svg
                        width="20"
                        height="21"
                        viewBox="0 0 20 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M5.29289 7.79289C5.68342 7.40237 6.31658 7.40237 6.7071 7.79289L9.99999 11.0858L13.2929 7.79289C13.6834 7.40237 14.3166 7.40237 14.7071 7.79289C15.0976 8.18342 15.0976 8.81658 14.7071 9.20711L10.7071 13.2071C10.3166 13.5976 9.68341 13.5976 9.29289 13.2071L5.29289 9.20711C4.90237 8.81658 4.90237 8.18342 5.29289 7.79289Z"
                          fill="#6B7280"
                        />
                      </svg>
                    </span>
                    {/* <LiveSelect
                      defaultValue={selectedWalletOption}
                      onChange={handleBankChange}
                      options={banks?.map((option) => ({
                        value: option.code,
                        label: option.name,
                      }))}
                    /> */}
                    <Select
                      labelId="s"
                      id="s"
                      fullWidth
                      size="small"
                      required
                      name="wallet_ref"
                      defaultValue="operation"
                      value={transferDetails.wallet_ref}
                      onChange={(e) =>
                        handleTransferDetails('wallet_ref', e.target.value)
                      }
                    >
                      {walletData &&
                        walletData.map((el, i) => {
                          return (
                            <MenuItem value={el?.wallet_ref}>
                              {el?.name} - ₦{el?.balance}
                            </MenuItem>
                          );
                        })}
                      {!walletData.length && (
                        <MenuItem value="">No Wallet Found</MenuItem>
                      )}
                    </Select>
                  </div>
                </div>
                 <div className="mb-3">
                    <label htmlFor="ledger-account">Select Ledger Account</label>
                    <LedgerAccountDropdown
                        onSelect={handleLedgerAccountSelect}
                    />
                </div>

                <div className="mb-3 input_amt">
                  <label htmlFor="">Input Amount</label>
                  <div className={`position-relative`}>
                    <div className={`${styles.charges_input}`}>
                      <div className={`${styles.select_wrap}`}>
                        <div className=" all_selectdropdown_icon position-absolute px-1 bg-white">
                          <svg
                            width="10"
                            height="7"
                            viewBox="0 0 10 7"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M0.292893 0.792893C0.683416 0.402369 1.31658 0.402369 1.7071 0.792893L4.99999 4.08579L8.29288 0.792893C8.6834 0.402369 9.31657 0.402369 9.70709 0.792893C10.0976 1.18342 10.0976 1.81658 9.70709 2.20711L5.7071 6.20711C5.31657 6.59763 4.68341 6.59763 4.29289 6.20711L0.292893 2.20711C-0.0976309 1.81658 -0.0976309 1.18342 0.292893 0.792893Z"
                              fill="#6B7280"
                            />
                          </svg>
                        </div>
                        <Select
                          labelId="s"
                          id="s"
                          size="small"
                          required
                          name="currency"
                          defaultValue="ngn"
                          className="remove_lines"
                          style={{ width: 'fit-content' }}
                          value={transferDetails.currency}
                          onChange={(e) =>
                            handleTransferDetails('currency', e.target.value)
                          }
                        >
                          <MenuItem value="NGN">NGN</MenuItem>
                          <MenuItem value="USD">USD</MenuItem>
                          <MenuItem value="CNY">CNY</MenuItem>
                        </Select>
                      </div>
                      <input
                        type="number"
                        placeholder=""
                        style={{ width: '100%', textIndent: '65px' }}
                        className=" form-control border"
                        name="amount"
                        disabled={transferDetails?.wallet_ref === ''}
                        value={transferDetails?.amount}
                        onChange={(e) =>
                          handleTransferDetails('amount', e.target.value)
                        }
                      />
                    </div>
                  </div>
                  {rateStatus && (
                    <>
                      <small
                        className="text-secondary font-weight-normal"
                        style={{ fontSize: '12px' }}
                      >
                        Exchange rate{' '}
                        {baseCurrency === 'CNY'
                          ? '¥'
                          : baseCurrency === 'USD'
                          ? '$'
                          : 'N'}
                        1 ={' '}
                        {targetCurrency === 'CNY'
                          ? '¥'
                          : targetCurrency === 'USD'
                          ? '$'
                          : 'N'}
                        {Number(exchangeRate).toLocaleString() || 0}
                        {/* Exchange rate 1 = {Number(exchangeRate).toLocaleString()  || 0} */}
                      </small>
                      <small
                        className="text-danger d-block"
                        style={{ fontSize: '12px' }}
                      >
                        {rateError}
                      </small>
                    </>
                  )}
                  <small
                    className="text-danger"
                    style={{ fontSize: '13px', color: 'red' }}
                  >
                    {notSufficientBal !== '' && (
                      <>
                        <i className="bi bi-exclamation-circle-fill text-danger"></i>{' '}
                        <span className="text-danger">
                          Insufficient funds, kindly
                        </span>{' '}
                        <span
                          className="font-weight-bold text-danger"
                          style={{
                            textDecoration: 'underline',
                            cursor: 'pointer',
                          }}
                          onClick={() =>
                            onFundWallet(transferDetails?.wallet_ref)
                          }
                        >
                          Fund Wallet
                        </span>
                      </>
                    )}
                  </small>
                </div>

                <div className="">
                  <label htmlFor="description">Description</label>
                  <div className="" style={{ width: '100%' }}>
                    <div className="">
                      <TextField
                        name="description"
                        required
                        type="text"
                        id="description"
                        size="small"
                        placeholder="Enter transfer description"
                        fullWidth
                        value={transferDetails.description}
                        onChange={(e) =>
                          handleTransferDetails('description', e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="checkbox_div pt-2">
                  <Checkbox
                    id="schedule"
                    checked={save}
                    onClick={handleChangeSave}
                    style={{}}
                  />
                  <label
                    htmlFor="schedule"
                    className="ml-1 text-dark"
                    style={{ fontSize: '12px', cursor: 'pointer' }}
                  >
                    Schedule payment{' '}
                    <span className="ml-1">
                      <svg
                        width="11"
                        height="11"
                        viewBox="0 0 11 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="5.5" cy="5.5" r="5.5" fill="#6B7280" />
                        <path
                          d="M5.3125 7.03587V6.98864C5.31581 6.6804 5.34647 6.43513 5.40447 6.25284C5.46413 6.07055 5.54865 5.92306 5.65803 5.81037C5.7674 5.69768 5.89915 5.59493 6.05327 5.50213C6.1527 5.43916 6.24219 5.36873 6.32173 5.29084C6.40128 5.21295 6.46425 5.12346 6.51065 5.02237C6.55706 4.92128 6.58026 4.80942 6.58026 4.68679C6.58026 4.5393 6.54545 4.4117 6.47585 4.30398C6.40625 4.19626 6.31345 4.1134 6.19744 4.0554C6.0831 3.99574 5.95549 3.96591 5.81463 3.96591C5.68703 3.96591 5.56522 3.99242 5.44922 4.04545C5.33321 4.09848 5.2371 4.18134 5.16087 4.29403C5.08464 4.40507 5.04072 4.54841 5.02912 4.72408H4.27344C4.28504 4.42578 4.36044 4.17389 4.49964 3.96839C4.63885 3.76125 4.8228 3.60464 5.05149 3.49858C5.28184 3.39252 5.53622 3.33949 5.81463 3.33949C6.11956 3.33949 6.38636 3.39666 6.61506 3.51101C6.84375 3.6237 7.02107 3.78196 7.14702 3.9858C7.27462 4.18797 7.33842 4.42412 7.33842 4.69425C7.33842 4.87985 7.30942 5.04723 7.25142 5.19638C7.19342 5.34387 7.11056 5.47562 7.00284 5.59162C6.89678 5.70762 6.76918 5.81037 6.62003 5.89986C6.47917 5.98769 6.36482 6.07884 6.27699 6.1733C6.19081 6.26776 6.12784 6.37962 6.08807 6.50888C6.0483 6.63814 6.02675 6.79806 6.02344 6.98864V7.03587H5.3125ZM5.68786 8.54723C5.55197 8.54723 5.43513 8.49917 5.33736 8.40305C5.23958 8.30528 5.1907 8.18762 5.1907 8.05007C5.1907 7.91418 5.23958 7.79818 5.33736 7.70206C5.43513 7.60428 5.55197 7.5554 5.68786 7.5554C5.82209 7.5554 5.93809 7.60428 6.03587 7.70206C6.1353 7.79818 6.18501 7.91418 6.18501 8.05007C6.18501 8.14122 6.16181 8.22491 6.11541 8.30114C6.07067 8.37571 6.01101 8.43537 5.93643 8.48011C5.86186 8.52486 5.779 8.54723 5.68786 8.54723Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                  </label>
                </div>

                {save && (
                  <div className="">
                    <label htmlFor="ounit">Your Next payment is on</label>
                    <div
                      className="cursor-pointer"
                      style={{ width: '100%' }}
                      onClick={handleChangeSave}
                    >
                      <div
                        className="mb-3 w-100 custom_short_datepkr bg-light"
                        style={{ position: 'relative' }}
                      >
                        <div className="p-2 border rounded-0 ">
                          <p className="mb-0">
                            {format(paymentDate, 'do MMMM yyyy - h:mmaaa')}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <TransactionPINDialog
          open={isPINDialogOpen}
          onClose={closePINDialog}
          onSubmit={handleSendTransfer}
          isLoading={isLoadingTransfer}
          pinError={pinError}
        />

        {/* <GlobalModal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={trsnPinDialog}
          onClose={handleClosetrsnPinDialog}
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          width="500px !important"
          maxWidth="100%"
          overflowY="scroll"
          bgcolor="#FFFF"
          border="1px solid #F5F5F5"
          borderRadius="5px"
          boxShadow="24"
          p="25px"
        >
          <div
            className={`modal_content`}
            style={{ overflowY: "scroll", height: "auto" }}
          >
            <div className="d-flex flex-column justify-content-center align-items-center">
              <div className="text-center">
                <h6>
                  <span
                    className="text-dark modals_head_title"
                    style={{ fontWeight: "500" }}
                  >

                  </span>
                </h6>
                <p style={{ fontWeight: "300", color: "rgba(75, 85, 99, 1)" }}>
                  <small>

                  </small>
                </p>
              </div>
              <div className="" style={{ width: "100%" }}>
                <div className="">
                  <TextField
                    name="pin_input"
                    required
                    type="text"
                    id="ounit"
                    value={PIN}
                    onChange={handlePINChange}
                    inputProps={{
                      maxLength: 4,
                      style: { textAlign: "center" },
                    }}
                    size="small"
                    placeholder=""
                    fullWidth
                  />
                </div>
              </div>
              <div className="w-100 mt-4">
                <button
                  className="btn border btn-primary d-block w-100"
                  onClick={handleToggleSuccessDialog}
                  style={{ padding: "12px" }}
                >
                  <span className="text-white">Proceed</span>
                </button>
              </div>
            </div>
          </div>
        </GlobalModal> */}

        {/* <Drawer
          anchor="right"
          open={trsnPinDialog}
          onClose={handleClosetrsnPinDialog}
          PaperProps={{
            sx: {
              width: 300,
              maxWidth: "100%",
              border: "none",
              overflow: "",
              marginTop: "0em",
              height: "100vh",
            },
          }}
        >
          <div className="w-100 px-2 px-md-4 drawer_minHeight">
            <div className="px-2 px-md-0">
              <Stack
                spacing={2}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ px: 0, pt: 2, }}
                className="pt-4 mt-2 mb-2"
              >
                <div className="text-left w-100 pt-4">
                  <p className="mb-0">
                    <span className="text-dark fw-500 fs-16">Enter Transaction Pin</span>
                  </p>
                  <p className="mb-0" style={{ fontWeight: '300', color: 'rgba(75, 85, 99, 1)' }}>
                    <small className="fs-13 fw-500">Enter your 4-digit pin to confirm your transfer</small>
                  </p>
                </div>
                <IconButton className="d-block d-sm-none" onClick={handleClosetrsnPinDialog}>
                  <Iconify icon="eva:close-fill" />
                </IconButton>
              </Stack>
            </div>

            <div
              className={`modal_content w-100 px-2 pl-md-0 pr-md-0 py-2 mt-2`}
            // style={{overflowY: 'scroll', height: '450px'}}
            >

              <div
                className="mt-2 pb-5 modal_content wallet_modal_details"
                style={{ maxHeight: "70vh", overflowY: "scroll" }}
              >
                <div className={`${styles.input_box} input_box`}>
                  <div className="">
                  <label htmlFor="trsn_pin" className="fs-13 mb-0">Enter Pin</label>
                  <FormControl sx={{ width: '100%' }} variant="outlined">
                  <OutlinedInput
                    id="outlined-adornment-PIN"
                    type={showPIN ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end" className="ml-0 px-0 py-0">
                        <IconButton
                          aria-label="toggle PIN visibility"
                          onClick={handleClickShowPIN}
                          onMouseDown={handleMouseDownPIN}
                          edge="end"
                           className="px-3 py-3"
                        >
                          {showPIN ? <VisibilityOff style={{fontSize: '30px'}}/> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    value={PIN}
                    onChange={handlePINChange}
                  />
                </FormControl>
                    <TextField
                      name="trsn_pin"
                      required
                      type="text"
                      id="trsn_pin"
                      value={PIN}
                      onChange={handlePINChange}
                      inputProps={{
                        maxLength: 4,
                        style: { textAlign: 'left' }
                      }}
                      size="small"
                      placeholder=""
                      fullWidth
                    />
                  </div>
                </div>
                <div className="w-100 mt-3">
                  <button
                    type="button"
                    className="btn border btn-primary d-block px-3"
                    onClick={handleSendTransfer}
                    disabled={otp === '' || isLoadingTransfer}
                  >
                    <span className="text-white">
                      Proceed
                      {isLoadingTransfer && <CircularProgress className='ml-1 mt-1' style={{ color: '#fff' }} size="0.8rem" />}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Drawer> */}

        <GlobalModal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={paymentSuccessfulDialog}
          onClose={handleClosePaymentSuccessfulDialog}
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          width="500px !important"
          maxWidth="100%"
          overflowY="scroll"
          bgcolor="#FFFF"
          border="1px solid #F5F5F5"
          borderRadius="5px"
          boxShadow="24"
          p="25px"
        >
          <div
            className={`modal_content`}
            style={{ overflowY: 'scroll', height: 'auto' }}
          >
            <div className="d-flex flex-column justify-content-center align-items-center w-100">
              <svg
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="48" height="48" rx="24" fill="#D1FAE5" />
                <path
                  d="M17 25L21 29L31 19"
                  stroke="#059669"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <div className="text-center pt-4">
                <h6>
                  <span
                    className="text-dark modals_head_title"
                    style={{ fontWeight: '500' }}
                  >
                    Payment Successful
                  </span>
                </h6>
                <p style={{ fontWeight: '300', color: 'rgba(75, 85, 99, 1)' }}>
                  <small>Your transfer is confirmed and on its way</small>
                </p>
              </div>

              <div
                className="mt-2 modal_content w-100"
                style={{ overflowY: 'scroll' }}
              >
                <div className="d-flex" style={{ gap: '10px' }}>
                  <button
                    type="button"
                    onClick={handleToggleLedgerDialog}
                    className="d-block w-100 btn py-2 border"
                  >
                    <span>Cancel</span>
                  </button>
                  <button
                    onClick={handleToggleLedgerDialog}
                    className="d-block w-100 btn py-2 btn-primary"
                  >
                    <span className="text-white font-weight-normal">
                      Save Beneficiary
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </GlobalModal>

        <GlobalModal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={ledgerDialog}
          onClose={handleCloseLedgerDialog}
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          width="500px !important"
          maxWidth="100%"
          overflowY="scroll"
          bgcolor="#FFFF"
          border="1px solid #F5F5F5"
          borderRadius="5px"
          boxShadow="24"
          p="25px"
        >
          <span
            className="px-3 py-2"
            style={{
              fontSize: '25px',
              cursor: 'pointer',
              position: 'absolute',
              top: '0px',
              right: '0px',
            }}
            onClick={goToTransfer}
          >
            &times;
          </span>
          <div
            className={`modal_content`}
            style={{ overflowY: 'scroll', height: 'auto' }}
          >
            <div className="d-flex flex-column justify-content-center align-items-center w-100">
              {/* <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="48" height="48" rx="24" fill="#D1FAE5" />
        <path d="M17 25L21 29L31 19" stroke="#059669" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg> */}

              <div className="text-center pt-4">
                <h6>
                  <span className="text-dark modals_head_title fw-500">
                    Ledger Update
                  </span>
                </h6>
                <p style={{ fontWeight: '300', color: 'rgba(75, 85, 99, 1)' }}>
                  <small>Your transfer will be updated to the ledger as;</small>
                </p>
              </div>

              <div className="text-left pt-2 w-100 pl-sm-4 mb-2">
                <h6 className=" mb-3">
                  <span className="text-dark modals_head_title fs-14 fw-500">
                    Ledger Name : NGN Ledger
                  </span>
                </h6>
                <h6 className=" mb-3">
                  <span className="text-dark modals_head_title fs-14 fw-500">
                    Credit Account : Wallet Account (NGN 200)
                  </span>
                </h6>
                <h6 className=" mb-3">
                  <span className="text-dark modals_head_title fs-14 fw-500">
                    Debit Account:{' '}
                    {!editLedger && <>Salaries Account (NGN 200)</>}
                  </span>
                </h6>
              </div>

              {editLedger && (
                <>
                  <div
                    className="d-sm-flex align-items-center w-100 mb-3 px-sm-3"
                    style={{ gap: '6px' }}
                  >
                    <div
                      className="position-relative mb-3 mb-sm-0"
                      style={{ minWidth: '200px' }}
                    >
                      <span className=" all_selectdropdown_icon position-absolute bg-white px-2">
                        <svg
                          width="20"
                          height="21"
                          viewBox="0 0 20 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M5.29289 7.79289C5.68342 7.40237 6.31658 7.40237 6.7071 7.79289L9.99999 11.0858L13.2929 7.79289C13.6834 7.40237 14.3166 7.40237 14.7071 7.79289C15.0976 8.18342 15.0976 8.81658 14.7071 9.20711L10.7071 13.2071C10.3166 13.5976 9.68341 13.5976 9.29289 13.2071L5.29289 9.20711C4.90237 8.81658 4.90237 8.18342 5.29289 7.79289Z"
                            fill="#6B7280"
                          />
                        </svg>
                      </span>
                      <Select
                        labelId="s"
                        id="s"
                        fullWidth
                        size="small"
                        required
                        name="verified"
                        defaultValue="salaries"
                      >
                        <MenuItem value="salaries">Salaries</MenuItem>
                        <MenuItem value="monthly-stipends">
                          Monthly Stipends
                        </MenuItem>
                      </Select>
                    </div>

                    <div className="position-relative box_with_ngnsign mb-3 mb-sm-0">
                      <span
                        className="position-absolute"
                        style={{
                          zIndex: '10',
                          top: '50%',
                          left: '11px',
                          transform: 'translateY(-50%)',
                        }}
                      >
                        NGN
                      </span>
                      <TextField
                        name="min_products"
                        required
                        type="number"
                        fullWidth
                        id="ounit"
                        placeholder=""
                        size="small"
                      />
                    </div>
                    <div className="fit-content ml-auto">
                      <button className="btn d-flex align-items-center rounded-0 ">
                        <svg
                          width="17"
                          height="17"
                          viewBox="0 0 17 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.25 8.5H12.75"
                            stroke="#111827"
                            strokeWidth="1.7"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M8.5 12.75V4.25"
                            stroke="#111827"
                            strokeWidth="1.7"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>

                        <span className="ml-2">Add</span>
                      </button>
                    </div>
                  </div>
                </>
              )}

              <div
                className="mt-2 modal_content w-100"
                style={{ overflowY: 'scroll' }}
              >
                <div className="d-flex" style={{ gap: '10px' }}>
                  <button
                    type="button"
                    onClick={handleToggleEditLedger}
                    className="d-block w-100 btn py-2 border"
                  >
                    {editLedger ? (
                      <>
                        <span>Cancel</span>
                      </>
                    ) : (
                      <>
                        <span>Edit</span>
                      </>
                    )}
                  </button>
                  <Link
                    to="/app/payment/transfers"
                    component={RouterLink}
                    onClick={() => setLedgerDialog(false)}
                    className="d-block w-100 btn py-2 btn-primary"
                  >
                    <span className="text-white font-weight-normal">
                      Post Entries
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </GlobalModal>

        <Drawer
          anchor="right"
          open={existingBen}
          onClose={handleCloseExistingBen}
          PaperProps={{
            sx: {
              width: 350,
              maxWidth: '100%',
              border: 'none',
              overflow: '',
              marginTop: '0em',
              height: '100vh',
            },
          }}
        >
          <div className="w-100 px-2 px-md-4">
            <Stack
              spacing={2}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ px: 1, pt: 2, background: 'rgba(249, 250, 251, 1)' }}
              className="pt-4 mt-2"
            >
              <Typography
                variant="subtitle1"
                sx={{ ml: 1 }}
                className="fw-500 text-dark"
              >
                Beneficiaries
              </Typography>
              <IconButton
                className="d-inline d-sm-none"
                onClick={() => setExistingBen(false)}
              >
                <Iconify icon="eva:close-fill" />
              </IconButton>
            </Stack>
            <div
              className={`modal_content w-100 px-2 pl-md-2 pr-md-3 py-2`}
              // style={{overflowY: 'scroll', height: '450px'}}
            >
              <div className={`modal_header w-100 mb-0`}>
                <div className={`${styles.hold_input} w-100`}>
                  <img
                    src="/assets/icons/search.svg"
                    alt=""
                    style={{ transform: 'translateY(4px)' }}
                  />
                  <input
                    type="text"
                    name="search"
                    placeholder="Search"
                    style={{ paddingLeft: '33px' }}
                    value={searchQuery}
                    onChange={handleSearch}
                    className=" form-control border rounded-0 w-100 fs-13"
                    // disabled={beneficiaryData.length < 1}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* <Divider /> */}

          <Scrollbar>
            <Stack spacing={2} sx={{ px: 2 }}>
              <div
                className={`modal_content px-md-3`}
                // style={{overflowY: 'scroll', height: '450px'}}
              >
                <div
                  className="pb-4 modal_content"
                  style={{ overflowY: 'scroll' }}
                >
                  {!isCreating &&
                    beneficiaryData.length > 0 &&
                    beneficiaryData.map((el, i) => {
                      return (
                        <div
                          className="py-3"
                          onClick={() => handleSetBeneficiary(el)}
                          style={{
                            cursor: 'pointer',
                          }}
                        >
                          <div>
                            <div className=" ">
                              <p className="mb-0" style={{ fontSize: '13px' }}>
                                <strong
                                  style={{ fontWeight: '500', color: '#000' }}
                                >
                                  {el?.account_name}
                                </strong>
                              </p>
                              <span
                                className="text-muted"
                                style={{ fontSize: '11px' }}
                              >
                                {el?.account_number} ( {el?.bank_name} )
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  {isCreating && (
                    <>
                      <div className="d-flex justify-content-center mt-5">
                        <CircularProgress
                          style={{ color: '#fff' }}
                          className="text-white ml-1"
                          size="1.3rem"
                        />
                      </div>
                      <br />
                      <span className="d-flex justify-content-center">
                        Retrieving Beneficiaries..
                      </span>
                    </>
                  )}
                  {!isCreating && !beneficiaryData.length && (
                    <div className="alerts alerts-primary text-center mt-4 mb-4">
                      <p>
                        <i style={{ fontSize: '13px' }}>
                          No Beneficiary Record!
                        </i>
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </Stack>
            {/* <Box sx={{ p: 3 }}>
            <Button
              fullWidth
              size="large"
              type="submit"
              color="inherit"
              variant="outlined"
              startIcon={<Iconify icon="ic:round-clear-all" />}
            >
              Clear All
            </Button>
          </Box> */}
          </Scrollbar>
        </Drawer>

        <Drawer
          anchor="right"
          open={schedulePayment}
          onClose={handleCloseSchedulePayment}
          PaperProps={{
            sx: {
              width: 300,
              maxWidth: '100%',
              border: 'none',
              overflow: '',
              marginTop: '0em',
              height: '100vh',
              background: 'rgba(249, 250, 251, 1)',
            },
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ px: 1, pt: 3, pb: 1, background: 'rgba(249, 250, 251, 1)' }}
            className=""
          >
            <Typography
              variant="subtitle1"
              sx={{ ml: 1 }}
              className="fw-500 text-dark"
            >
              Schedule Payment
              <p className="mb-0">
                <small
                  style={{
                    fontWeight: '400',
                    color: 'rgba(75, 85, 99, 1)',
                    fontSize: '12px',
                  }}
                >
                  You are now scheduling a payment
                </small>
              </p>
            </Typography>
            <IconButton
              className="d-inline d-sm-none"
              onClick={() => setSchedulePayment(false)}
            >
              <Iconify icon="eva:close-fill" />
            </IconButton>
          </Stack>

          <Divider />
          <Scrollbar>
            <Stack spacing={2} sx={{ p: 2 }}>
              <div
                className={`modal_content`}
                // style={{overflowY: 'scroll', height: '450px'}}
              >
                {/* <div className="w-100 mb-3">
                  <label htmlFor="ounit">Payment Date</label>
                  <div
                    className="w-100 payment_date"
                    style={{ position: "relative" }}
                  >
                    <span
                      style={{
                        position: "absolute",
                        left: "10px",
                        top: "5px",
                        zIndex: "10",
                      }}
                    >
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.66602 1.6665V4.1665" stroke="#9CA3AF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M13.334 1.6665V4.1665" stroke="#9CA3AF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M2.91602 7.5752H17.0827" stroke="#9CA3AF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M17.5 7.08317V14.1665C17.5 16.6665 16.25 18.3332 13.3333 18.3332H6.66667C3.75 18.3332 2.5 16.6665 2.5 14.1665V7.08317C2.5 4.58317 3.75 2.9165 6.66667 2.9165H13.3333C16.25 2.9165 17.5 4.58317 17.5 7.08317Z" stroke="#9CA3AF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M13.0781 11.4167H13.0856" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M13.0781 13.9167H13.0856" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M9.99607 11.4167H10.0036" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M9.99607 13.9167H10.0036" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M6.91209 11.4167H6.91957" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M6.91209 13.9167H6.91957" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>

                    </span>
                    <DatePicker
                      selected={paymentDate}
                      onChange={(date) => setPaymentDate(date)}
                      dateFormat="do MMMM yyyy"
                      className="form-control border rounded-0 w-100"
                      placeholderText="Select Payment Date"
                      required
                      fullWidth
                      minDate={new Date()}
                    // renderCustomHeader={({ date }) => (
                    //   <div className="">{paymentDate ? formatDateWithSuffix(paymentDate) : 'Select Payment Date'}</div>
                    // )}
                    />
                  </div>
                </div> */}

                <div className={`wallet_modal_header m-0 w-100 mb-0`}>
                  <label htmlFor="paymentTime">Payment Time</label>

                  <div className=" d-flex">
                    <div className="" style={{ width: '100%' }}>
                      <div
                        className="mb-2 w-100 custom_short_datepkr bg-white"
                        style={{ position: 'relative' }}
                      >
                        <span
                          className="bg-white"
                          style={{
                            position: 'absolute',
                            left: '9px',
                            top: '5px',
                            zIndex: '20',
                            // transform: 'scale(0.85)'
                          }}
                        >
                          <svg
                            width="20"
                            height="21"
                            viewBox="0 0 20 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.66699 2.1665V4.6665"
                              stroke="#9CA3AF"
                              strokeWidth="1.5"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M13.333 2.1665V4.6665"
                              stroke="#9CA3AF"
                              strokeWidth="1.5"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M2.91699 8.0752H17.0837"
                              stroke="#9CA3AF"
                              strokeWidth="1.5"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M17.5 7.58317V14.6665C17.5 17.1665 16.25 18.8332 13.3333 18.8332H6.66667C3.75 18.8332 2.5 17.1665 2.5 14.6665V7.58317C2.5 5.08317 3.75 3.4165 6.66667 3.4165H13.3333C16.25 3.4165 17.5 5.08317 17.5 7.58317Z"
                              stroke="#9CA3AF"
                              strokeWidth="1.5"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M13.0791 11.9167H13.0866"
                              stroke="#9CA3AF"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M13.0791 14.4167H13.0866"
                              stroke="#9CA3AF"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M9.99607 11.9167H10.0036"
                              stroke="#9CA3AF"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M9.99607 14.4167H10.0036"
                              stroke="#9CA3AF"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M6.91209 11.9167H6.91957"
                              stroke="#9CA3AF"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M6.91209 14.4167H6.91957"
                              stroke="#9CA3AF"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>

                        <input
                          aria-label="Time"
                          onChange={handleTimeChange}
                          value={paymentTime}
                          className="form-control border"
                          type="time"
                          defaultValue={defaultTime}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="scheduledOption">Frequency</label>
                    <div className="" style={{ width: '100%' }}>
                      <div className="position-relative bg-white">
                        <span className=" all_selectdropdown_icon position-absolute bg-white px-2">
                          <svg
                            width="20"
                            height="21"
                            viewBox="0 0 20 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M5.29289 7.79289C5.68342 7.40237 6.31658 7.40237 6.7071 7.79289L9.99999 11.0858L13.2929 7.79289C13.6834 7.40237 14.3166 7.40237 14.7071 7.79289C15.0976 8.18342 15.0976 8.81658 14.7071 9.20711L10.7071 13.2071C10.3166 13.5976 9.68341 13.5976 9.29289 13.2071L5.29289 9.20711C4.90237 8.81658 4.90237 8.18342 5.29289 7.79289Z"
                              fill="#6B7280"
                            />
                          </svg>
                        </span>
                        <Select
                          labelId="scheduledOption"
                          id="scheduledOption"
                          fullWidth
                          size="small"
                          required
                          name="scheduledOption"
                          // defaultValue="weekly"
                          value={scheduledOption}
                          onChange={handleScheduleChange}
                          className=""
                        >
                          <MenuItem value="DAILY">Daily</MenuItem>
                          <MenuItem value="WEEKLY">Weekly</MenuItem>
                          <MenuItem value="MONTHLY">Monthly</MenuItem>
                          <MenuItem value="CUSTOM">Custom</MenuItem>
                        </Select>
                      </div>
                    </div>
                  </div>
                  {scheduledOption === 'CUSTOM' && (
                    <>
                      <div className="mb-3">
                        <label htmlFor="freq">Interval</label>
                        <div className="" style={{ width: '100%' }}>
                          <div className="position-relative bg-white">
                            <span className=" all_selectdropdown_icon position-absolute bg-white px-2">
                              <svg
                                width="20"
                                height="21"
                                viewBox="0 0 20 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M5.29289 7.79289C5.68342 7.40237 6.31658 7.40237 6.7071 7.79289L9.99999 11.0858L13.2929 7.79289C13.6834 7.40237 14.3166 7.40237 14.7071 7.79289C15.0976 8.18342 15.0976 8.81658 14.7071 9.20711L10.7071 13.2071C10.3166 13.5976 9.68341 13.5976 9.29289 13.2071L5.29289 9.20711C4.90237 8.81658 4.90237 8.18342 5.29289 7.79289Z"
                                  fill="#6B7280"
                                />
                              </svg>
                            </span>
                            <Select
                              labelId="freq"
                              id="freq"
                              fullWidth
                              size="small"
                              required
                              name="interval"
                              // defaultValue="every_2_weeks"
                              className=""
                              onChange={handleInterval}
                              value={interval}
                            >
                              <MenuItem value="EVERY_5_DAYS">
                                Every 5 Days
                              </MenuItem>
                              <MenuItem value="EVERY_2_WEEKS">
                                Every 2 Weeks
                              </MenuItem>
                              <MenuItem value="EVERY_2_MONTHS">
                                Every 2 Months
                              </MenuItem>
                              <MenuItem value="BI_ANNUALLY">
                                Bi-Annually
                              </MenuItem>
                              <MenuItem value="QUARTERLY">Quarterly</MenuItem>
                            </Select>
                          </div>
                        </div>
                      </div>

                      <div className="mb-3">
                        <label className="">Number of Occurence</label>
                        <div className="" style={{ width: '100%' }}>
                          <div className="">
                            <TextField
                              name="noOfTimes"
                              // multiline
                              // rows={4}
                              required
                              type="number"
                              id="noOfTimes"
                              size="small"
                              placeholder=""
                              onChange={handleNoOfTimesChange}
                              fullWidth
                              value={noOfTimes}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  <label htmlFor="ounit">Next payment is on</label>

                  <div className=" d-flex">
                    <div className="" style={{ width: '100%' }}>
                      <div
                        className="mb-3 w-100 custom_short_datepkr"
                        style={{ position: 'relative' }}
                      >
                        <div className="p-2 border rounded-0 ">
                          <p className="mb-0">
                            {format(paymentDate, 'do MMMM yyyy - h:mmaaa')}
                          </p>
                        </div>

                        {/* <DatePicker
                              // showIcon
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                              dateFormat="dd/MM/yyyy"
                              className="form-control"
                              placeholderText="Start date"
                              required
                          /> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="mt-2 modal_content"
                  style={{ maxHeight: '70vh', overflowY: 'scroll' }}
                >
                  <div
                    className="d-flex flex-wrap mb-5"
                    style={{ gap: '10px' }}
                  >
                    <button
                      type="button"
                      onClick={verifyScheduleTrue}
                      className="btn btn-primary"
                    >
                      <span className="text-white font-weight-normal">
                        Done
                      </span>
                    </button>
                    <button
                      type="button"
                      onClick={() => setSchedulePayment(false)}
                      className="btn border bg-white"
                    >
                      <span>Cancel</span>
                    </button>
                  </div>
                </div>
              </div>
            </Stack>
            {/* <Box sx={{ p: 3 }}>
            <Button
              fullWidth
              size="large"
              type="submit"
              color="inherit"
              variant="outlined"
              startIcon={<Iconify icon="ic:round-clear-all" />}
            >
              Clear All
            </Button>
          </Box> */}
          </Scrollbar>
        </Drawer>
      </div>
    </>
  );
}
