import axiosInstance from '../axiosInstance'; 

export const fetchLedgerAccounts = async () => {
    try {
        const response = await axiosInstance.get('/ledger/EXPENSES?productName=onepoint');
        
        if (response.data.status === "success") {
            return response.data.data; 
        } else {
            throw new Error(response.data.message);
        }
    } catch (error) {
        console.error("Error fetching ledger accounts:", error);
        throw error; 
    }
};
