import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Link as RouterLink, useParams, useLocation } from "react-router-dom";
import styles from "./LedgerTable.module.css";
import { Link } from "@mui/material";
import axiosInstance from "src/axiosInstance";
import Scrollbar from "../../components/scrollbar";
import GlobalPagination from "../../pagination/GlobalPagination";
import Pageloader from "src/pageloader";

export default function SalesDetails() {
  const { id: ledger_account_id } = useParams();
  const location = useLocation();
  const accountData = location.state?.accountData;

  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 8;

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await axiosInstance.get(
          `/ledger/account-transactions/${ledger_account_id}?productName=onepoint`
        );

        if (response.data.status === "success" && response.data.data) {
          const accountData = response.data.data[0];
          if (accountData?.transactions) {
            setTransactions(
              accountData.transactions.map((transaction) => ({
                ...transaction,
                type: accountData.type,
              }))
            );
          }
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching transactions:", error);
        setLoading(false);
      }
    };

    if (ledger_account_id) {
      fetchTransactions();
    }
  }, [ledger_account_id]);

  return (
    <>
      <Helmet>
        <title>{accountData?.name || "Account Details"} | OnePoint</title>
      </Helmet>

      <div className="px-0 px-md-2">
        {/* Breadcrumb */}
        <div className="d-flex justify-content-between align-items-center">
          <div className="">
            <h5 className="page_title mb-2">Ledgers</h5>
            <p
              className="mt-2 d-flex align-items-center fw-500 text-dark"
              style={{ fontSize: "13px", gap: "8px" }}
            >
              <Link to="/app/ledger-management" component={RouterLink}>
                Ledger
              </Link>
              <span>›</span>
              <Link
                to="/app/ledger-management/ledger-details"
                component={RouterLink}
              >
                NGN Ledger
              </Link>
              <span>›</span>
              <span className="text-dark">{accountData?.name}</span>
            </p>
          </div>
        </div>

        {/* Balance Card */}
        <div
          className="card px-3 py-3 border-0 position-relative mt-4"
          style={{
            background: "rgba(243, 244, 246, 1)",
            borderRadius: "6px",
            width: "20%",
          }}
        >
          <h6 className="text-dark mb-2">{accountData?.name}</h6>
          <h3 className="fw-bold">
            ₦{(accountData?.total_amount || 0).toLocaleString()}
          </h3>
        </div>

        {/* Transactions Table */}
        <div className="mt-4">
      <div className="card border-0 table-responsive">

          <Scrollbar>
            <div className={`${styles.table} table-responsive`}>
            <table
            className={`${styles.table} table-hover table-striped`}
            style={{ minWidth: "20em" }}
          >
                <thead>
                  <tr>
                    <th className="fw-500 fs-12">
                      Transaction ID
                      <svg
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.00004 3.8335V13.1668M8.00004 13.1668L12.6667 8.50016M8.00004 13.1668L3.33337 8.50016"
                          stroke="#667085"
                          strokeWidth="1.33333"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </th>
                    <th className="fw-500 fs-12">
                      Type
                      <svg
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.00004 3.8335V13.1668M8.00004 13.1668L12.6667 8.50016M8.00004 13.1668L3.33337 8.50016"
                          stroke="#667085"
                          strokeWidth="1.33333"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </th>
                    <th className="fw-500 fs-12">
                      Amount
                      <svg
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.00004 3.8335V13.1668M8.00004 13.1668L12.6667 8.50016M8.00004 13.1668L3.33337 8.50016"
                          stroke="#667085"
                          strokeWidth="1.33333"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </th>
                    <th className="fw-500 fs-12">
                      Wallet
                      <svg
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.00004 3.8335V13.1668M8.00004 13.1668L12.6667 8.50016M8.00004 13.1668L3.33337 8.50016"
                          stroke="#667085"
                          strokeWidth="1.33333"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </th>
                    <th className="fw-500 fs-12">
                      Date Posted
                      <svg
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.00004 3.8335V13.1668M8.00004 13.1668L12.6667 8.50016M8.00004 13.1668L3.33337 8.50016"
                          stroke="#667085"
                          strokeWidth="1.33333"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </th>
                  </tr>
                </thead>
                <tbody style={{border: "none"}}>
                  {loading ? (
                    <tr>
                      <td
                        colSpan="5"
                        style={{
                          position: "relative",
                          height: "150px",
                          backgroundColor: "#fff",
                        }}
                      >
                        <Pageloader loading={loading} />
                      </td>
                    </tr>
                  ) : transactions.length > 0 ? (
                    transactions.map((item) => (
                      <tr key={item.transaction_ref}>
                        <td>{item.transaction_ref}</td>
                        <td>{item.type}</td>
                        <td>₦{Number(item.amount).toLocaleString()}</td>
                        <td>
                          <div className="d-flex align-items-center gap-2">
                            <span>🇳🇬</span>
                            <span>{item.wallet_name}</span>
                          </div>
                        </td>
                        <td>{new Date(item.createdAt).toLocaleString()}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5" className="text-center">
                        No transactions found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </Scrollbar>
        </div>
        </div>
      </div>
      
    </>
  );
}


