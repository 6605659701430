// ----------------------------------------------------------------------
import styles from './PaymentTables.module.css';
import { useEffect, useState } from 'react';
import React from 'react';
import TextField from '@mui/material/TextField';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import bus from 'src/bus';

import { Link as RouterLink } from 'react-router-dom';

import {
  Link,
  Stack,
  Divider,
  Drawer,
  Typography,
  IconButton,
  //   Avatar,
} from '@mui/material';
// import Scrollbar from "../../components/scrollbar";
// sections
// mock
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Scrollbar from '../../components/scrollbar';
import { Helmet } from 'react-helmet-async';
import Pagination from '../../pagination/GlobalPagination';
import Iconify from '../../components/iconify';
import axiosInstance from 'src/axiosInstance';
import { notifySuccess, notifyError } from 'src/hooks/useMockData';
import CircularProgress from '@mui/material/CircularProgress';
import {
  DateTimeFormat,
  formatDateToDMY,
  formatDateToFullYear,
  fullNameDateFormat,
} from 'src/hooks/useDateFormat';
import { formatDate } from 'date-fns';
import Preloader from 'src/preloader';
import Pageloader from 'src/pageloader';

// ----------------------------------------------------------------------

export default function PaymentTransfers() {
  const baseWebURL = window.location.origin;

  // eslint-disable-next-line no-unused-vars
  const apiUrl = process.env.REACT_APP_NAME;
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const [isDataAvl, setIsDataAvl] = useState(false);
  const [isSearchDataAvl, setIsSearchDataAvl] = useState(false);
  const [isLoadingPLData, setIsLoadingPLData] = useState(false);
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [paymentLinkDetails, setPaymentLinkDetails] = useState(false);
  const [paymentLinkDetailsData, setPaymentLinkDetailsData] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [idtooltipVisible, setIdTooltipVisible] = useState(false);
  const [reloadData, setReloadData] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [isVisitVerified, setIsVisitVerified] = useState(false);
  const [hasLength, setHasLength] = useState(false);
  const [isTimeoutCompleted, setisTimeoutCompleted] = useState(false);
  const [PLinksData, setPLinksData] = useState([]);
  const [PLinksPagination, setPLinksPagination] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const [isSorted, setIsSorted] = useState(false);
  const [isPreviewing, setisPreviewing] = useState(false);
  const [IsKYCVerified, setIsKYCVerified] = useState(false);
  let KYC_Status = JSON.parse(localStorage.getItem('kyc_status'));
  let userDetails = JSON.parse(localStorage.getItem('userDetails'));

  // let plink_visited = JSON.parse(localStorage.getItem('plink_visited')) || false;
  const [sortConfig, setSortConfig] = useState({
    key: '',
    direction: 'ascending',
  });
  const [filter, setFilter] = useState({
    currency: '',
    amount: '',
    search: '',
  });
  const handleClearFilter = () => {
    setFilter({
      status: '',
      amount: '',
      currency: '',
    });
    setStartDate(null);
    setEndDate(null);
    setSearchQuery('');
    setToggleDropdown(false);
    setReloadData(true);
  };

  useEffect(() => {
    setIsLoadingPage(true);
    if (hasLength && !PLinksData.length) {
      setHasLength(true);
    }
  }, []);

  const fetchPLinksData = async (page = currentPage, perPage = pageSize) => {
    setIsPending(true);

    const filter_payload = {};
    if (filter.currency !== '' && filter.currency !== 'all') {
      filter_payload.currency = filter.currency;
    }
    if (filter.amount !== '') {
      filter_payload.amount = filter.amount;
    }
    if (filter.search !== '') {
      filter_payload.search = filter.search;
    }
    if (startDate !== null) {
      filter_payload.from = formatDateToFullYear(startDate);
    }
    if (endDate !== null) {
      filter_payload.to = formatDateToFullYear(endDate);
    }

    filter_payload.page = page;
    filter_payload.perPage = perPage;

    try {
      const response = await axiosInstance.get(
        `/payments/payment-links?productName=${apiUrl}`,
        {
          params: {
            ...filter_payload,
          },
        }
      );
      const data = response.data.data;

      setPLinksData(data?.data);
      setPLinksPagination(data);
      setToggleDropdown(false);

      if (data.data.length > 0) {
        setIsVisitVerified(true);
        setHasLength(true);
        setIsDataAvl(true);
      } else {
        if (
          filter.search !== '' ||
          filter.currency !== '' ||
          filter.amount !== '' ||
          startDate ||
          endDate
        ) {
          setIsVisitVerified(true);
        } else {
          setIsVisitVerified(false);
        }
        setIsDataAvl(false);
      }
      setIsLoadingPage(false);
    } catch (err) {
      if (err.response) {
        if (
          err.response.data &&
          err.response.data.data &&
          err.response.data.data.errorMessage
        ) {
          notifyError(err.response.data.data.errorMessage);
        } else if (err.response.data && err.response.data.message) {
          notifyError(err.response.data.message);
        } else {
          notifyError('An unexpected error occurred');
        }
      } else {
        notifyError('Network error or server not responding');
      }
      // notifyError(err.response.data.data.errorMessage || 'Something went wrong')
    } finally {
      setIsPending(false);
    }
  };

  const handleOpenPreviewPL = (url) => {
    // Check if 'id' is defined
    if (!url) {
      console.error('Preview ID is not defined');
      return;
    }

    setisPreviewing(true);
    // console.log('url: ', url);
    setTimeout(() => {
      setisPreviewing(false);
      window.open(`/payment-links/preview/${url}`, '_blank');
    }, 1000);
  };

  useEffect(() => {
    if (
      (KYC_Status !== null && KYC_Status) ||
      (userDetails !== null &&
        userDetails.account !== null &&
        userDetails.account.kyc_status)
    ) {
      setIsKYCVerified(true);
    } else {
      setIsKYCVerified(false);
    }

    setisTimeoutCompleted(false);
    setTimeout(() => {
      if (hasLength) {
        setIsVisitVerified(true);
      } else {
        setIsVisitVerified(false);
      }
      setisTimeoutCompleted(true);
    }, 200);
  }, []);

  useEffect(() => {
    fetchPLinksData();
  }, [filter.search, pageSize, currentPage, reloadData]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const copyToClipboard = (text, type) => {
    navigator.clipboard.writeText(text).then(
      () => {
        if (type === 'link' && (paymentLinkDetailsData || PLinksData)) {
          notifySuccess('Payment Link Copied');
          // setTooltipVisible(true);
          // setTimeout(() => {
          //     setTooltipVisible(false);
          // }, 2000);
        } else if (type === 'id' && (paymentLinkDetailsData || PLinksData)) {
          notifySuccess('Payment ID Copied');
          // setIdTooltipVisible(true);
          // setTimeout(() => {
          //     setIdTooltipVisible(false);
          // }, 2000);
        } else {
          notifyError('No data to copy');
        }
      },
      (err) => {
        console.error('Failed to copy: ', err);
      }
    );
  };

  const handleToggleIsDataAvl = () => {
    setIsDataAvl(!isDataAvl);
  };

  const handleOpenPaymentLinkDetails = async (item) => {
    setPaymentLinkDetails(true);
    setPaymentLinkDetailsData(null);
    // setIsLoadingPLData(true);
    setSelectedImage(null);

    setPaymentLinkDetailsData(item);
    setSelectedImage(item.image);

    // try {
    //     const response = await axiosInstance.get(`/payments/payment-links/${custom_url_path}?productName=${apiUrl}`);
    //     const data = response.data.data;

    //     setPaymentLinkDetailsData(data);
    //     setSelectedImage(data.image);
    //     // notifySuccess('Payment link details fetched successfully');
    // } catch (err) {
    //     if (err.response) {
    //         if (err.response.data && err.response.data.data && err.response.data.data.errorMessage) {
    //             notifyError(err.response.data.data.errorMessage);
    //         } else if (err.response.data && err.response.data.message) {
    //             notifyError(err.response.data.message);
    //         } else {
    //             notifyError('An unexpected error occurred');
    //         }
    //     } else {
    //         notifyError('Network error or server not responding');
    //     }
    // } finally {
    //     setIsLoadingPLData(false);
    // }
  };

  const handleClosePaymentLinkDetails = () => {
    setPaymentLinkDetails(false);
  };

  const handleToggleDropdown = () => {
    const newToggleDropdown = !toggleDropdown; // Toggle the current status
    setToggleDropdown(newToggleDropdown); // Update the state

    // Emit the updated toggleDropdown status
    // bus.emit('toggleOverlay', newToggleDropdown);
  };

  const sortTableData = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }

    if (direction === 'ascending') {
      setIsSorted(true);
      // console.log("isSorted:", isSorted);
    } else if (direction === 'descending') {
      setIsSorted(false);
      // console.log("isSorted:", isSorted);
    }

    const sortedData = [...PLinksData].sort((a, b) => {
      if (typeof a[key] === 'number' && typeof b[key] === 'number') {
        // return direction === 'ascending' ? a[key] - b[key] : b[key] - a[key];
        if (direction === 'ascending') {
          // console.log("enters ascending");
          return direction === 'ascending' ? -1 : 1;
        } else if (direction === 'descending') {
          // console.log("enters descending");
          return direction === 'ascending' ? 1 : -1;
        }
      } else {
        if (a[key] < b[key]) {
          return direction === 'ascending' ? -1 : 1;
        }
        if (a[key] > b[key]) {
          return direction === 'ascending' ? 1 : -1;
        }
        return 0;
      }
    });
    setPLinksData(sortedData);
    setSortConfig({ key, direction });
    // console.log("sortConfig:", sortConfig);
  };

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <Helmet>
        <title>Payment Links</title>
      </Helmet>
      <>
        {IsKYCVerified ? (
          <>
            {isLoadingPage ? (
              <Preloader />
            ) : (
              <>
                {hasLength && !isLoadingPage && (
                  <div className="px-0 px-md-2">
                    <div
                      onClick={() => handleToggleDropdown()}
                      className={`overlay_div ${
                        toggleDropdown ? 'show_overlay' : ''
                      }`}
                    ></div>

                    <div className="d-flex justify-content-between align-items-center">
                      <div className="">
                        <h5 className="page_title mb-2">Payment Links</h5>
                        <p style={{ color: '#4B5563', fontSize: '13px' }}>
                          <span>Share a link to a checkout page</span>
                        </p>
                      </div>
                    </div>

                    <div className="d-md-flex justify-content-between align-items-center mt-4 w-100">
                      <div
                        className="d-flex justify-content-between align-items-center mb-3 mb-md-0 w-100"
                        style={{ gap: '10px', width: 'fit-content' }}
                      >
                        <div className={`${styles.hold_input} w-100`}>
                          <img src="/assets/icons/search.svg" alt="" />
                          <input
                            type="text"
                            name="search"
                            placeholder="Search"
                            style={{
                              paddingLeft: '33px',
                              width: '24em',
                              maxWidth: '100%',
                              borderRadius: '0',
                              fontSize: '13px',
                              minHeight: '40px',
                            }}
                            className=" form-control rounded-0"
                            value={filter.search}
                            onChange={handleFilterChange}
                          />
                        </div>
                        {/* <div className="" onClick={() => handleToggleIsDataAvl()}>
                                            <span className="cursor-pointer border rounded-pill py-1 pl-2 pr-1 d-flex align-items-center">
                                                <small style={{ fontSize: '10px' }}>Transaction ID</small>
                                                <svg className="ml-2" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M9.33786 4.06641C6.37786 4.06641 3.98523 6.45903 3.98523 9.41904C3.98523 12.379 6.37786 14.7717 9.33786 14.7717C12.2979 14.7717 14.6905 12.379 14.6905 9.41904C14.6905 6.45903 12.2979 4.06641 9.33786 4.06641ZM12.0142 11.3408L11.2595 12.0955L9.3379 10.1739L7.41631 12.0955L6.66158 11.3408L8.58318 9.41919L6.66158 7.4976L7.41631 6.74288L9.3379 8.66447L11.2595 6.74288L12.0142 7.4976L10.0926 9.41919L12.0142 11.3408Z" fill="#6D6B80" />
                                                </svg>

                                            </span>
                                        </div>
                                        <div className="">
                                            <span className="cursor-pointer border rounded-pill py-1 pl-2 pr-2 d-flex align-items-center">
                                                <small style={{ fontSize: '10px' }}>Amount</small>
                                            </span>
                                        </div>
                                        <div className="">
                                            <span className="cursor-pointer border rounded-pill py-1 pl-2 pr-2 d-flex align-items-center">
                                                <small style={{ fontSize: '10px' }}>Status</small>
                                            </span>
                                        </div>
                                        <div className="">
                                            <span className="cursor-pointer border rounded-pill py-1 pl-2 pr-2 d-flex align-items-center">
                                                <small style={{ fontSize: '10px' }}>Date</small>
                                            </span>
                                        </div> */}
                      </div>
                      <div className="d-flex justify-content-end">
                        <div
                          className="fit-content d-flex"
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          <div className="btn-group">
                            <Link
                              className=" btn border btn-primary pl-3 pr-3 dropdown_text"
                              to="/app/payment/payment-links/create"
                              component={RouterLink}
                              type="button"
                            >
                              <svg
                                width="13"
                                height="14"
                                viewBox="0 0 15 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M3.75 8H11.25"
                                  stroke="white"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M7.5 11.75V4.25"
                                  stroke="white"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>{' '}
                              <span className="text-white button_text">
                                Create{' '}
                                <span className="d-none d-sm-inline">
                                  Payment Link
                                </span>
                              </span>
                            </Link>
                          </div>
                          {/* &nbsp;&nbsp;
                                                    <div className="btn-group">
                                                        <button
                                                            data-toggle="dropdown"
                                                            aria-expanded="false"
                                                            className="btn border btn-primary"
                                                            type="button"
                                                        >
                                                            <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M1.51855 8.93359V10.3937C1.51855 10.9313 1.95436 11.3671 2.49195 11.3671H9.30576C9.84335 11.3671 10.2792 10.9313 10.2792 10.3937V8.93359" stroke="#F3F4F6" strokeWidth="1.86" strokeLinecap="round" strokeLinejoin="round" />
                                                                <path d="M3.9519 6.5L5.8987 8.4468L7.8455 6.5" stroke="#F3F4F6" strokeWidth="1.86" strokeLinecap="round" strokeLinejoin="round" />
                                                                <path d="M5.89868 1.6333V8.4471" stroke="#F3F4F6" strokeWidth="1.86" strokeLinecap="round" strokeLinejoin="round" />
                                                            </svg>
                                                            {" "}
                                                            <span className="button_text text-white">Export</span>
                                                        </button>
                                                        <div
                                                            className="dropdown-menu dropdown-menu-right rounded border-0"
                                                            style={{
                                                                background: "#fff",
                                                                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.1)",
                                                            }}
                                                        >
                                                            <button
                                                                className="dropdown-item dropdown_text fw-500 position-relative"
                                                            >
                                                                CSV
                                                            </button>
                                                            <button
                                                                className="dropdown-item dropdown_text fw-500 position-relative"
                                                            >
                                                                PDF
                                                            </button>
                                                        </div>
                                                    </div> */}
                          &nbsp;&nbsp;
                          <div className="btn-group">
                            <Link
                              onClick={() => handleToggleDropdown()}
                              className="btn border btn-primary rounded d-flex align-items-center"
                            >
                              <svg
                                width="12"
                                height="13"
                                viewBox="0 0 12 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9.7523 5.01514L6.57741 8.19003C6.20246 8.56498 5.58891 8.56498 5.21396 8.19003L2.03906 5.01514"
                                  stroke="white"
                                  strokeWidth="1.16867"
                                  strokeMiterlimit="10"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>{' '}
                              <span className="ml-1 text-white">Filters</span>
                            </Link>
                            <div
                              className={`${
                                toggleDropdown ? 'show' : ''
                              } dropdown-menu dropdown-menu-right ${
                                styles.drp_dwn_menu_right_topup
                              } drp_dwn_menu_right`}
                              style={{
                                transform: 'translate3d(-310px, 40px, 0px)',
                              }}
                            >
                              <div
                                className={`${styles.filter_wrap} px-2 px-sm-4 py-3`}
                              >
                                <div className="row">
                                  <div className="col-12 mb-1">
                                    <div className="mb-3 d-flex flex-column">
                                      <label htmlFor="currency">Currency</label>
                                      <div className="position-relative bg-white">
                                        <span className=" all_selectdropdown_icon position-absolute bg-white px-2">
                                          <svg
                                            width="20"
                                            height="21"
                                            viewBox="0 0 20 21"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              fillRule="evenodd"
                                              clipRule="evenodd"
                                              d="M5.29289 7.79289C5.68342 7.40237 6.31658 7.40237 6.7071 7.79289L9.99999 11.0858L13.2929 7.79289C13.6834 7.40237 14.3166 7.40237 14.7071 7.79289C15.0976 8.18342 15.0976 8.81658 14.7071 9.20711L10.7071 13.2071C10.3166 13.5976 9.68341 13.5976 9.29289 13.2071L5.29289 9.20711C4.90237 8.81658 4.90237 8.18342 5.29289 7.79289Z"
                                              fill="#6B7280"
                                            />
                                          </svg>
                                        </span>
                                        <Select
                                          labelId="currency-label"
                                          id="currency"
                                          fullWidth
                                          size="small"
                                          required
                                          name="currency"
                                          value={filter.currency}
                                          onChange={handleFilterChange}
                                        >
                                          <MenuItem value="all">ALL</MenuItem>
                                          <MenuItem value="NGN">NGN</MenuItem>
                                          <MenuItem value="CNY">CNY</MenuItem>
                                        </Select>
                                      </div>
                                    </div>

                                    <div className="mb-3 d-flex flex-column">
                                      <label htmlFor="amount">Amount</label>
                                      <div className="position-relative">
                                        <TextField
                                          name="amount"
                                          required
                                          type="number"
                                          fullWidth
                                          id="amount"
                                          placeholder=""
                                          size="small"
                                          value={filter.amount}
                                          onChange={handleFilterChange}
                                        />
                                      </div>
                                    </div>

                                    <label htmlFor="ounit">
                                      Custom Date Range
                                    </label>

                                    <div className="start_enddate d-flex">
                                      <div
                                        className=""
                                        style={{ width: '100%' }}
                                      >
                                        <div
                                          className="mb-3 bg-white"
                                          style={{ position: 'relative' }}
                                        >
                                          <span
                                            style={{
                                              position: 'absolute',
                                              right: '10px',
                                              top: '7px',
                                              zIndex: '0',
                                            }}
                                          >
                                            <svg
                                              width="14"
                                              height="14"
                                              viewBox="0 0 14 14"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M2.40527 4.29523C2.40527 3.38641 3.14202 2.64966 4.05084 2.64966H10.6331C11.5419 2.64966 12.2787 3.3864 12.2787 4.29523V10.8775C12.2787 11.7863 11.5419 12.5231 10.6331 12.5231H4.05084C3.14202 12.5231 2.40527 11.7863 2.40527 10.8775V4.29523Z"
                                                stroke="#7B8794"
                                                strokeWidth="1.64557"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                              <path
                                                d="M9.53516 1.55298V3.74707"
                                                stroke="#7B8794"
                                                strokeWidth="1.64557"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                              <path
                                                d="M5.14844 1.55298V3.74707"
                                                stroke="#7B8794"
                                                strokeWidth="1.64557"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                              <path
                                                d="M2.40527 5.94092H12.2787"
                                                stroke="#7B8794"
                                                strokeWidth="1.64557"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                            </svg>
                                          </span>
                                          <DatePicker
                                            // showIcon
                                            showYearDropdown
                                            scrollableYearDropdown
                                            yearDropdownItemNumber={15}
                                            selected={startDate}
                                            onChange={(date) =>
                                              setStartDate(date)
                                            }
                                            dateFormat="yyyy/MM/dd"
                                            className="form-control border"
                                            placeholderText="Start date"
                                            required
                                          />
                                        </div>
                                      </div>
                                      <div
                                        className="px-2 d-flex align-items-center "
                                        style={{ height: '40px' }}
                                      >
                                        <img
                                          src="/assets/icons/line.svg"
                                          style={{ width: '30px' }}
                                          alt=""
                                        />
                                      </div>
                                      <div
                                        className=""
                                        style={{ width: '100%' }}
                                      >
                                        <div
                                          className="mb-3 bg-white"
                                          style={{ position: 'relative' }}
                                        >
                                          <span
                                            style={{
                                              position: 'absolute',
                                              right: '10px',
                                              top: '7px',
                                              zIndex: '0',
                                            }}
                                          >
                                            <svg
                                              width="14"
                                              height="14"
                                              viewBox="0 0 14 14"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M2.40527 4.29523C2.40527 3.38641 3.14202 2.64966 4.05084 2.64966H10.6331C11.5419 2.64966 12.2787 3.3864 12.2787 4.29523V10.8775C12.2787 11.7863 11.5419 12.5231 10.6331 12.5231H4.05084C3.14202 12.5231 2.40527 11.7863 2.40527 10.8775V4.29523Z"
                                                stroke="#7B8794"
                                                strokeWidth="1.64557"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                              <path
                                                d="M9.53516 1.55298V3.74707"
                                                stroke="#7B8794"
                                                strokeWidth="1.64557"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                              <path
                                                d="M5.14844 1.55298V3.74707"
                                                stroke="#7B8794"
                                                strokeWidth="1.64557"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                              <path
                                                d="M2.40527 5.94092H12.2787"
                                                stroke="#7B8794"
                                                strokeWidth="1.64557"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                            </svg>
                                          </span>
                                          <DatePicker
                                            // showIcon
                                            showYearDropdown
                                            scrollableYearDropdown
                                            yearDropdownItemNumber={15}
                                            selected={endDate}
                                            onChange={(date) =>
                                              setEndDate(date)
                                            }
                                            dateFormat="yyyy/MM/dd"
                                            className="form-control border"
                                            placeholderText="End date"
                                            required
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="mt-1">
                                  <div
                                    className="mr-auto"
                                    style={{ width: 'fit-content' }}
                                  >
                                    <button
                                      type="button"
                                      className={`btn border bg-white`}
                                      onClick={() => handleClearFilter()}
                                    >
                                      Clear
                                    </button>
                                    &nbsp; &nbsp;
                                    <button
                                      type="button"
                                      className={`btn btn-primary`}
                                      // onClick={() => handleToggleDropdown()}
                                      onClick={() => fetchPLinksData()}
                                      // disabled={filter.status === '' || filter.currency === ''}
                                    >
                                      Apply
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{ marginTop: '0px', borderRadius: '10px' }}
                      className="p-0"
                    >
                      <Scrollbar>
                        <div
                          className={`${styles.overflow_table} table-responsive  pt-3 pb-3 pl-0 pr-0`}
                          style={{ minHeight: '80vh' }}
                        >
                          <table
                            className={`${styles.table} table-hover table-striped`}
                            style={{ minWidth: '50em' }}
                          >
                            <thead className={`${styles.thead}`}>
                              <tr>
                                <th
                                  onClick={() => sortTableData('id')}
                                  className="text-muted cursor-pointer fs-12 th_width3"
                                  style={{ minWidth: '26%' }}
                                >
                                  Payment ID{' '}
                                  <svg
                                    className={`${
                                      !isSorted ? '' : 'rotate_icon'
                                    }`}
                                    width="16"
                                    height="17"
                                    viewBox="0 0 16 17"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M8.00004 3.8335V13.1668M8.00004 13.1668L12.6667 8.50016M8.00004 13.1668L3.33337 8.50016"
                                      stroke="#667085"
                                      strokeWidth="1.33333"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </th>
                                <th
                                  onClick={() => sortTableData('amount')}
                                  className="text-muted cursor-pointer fs-12 th_width"
                                >
                                  Amount{' '}
                                  <svg
                                    className={`${
                                      !isSorted ? '' : 'rotate_icon'
                                    }`}
                                    width="16"
                                    height="17"
                                    viewBox="0 0 16 17"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M8.00004 3.8335V13.1668M8.00004 13.1668L12.6667 8.50016M8.00004 13.1668L3.33337 8.50016"
                                      stroke="#667085"
                                      strokeWidth="1.33333"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </th>
                                {/* <th className="text-muted cursor-pointer fs-12 th_width_12">Status {" "}<svg className={`${!isSorted ? "" : "rotate_icon"}`} width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M8.00004 3.8335V13.1668M8.00004 13.1668L12.6667 8.50016M8.00004 13.1668L3.33337 8.50016" stroke="#667085" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                                                                </svg>
                                                                </th> */}
                                {/* <th className="text-muted cursor-pointer fs-12 th_width">Type {" "}<svg className={`${!isSorted ? "" : "rotate_icon"}`} width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M8.00004 3.8335V13.1668M8.00004 13.1668L12.6667 8.50016M8.00004 13.1668L3.33337 8.50016" stroke="#667085" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                                                                </svg>
                                                                </th> */}
                                <th
                                  onClick={() => sortTableData('createdAt')}
                                  className="text-muted cursor-pointer fs-12 th_width"
                                >
                                  Date{' '}
                                  <svg
                                    className={`${
                                      !isSorted ? '' : 'rotate_icon'
                                    }`}
                                    width="16"
                                    height="17"
                                    viewBox="0 0 16 17"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M8.00004 3.8335V13.1668M8.00004 13.1668L12.6667 8.50016M8.00004 13.1668L3.33337 8.50016"
                                      stroke="#667085"
                                      strokeWidth="1.33333"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </th>
                                <th style={{ minWidth: '300px' }}></th>
                              </tr>
                            </thead>

                            <tbody>
                              {isPending ? (
                                <>
                                  <tr>
                                    <td
                                      colspan="4"
                                      style={{
                                        position: 'relative',
                                        height: '150px',
                                        backgroundColor: '#fff',
                                      }}
                                    >
                                      <Pageloader loading={isPending} />
                                    </td>
                                  </tr>
                                </>
                              ) : (
                                !isPending &&
                                PLinksData.map((item, index) => (
                                  <tr
                                    key={index}
                                    className={`${styles.tr} table-fonts cursor-pointer`}
                                  >
                                    <td
                                      onClick={() =>
                                        handleOpenPaymentLinkDetails(item)
                                      }
                                    >
                                      <div
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'row',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <div className=" ">
                                          <span className=" fw-500">
                                            {item.id ? item.id : '---'}
                                          </span>
                                        </div>
                                      </div>
                                    </td>
                                    <td
                                      onClick={() =>
                                        handleOpenPaymentLinkDetails(item)
                                      }
                                    >
                                      <span className="fw-600">
                                        {item.amount ? item.amount : '---'}
                                      </span>
                                      {/* <span style={{color: 'rgba(149, 155, 164, 1)'}}>
                                                                    {item.currency}
                                                                </span> */}
                                    </td>
                                    {/* <td onClick={() => handleOpenPaymentLinkDetails(item)}>
                                                                        <span className="text-capitalize fw-500">{item.type}</span>
                                                                    </td> */}

                                    <td
                                      onClick={() =>
                                        handleOpenPaymentLinkDetails(item)
                                      }
                                    >
                                      <span className=" fw-500">
                                        {item.createdAt
                                          ? DateTimeFormat(item.createdAt)
                                          : '---'}
                                      </span>
                                    </td>
                                    <td className="d-flex align-items-center">
                                      {/* /payment-links/preview/001 */}
                                      <button
                                        onClick={() => {
                                          copyToClipboard(
                                            `${baseWebURL}/payment-links/preview/${item.custom_url_path}`,
                                            'link'
                                          );
                                        }}
                                        className="btn btn-sm fw-500 py-1 px-3 copy_link_btn position-relative cursor-pointer"
                                        style={{ border: '1px solid #111827' }}
                                      >
                                        <span
                                          className="text-dark"
                                          style={{ fontSize: '12px' }}
                                        >
                                          Copy link
                                        </span>
                                      </button>
                                      <div
                                        onClick={() =>
                                          handleOpenPaymentLinkDetails(item)
                                        }
                                        className="w-100 h-100 py-4"
                                      ></div>
                                    </td>
                                  </tr>
                                ))
                              )}
                            </tbody>
                          </table>
                          {!isPending && !PLinksData.length && (
                            <div className="alerts alerts-primary text-center mt-4 mb-4">
                              <h5>No record found!</h5>
                            </div>
                          )}
                          <div className="mt-3">
                            {!isPending && PLinksData.length ? (
                              <Pagination
                                currentPage={currentPage}
                                totalResults={PLinksPagination?.totalPages}
                                totalItems={PLinksPagination?.totalItems}
                                pageSize={pageSize}
                                onPageChange={onPageChange}
                              />
                            ) : (
                              <Pagination
                                currentPage={0}
                                totalResults={0}
                                pageSize={0}
                                onPageChange={onPageChange}
                              />
                            )}
                          </div>
                        </div>
                        {/* <div className="d-flex justify-content-center">

                        </div> */}

                        {/* <div className="alert alert-primary text-center">
                            <h4>No record found</h4>
                            </div> */}
                      </Scrollbar>

                      {/* <Pagination
                                        currentPage={currentPage}
                                        totalResults={totalResults}
                                        pageSize={pageSize}
                                        onPageChange={onPageChange}
                                    /> */}
                    </div>
                  </div>
                )}
                {!hasLength && !isLoadingPage && (
                  <div className="px-0 px-md-2 mt-3 py-5 w-100">
                    <div className="w-100">
                      <div className={`${styles.trfpage_head} w-100`}>
                        <h2 className="">Collect payment with ease</h2>
                        <p className="text-center mx-auto">
                          Get started fast with a no-code option or explore
                          customisable UIs that integrate with our APIs.
                        </p>
                      </div>
                    </div>
                    <div className="rowWrap py-5">
                      <div className="row justify-content-center">
                        <div
                          className="mb-4 "
                          style={{ width: '450px', maxWidth: '100%' }}
                        >
                          <div
                            className={`${styles.trfpage_cardbox} pb-3 mx-auto px-3 ml-sm-auto mr-sm-0 pr-md-3`}
                          >
                            <div
                              className="topside py-5 w-100"
                              // onClick={() => handleToggleIsDataAvl()}
                              style={{
                                background:
                                  'url(/assets/single_trf.gif) no-repeat',
                                backgroundSize: 'cover',
                                backgroundPosition: 'top center',
                                minHeight: '170px',
                                borderRadius: '6px',
                              }}
                            ></div>
                            <div className="content pt-4">
                              <h6>Share a link to a checkout page</h6>
                              <p className="mb-0">
                                <span>
                                  Sell a product or subscription or accept a
                                  donation by sharing a link to a payment page.
                                </span>
                              </p>
                              <div className="mt-3 text-center">
                                {IsKYCVerified ? (
                                  <Link
                                    to="/app/payment/payment-links/create"
                                    component={RouterLink}
                                    className="btn btn-primary"
                                  >
                                    <span className="button_text">
                                      Get Started
                                    </span>
                                  </Link>
                                ) : (
                                  <button
                                    className="btn btn-primary"
                                    onClick={() =>
                                      bus.emit('not_verified', true)
                                    }
                                  >
                                    <span className="button_text">
                                      Get Started
                                    </span>
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className="col-sm-6 mb-4">
                                            <div className={`${styles.trfpage_cardbox} pb-3 mx-auto mr-sm-auto ml-sm-0 pl-md-3`}>
                                                <div className="topside py-5 w-100" style={{ background: 'url(/assets/bulk_trf.gif) no-repeat', backgroundSize: 'cover', backgroundPosition: 'top center', minHeight: '200px', borderRadius: '10px' }}></div>
                                                <div className="content pt-4">
                                                    <h6>Bulk Transfer</h6>
                                                    <p className="mb-0"><span>Create a payment from the Dashboard by manually entering a customer information.</span></p>
                                                    <div className="mt-3">
                                                        <Link
                                                            to="/app/payment/transfers/bulk"
                                                            component={RouterLink}
                                                            className="btn border"
                                                        >
                                                            <span className="button_text">Make Bulk Transfer</span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        ) : (
          <div className="px-0 px-md-2 mt-3 py-5 w-100">
            <div className="w-100">
              <div className={`${styles.trfpage_head} w-100`}>
                <h2 className="">Collect payment with ease</h2>
                <p className="text-center mx-auto">
                  Get started fast with a no-code option or explore customisable
                  UIs that integrate with our APIs.
                </p>
              </div>
            </div>
            <div className="rowWrap py-5">
              <div className="row justify-content-center">
                <div
                  className="mb-4 "
                  style={{ width: '450px', maxWidth: '100%' }}
                >
                  <div
                    className={`${styles.trfpage_cardbox} pb-3 mx-auto px-3 ml-sm-auto mr-sm-0 pr-md-3`}
                  >
                    <div
                      className="topside py-5 w-100"
                      // onClick={() => handleToggleIsDataAvl()}
                      style={{
                        background: 'url(/assets/single_trf.gif) no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'top center',
                        minHeight: '170px',
                        borderRadius: '6px',
                      }}
                    ></div>
                    <div className="content pt-4">
                      <h6>Share a link to a checkout page</h6>
                      <p className="mb-0">
                        <span>
                          Sell a product or subscription or accept a donation by
                          sharing a link to a payment page.
                        </span>
                      </p>
                      <div className="mt-3 text-center">
                        <button
                          className="btn btn-primary"
                          onClick={() => bus.emit('not_verified', true)}
                        >
                          <span className="button_text">Get Started</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>

      <Drawer
        anchor="right"
        open={paymentLinkDetails}
        onClose={handleClosePaymentLinkDetails}
        PaperProps={{
          sx: {
            width: 350,
            maxWidth: '100%',
            border: 'none',
            overflow: '',
            marginTop: '0em',
            height: '100vh',
          },
        }}
        className={`${styles.drawerHolder} position-relative`}
      >
        {isLoadingPLData && (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              position: 'absolute',
              top: '0',
              left: '0',
              right: '0',
              bottom: '0',
              width: '100%',
              height: '100%',
              background: 'rgb(249, 250, 251)',
              zIndex: '10',
            }}
          >
            <CircularProgress
              style={{ color: 'white' }}
              className="text-white ml-1"
              size="1.5rem"
            />
          </div>
        )}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 1, pt: 2, pb: 1 }}
          className=""
        >
          <Typography variant="h6" sx={{ ml: 1 }}></Typography>
          <button
            className="btn cursor-pointer d-inline d-sm-none"
            onClick={() => setPaymentLinkDetails(false)}
          >
            <Iconify icon="eva:close-fill" className="" />
          </button>
        </Stack>

        <Scrollbar>
          <Stack spacing={2} sx={{ p: 0 }}>
            <div
              className={`modal_content pt-3 pt-sm-5`}
              style={{ minHeight: '93vh' }}
            >
              <div className={` px-4 pt-2 pb-5`}>
                <div className="">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <svg
                        width="13"
                        height="13"
                        viewBox="0 0 13 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M1.51953 1.25H10.5195C10.9337 1.25 11.2695 1.58579 11.2695 2V2.75H0.769531V2C0.769531 1.58579 1.10532 1.25 1.51953 1.25ZM0.769531 3.5H11.2695C11.6837 3.5 12.0195 3.80526 12.0195 4.18182V10.3182C12.0195 10.6947 11.6837 11 11.2695 11H0.769531C0.355318 11 0.0195312 10.6947 0.0195312 10.3182V4.18182C0.0195312 3.80526 0.355318 3.5 0.769531 3.5ZM7.89453 7.25C7.89453 8.28553 7.05507 9.125 6.01953 9.125C4.984 9.125 4.14453 8.28553 4.14453 7.25C4.14453 6.21447 4.984 5.375 6.01953 5.375C7.05507 5.375 7.89453 6.21447 7.89453 7.25Z"
                          fill="#8792A2"
                        />
                      </svg>
                      <small
                        className="pl-2 font-weight-normal fs-11"
                        style={{ color: 'rgba(105, 115, 134, 1)' }}
                      >
                        Payment Link
                      </small>
                    </div>
                  </div>
                </div>
                <div className="mt-2">
                  <h4 className="d-flex">
                    <span
                      className="fw-700 preview_amount"
                      style={{ fontSize: '24px' }}
                    >
                      {/* $2400.00 */}
                      {/* {paymentLinkDetailsData ? paymentLinkDetailsData.accepted_currencies === 'NGN' ? '₦' : paymentLinkDetailsData.accepted_currencies === 'CNY' ? '¥' : '' : ''} */}
                      {paymentLinkDetailsData &&
                      paymentLinkDetailsData.amount !== null
                        ? paymentLinkDetailsData.amount
                        : '0'}
                      .00
                    </span>
                    <span className="ml-3 ml-auto">
                      <button
                        disabled={paymentLinkDetailsData === null}
                        onClick={() =>
                          handleOpenPreviewPL(
                            `${
                              paymentLinkDetailsData &&
                              paymentLinkDetailsData.custom_url_path
                            }`
                          )
                        }
                        // onClick={() => {
                        //     copyToClipboard(`https://onepoint-app.netlify.app/payment-links/preview/${paymentLinkDetailsData && paymentLinkDetailsData.custom_url_path}`, 'link');
                        // }}
                        className="btn btn-sm fw-500 py-1 px-3 copy_link_btn position-relative cursor-pointer"
                        style={{ border: '1px solid #111827' }}
                      >
                        <span
                          className="text-dark"
                          style={{ fontSize: '12px' }}
                        >
                          Preview
                        </span>{' '}
                        {isPreviewing && (
                          <span
                            style={{ transform: 'translateY(1px)' }}
                            className="ml-1 dark_circular_prog"
                          >
                            <CircularProgress
                              style={{ color: '#fff' }}
                              className="text-white ml-1"
                              size="0.8rem"
                            />
                          </span>
                        )}
                        {/* {tooltipVisible && (
                                                    <div className="copy_tooltip" style={{ top: '-35px', right: '15px' }}>
                                                        Copied
                                                    </div>
                                                )} */}
                      </button>
                    </span>
                  </h4>
                </div>

                <div className="mt-4 d-flex justify-content-between align-items-center">
                  <div className="w-100">
                    <small className="d-block mb-1 font-weight-normal fs-14">
                      Date Created
                    </small>
                    <span className="fs-14">
                      {paymentLinkDetailsData
                        ? DateTimeFormat(paymentLinkDetailsData.createdAt)
                        : '---'}
                    </span>
                  </div>
                  <div className="w-100 text-right">
                    <small className="d-block mb-1 font-weight-normal fs-14">
                      Payment ID
                    </small>
                    <small className="fs-14" style={{}}></small>
                    <small
                      className="fw-500 fs-13 position-relative cursor-pointer"
                      onClick={() =>
                        paymentLinkDetailsData !== null
                          ? copyToClipboard(
                              paymentLinkDetailsData &&
                                paymentLinkDetailsData.id,
                              'id'
                            )
                          : null
                      }
                    >
                      {paymentLinkDetailsData
                        ? paymentLinkDetailsData.id
                        : '---'}
                      <span className="ml-1">
                        <svg
                          width="13"
                          height="16"
                          viewBox="0 0 13 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.88777 8.3125V10.9375C8.88777 13.125 8.16813 14 6.36902 14H4.2101C2.41099 14 1.69135 13.125 1.69135 10.9375V8.3125C1.69135 6.125 2.41099 5.25 4.2101 5.25H6.36902C8.16813 5.25 8.88777 6.125 8.88777 8.3125Z"
                            stroke="#5D20D2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M8.88777 8.3125V10.9375C8.88777 13.125 8.16813 14 6.36902 14H4.2101C2.41099 14 1.69135 13.125 1.69135 10.9375V8.3125C1.69135 6.125 2.41099 5.25 4.2101 5.25H6.36902C8.16813 5.25 8.88777 6.125 8.88777 8.3125Z"
                            stroke="black"
                            stroke-opacity="0.2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M8.88777 8.3125V10.9375C8.88777 13.125 8.16813 14 6.36902 14H4.2101C2.41099 14 1.69135 13.125 1.69135 10.9375V8.3125C1.69135 6.125 2.41099 5.25 4.2101 5.25H6.36902C8.16813 5.25 8.88777 6.125 8.88777 8.3125Z"
                            stroke="black"
                            stroke-opacity="0.2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M8.88777 8.3125V10.9375C8.88777 13.125 8.16813 14 6.36902 14H4.2101C2.41099 14 1.69135 13.125 1.69135 10.9375V8.3125C1.69135 6.125 2.41099 5.25 4.2101 5.25H6.36902C8.16813 5.25 8.88777 6.125 8.88777 8.3125Z"
                            stroke="black"
                            stroke-opacity="0.2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M11.9719 4.5625V7.1875C11.9719 9.375 11.2523 10.25 9.45319 10.25H8.88776V8.3125C8.88776 6.125 8.16812 5.25 6.36901 5.25H4.77551V4.5625C4.77551 2.375 5.49516 1.5 7.29426 1.5H9.45319C11.2523 1.5 11.9719 2.375 11.9719 4.5625Z"
                            stroke="#5D20D2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M11.9719 4.5625V7.1875C11.9719 9.375 11.2523 10.25 9.45319 10.25H8.88776V8.3125C8.88776 6.125 8.16812 5.25 6.36901 5.25H4.77551V4.5625C4.77551 2.375 5.49516 1.5 7.29426 1.5H9.45319C11.2523 1.5 11.9719 2.375 11.9719 4.5625Z"
                            stroke="black"
                            stroke-opacity="0.2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M11.9719 4.5625V7.1875C11.9719 9.375 11.2523 10.25 9.45319 10.25H8.88776V8.3125C8.88776 6.125 8.16812 5.25 6.36901 5.25H4.77551V4.5625C4.77551 2.375 5.49516 1.5 7.29426 1.5H9.45319C11.2523 1.5 11.9719 2.375 11.9719 4.5625Z"
                            stroke="black"
                            stroke-opacity="0.2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M11.9719 4.5625V7.1875C11.9719 9.375 11.2523 10.25 9.45319 10.25H8.88776V8.3125C8.88776 6.125 8.16812 5.25 6.36901 5.25H4.77551V4.5625C4.77551 2.375 5.49516 1.5 7.29426 1.5H9.45319C11.2523 1.5 11.9719 2.375 11.9719 4.5625Z"
                            stroke="black"
                            stroke-opacity="0.2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </span>
                      {/* {idtooltipVisible && (
                                                <div className="copy_tooltip">
                                                    Copied
                                                </div>
                                            )} */}
                    </small>
                  </div>
                </div>

                <div className={`${styles.dropdown_payInfo} mt-4`}>
                  <p className="fw-500 fs-14">Link Information</p>

                  <div
                    className="card-img-top text-center overflow-hidden mb-3"
                    style={{
                      maxHeight: '150px',
                    }}
                  >
                    {!selectedImage && (
                      <div className="w-100 border">
                        <div className="mx-auto" style={{ width: '100%' }}>
                          <img
                            src="/assets/payment_link_previewImg.png"
                            style={{
                              objectFit: 'cover',
                              width: '100%',
                              minHeight: '150px',
                            }}
                            alt="preview"
                          />
                        </div>
                      </div>
                    )}
                    {selectedImage && (
                      <div className="preview_selImage fit-content mx-auto">
                        <img
                          className=""
                          style={{ minHeight: '150px' }}
                          src={selectedImage}
                          alt="Selected"
                        />
                      </div>
                    )}
                  </div>

                  <div className="d-flex align-items-center">
                    <div className="w-75">
                      <span className="fs-14">Title</span>
                    </div>

                    <div className="w-100 text-right">
                      <span className="fs-14">
                        {paymentLinkDetailsData
                          ? paymentLinkDetailsData.name
                          : '---'}
                      </span>
                    </div>
                  </div>
                  {paymentLinkDetailsData &&
                    paymentLinkDetailsData.redirect_url !== null && (
                      <div className="d-flex align-items-center">
                        <div className="w-75">
                          <span className="fs-14">Redirect Link</span>
                        </div>

                        <div className="w-100 text-right">
                          <span className="fs-14">
                            {paymentLinkDetailsData
                              ? paymentLinkDetailsData.redirect_url
                              : '---'}
                          </span>
                        </div>
                      </div>
                    )}
                  <div className="d-flex align-items-center">
                    <div className="w-75">
                      <span className="fs-14">Currency Accepted</span>
                    </div>

                    <div className="w-100 text-right">
                      <span className="fs-14">
                        {paymentLinkDetailsData
                          ? paymentLinkDetailsData.accepted_currencies
                          : '---'}
                      </span>
                    </div>
                  </div>
                  {paymentLinkDetailsData &&
                    (paymentLinkDetailsData.description !== null ||
                      paymentLinkDetailsData.description !== '') && (
                      <div className="d-sm-flex align-items-center">
                        <div className="w-75">
                          <span className="fs-14">Description</span>
                        </div>

                        <div className="w-100 text-sm-right">
                          <span
                            className="fs-14 d-block"
                            style={{ lineHeight: '1.5' }}
                          >
                            {paymentLinkDetailsData
                              ? paymentLinkDetailsData.description
                              : '---'}
                          </span>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </Stack>
        </Scrollbar>
      </Drawer>
    </>
  );
}
